import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Tab, Tabs, Box, AppBar, Typography, } from "@material-ui/core";
import NotWhatsapp from './NotWhatsapp';
import OnWhatsapp from './OnWhatsapp';
import CallIcon from '@material-ui/icons/Call';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import useStyles from "./style";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Home() {

  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>

      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
        </Grid>
      </Grid>

      <AppBar position="static" color="default" style={{ backgroundColor: "#cbe8ff", boxShadow: 'none' }}>
        <Tabs value={value} onChange={handleChange} aria-label="campaign"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          className={classes.tabList}>
          <Tab label={"All Contacts"} icon={<WhatsAppIcon style={{ color: "green", fontSize: 25 }} />} iconPosition="end" {...a11yProps(0)} />
          <Tab label="Contacts With No Whatsapp" icon={<CallIcon style={{ color: "green", fontSize: 25 }} />}   {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} >
        <OnWhatsapp />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NotWhatsapp />
      </TabPanel>
    </div>
  );
}