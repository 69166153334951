import React from "react";
import Chat from "../chat/Chat";

function Message(props) {
  return (
    <div style={{height:"70vh"}}>
      <Chat setValue={props.setValue} mobile={props.mobile} />
    </div>
  );
}

export default Message;
