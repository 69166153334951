import React from "react";

// styles
import useStyles from "./styles";
import { Avatar, Typography } from "@material-ui/core";
// components
// import { Typography } from "../Wrappers";

export default function CustomAvatar({ name }) {
  var classes = useStyles();

  const getAvatarColor = name => {
    const colors = ["#363062","#610C9F","#0F0F0F","#451952","#040D12","#352F44","#E19898","#35155D","#331D2C","#2D4356","#0E2954","#116D6E","#27374D","#0C134F","#27374D","#0C134F","#5F264A","#070A52","#393646","#0B2447","#37306B","#2C3333","#3A1078","#635985","#13005A","#0A2647","#1A120B","#2D033B","#00005C","#FB2576"];

    const charCode = name.charCodeAt(0);

    if (charCode >= 48 && charCode <= 57) {
      // Numbers 0-9
      return '#d3d7dc'; // Return null for numbers, to show the icon
    }
    const charIndex = charCode % colors.length;
    return colors[charIndex];
  };

  const renderAvatarContent = name => {
    const charCode = name?.charCodeAt(0);
    if (charCode >= 48 && charCode <= 57) {
      return (
        <Avatar alt="Image" className={classes.chatListAvatar} />
      );
    } else {
      return (
        <Typography className={classes.text}>{name?.charAt(0)}</Typography>
      );
    }
  };

  return (
    <div className={classes.avatar} style={ { backgroundColor: getAvatarColor(name) }}>
      {renderAvatarContent(name)}
    </div>
  );
}
