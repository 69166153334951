import React, { useState,} from "react";
 import {
  Grid,
  TextField,
  Button,   
  Backdrop,
  CircularProgress,  
} from "@material-ui/core";
 
import { toast } from "react-toastify"; 
import Tooltip from "@material-ui/core/Tooltip"; 
import useStyles from "../../themes/style.js";
import Notification from "../../components/Notification/Notification";
import { uploadBulkContacts } from '../../services/groupContact.api';
import { Autocomplete } from "@material-ui/lab";
import BackupIcon from '@material-ui/icons/Backup';
import readXlsxFile from 'read-excel-file';
import "react-toastify/dist/ReactToastify.css";


export default function GroupContact(props) {

  const classes = useStyles(); 
  const [loader, setLoader] = useState(false);
  const token = localStorage.getItem("webWAInfoapto_PX11925");
  const user = JSON.parse(localStorage.getItem("user"));
  
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  }
   
  const handleUploadContact = (e) => {
 
    var filenamearr = e.target.files[0].name.split(".");
    if (filenamearr[filenamearr.length - 1] === "xlsx") {
      const reader = new FileReader();
      reader.readAsArrayBuffer(e.target.files[0]);
      reader.onload = (e) => {
        const thisData = e.target.result;
        readXlsxFile(thisData).then((rows) => {
          const headings = rows[0]; 

          const wantedheadings=["mobile","name", "country code"];
          const issubset=wantedheadings.every(item=>headings.includes(item));

//           const results = rows.filter((heading) => {
//             return (heading.includes("mobile")) &&
//                    (heading.includes("name")) &&  
//                    (heading.includes("country code"));
//           });

         
// if(results ){

// }
          if(issubset){

          const contacts = rows.slice(1); // Exclude the first array which contains headings

          new Promise((resolve, reject) => {
            try {
              resolve(contacts?.map((contactDetails) => {
                const contactInfo = {};
                headings.forEach((heading, index) => {
                  const detail = contactDetails[index];
                  contactInfo[heading] = detail;
                });
                return contactInfo;
              }));
            } catch (error) {
              console.error(error);
              reject(error);
            }
          }).then(output => {
           
            setLoader(true);
            uploadBulkContacts({data: output, group_id: props.onGroupContactAdd.values.group_id, token: token}).then((response) => {    
      if (response.data.status === true) { 
        handleNotificationCall("success", response.data.message);  
         
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
      
       props.handleUploadClose();
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    }) 
    })
} else{
  handleNotificationCall("error", "Uploaded data format is not valid"); 
  props.handleUploadClose();
}


        })
      }
    }else{
      handleNotificationCall("error", "Only .xlsx extension is Allowed");  
       
    }
  }

  return (
    <>
    
    <Grid container spacing={2} style={{ height: "100px"}}> 

    { props.upload === "false"?
<Grid item xs={12}>
    <form onSubmit={props.onGroupContactAdd.handleSubmit}  style={{display:"flex"}} className={classes.marginTop15}   >

          <Grid container direction="row" spacing={2} >
        <Grid item xs={12}  className={[classes.gap10, classes.center]}>
          
          
<Autocomplete  underlineShow={false}
         
            options={props.groupData}
            
            getOptionLabel={(option) =>
              option?.name !==undefined? 
              user?.role_code ==="ADMIN"? `${ option?.name + " ("+ option?.createdBy + ")" }`
              : option?.name :""
            }
            
            value={props.groupData[props.onGroupContactAdd.values.group_id]} 
           onChange={(e, value) => { 
                    props.onGroupContactAdd.setValues({
                      "contact_id": props.onGroupContactAdd.values.contact_id,
                      "group_id": props.groupData.indexOf(value),
                    })
      
                   }
                  } 
            renderInput={(params) => (
              <TextField
              classes={{ root: classes.customTextField }}
              InputProps={{ disableUnderline: true }}
                {...params}
                name="group_id" 
                InputLabelProps={{ shrink: true }}
                type="text"
                error={props.onGroupContactAdd.touched.group_id && Boolean(props.onGroupContactAdd.errors.group_id)}
                helperText={props.onGroupContactAdd.touched.group_id && props.onGroupContactAdd.errors.group_id} 

              />
            )}
          />   
       
            <Button
              variant="contained"
              color="primary"
              size="small"
               type="submit"
               disabled={props.onGroupContactAdd.isSubmitting}
               style={{marginTop:  props.onGroupContactAdd.touched.contact_id && Boolean(props.onGroupContactAdd.errors.contact_id)? "-20px" :"10px"}}
            >
              Save
            </Button>
           
        </Grid>
          </Grid>
          
          </form>
          </Grid>

: 
          <Grid item xs={12}  className={classes.center +" "+ classes.gap10} > 
        
         <Tooltip title="Upload Contact" placement="bottom" aria-label="download"> 
    <div className={classes.marginTop}>
      <input
        accept=".xlsx"
        className={classes.input}
        id="icon-button-file"
        type="file" 
       
        style={{ display: "none" }}
        onChange={handleUploadContact}
       
      />
      <label htmlFor="icon-button-file">
      <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            startIcon={<BackupIcon />}
                            aria-label="upload"
                            component="span"
                          >
                            Upload Contact
                          </Button>
     

      
      </label>
    </div>  
 
</Tooltip>

<Tooltip title="Dowmload Sample" placement="bottom" aria-label="download"> 
  <a  href={`${process.env.REACT_APP_URL}assets/samplecontacts.xlsx`} className={classes.marginTop } download>
       Download Sample
                         
  </a>
 
</Tooltip>


</Grid> }
</Grid>


 

      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

