import { makeStyles } from "@material-ui/styles";
export default makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        border: "1px solid #f5f5f5"
    },
    verticalAlign: {
        display: "flex",
        alignItems: "center",
    },
    userDetails: {
        color: "rgba(0, 0, 0, 0.87)",
        display: "flex",
        flexDirection: "column",
        "& h6": {
            fontSize: "0.950rem",
            fontWeight: "600"
        },
        "& p": {
            color: "rgba(0, 0, 0, 0.54) !important",
        }
    },
    bannerBtnContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "end",
        flexDirection: 'column',
        [theme.breakpoints.only("xs")]: {
            flexDirection: 'row',
            justifyContent: "space-around",
        },
    },
    bannerBtn: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "20px",
        // [theme.breakpoints.down("md")]: {
        //     justifyContent:"center",
        // },
    },
    tabList: {
        // background: "#cbe8ff !important",
        background: "transparent !important",
        "& button": {
            fontFamily: "'Satoshi' !important",
            textTransform: "capitalize",
            minHeight: "60px !important",
            "& span": {
                flexDirection: "row !important",
                columnGap: "5px",
                alignItems: "center"
            }
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "15px"
        },
    },
    tempTabList: {
        "& button": {
            textTransform: "capitalize",
            minHeight: "0px",
            "& span": {
                flexDirection: "row-reverse !important",
                columnGap: "5px",
                alignItems: "center"
            }
        },

    },
    instanceTabList: {
        background: "transparent !important",
        '& .Mui-selected': {
            backgroundColor: '#F6F5F2',
        },
        "& button": {
            fontFamily: "'Satoshi' !important",
            textTransform: "capitalize",
            minHeight: "60px !important",
            "& span": {
                flexDirection: "row !important",
                columnGap: "5px",
                alignItems: "center"
            }
        },
        // [theme.breakpoints.up("lg")]:{
        //     marginTop:"15px"
        // },
    },
    tabsTitle: {
        // background:"#064be2",
        background: "#2196f3",
        color: "White",
        padding: "12px",
        borderRadius: "16px 16px 0px 0px",
    },
    p25: {
        padding: "25px"
    },
    tabsFooter: {
        padding: "10px 40px",
        display: "flex",
        justifyContent: "end",
        "& button": {
            color: "#fff",
            backgroundColor: "#4caf50",
            "&:hover": {
                color: "#fff",
                backgroundColor: "#4caf50",
            }
        },
        [theme.breakpoints.only("xs")]: {
            padding: "10px !important",
        },

    },
    tabsBody: {
        display: 'flex',
        flexDirection: "column",
        rowGap: '50px',
    },
    tabsContainer: {
        border: "1px solid #007bff21",
        borderRadius: "16px",
        // boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
        boxShadow: "10px 20px 20px 5px #2f3c4a14 !important",
        [theme.breakpoints.down("xs")]: {
            marginTop: "10px"
        },
    },
    listItemCenter: {
        "& span": {
            display: "flex",
            alignItems: "center",
        }
    },
    listContainer: {
        padding: 0,
        "& :nth-child(even)": {
            backgroundColor: "#cbe8ff !important", //new change f5f5f5
        },
        "& li": {
            borderBottom: "1px solid #d1d9fd",
        }
    },
    tempListContainer: {
        padding: 0,
        "& li": {
            borderBottom: "1px solid #d1d9fd",
            "&:hover": {
                backgroundColor: "#cbe8ff !important", //new change f5f5f5
                cursor: 'default',
            }
        }
    },
    TabPanelList: {
        "& div": {
            padding: "0px",
        }
    },
    countCircle: {
        borderRadius: "50px",
        padding: "0px 8px",
    },

    gray: {
        backgroundColor: "#b5bab9",
        color: "#fff",
        boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3) !important",
    },
    blue: {
        backgroundColor: "#3da5d9",
        color: "#fff",
        boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3) !important",
    },
    green: {
        textTransform: "capitalize !important",
        backgroundColor: "#4caf50",
        color: "#e9ffe9 ",
        boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3) !important",
        "&:hover": {
            backgroundColor: "#4caf50  !important",
        }
    },
    orange: {
        backgroundColor: "#ea7317",
        color: "#fff",
        boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3) !important",
    },
    marginBorder: {
        borderLeft: "2px solid #dfe1e5",
        margin: "10px"
    },
    mapContainer: {
        height: "100%",

    },

    formFooter: {
        display: 'flex',
        justifyContent: 'end',
        gap: "10px",
        borderTop: "1px solid #aeaa",
        marginTop: "50px",
    },


    card: {
        boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
        backgroundColor: "#fff",
        backgroundClip: "border-box",
        border: "1px solid rgba(0,0,0,.125)",
        borderRadius: "0.25rem"
    },
    cardHeader: {

        alignItems: "center",
        padding: "0.75rem 1.25rem",
        marginBottom: "0",
        backgroundColor: "rgba(0,0,0,.03)",
        borderBottom: "1px solid rgba(0,0,0,.125)",
        fontSize: "14px",

    },

    heroContent: {
        borderBottom: "1px solid rgba(0,0,0,.125)",
    },

    cardContent: {
        height: "150px",
    },

    a: {
        textDecoration: "none",
        color: "white"
    },

    profileHead: {
        margin: "0px",
        width: "100%",
        boxSizing: 'border-box',
        background: "#cbe8ff !important", //new change f5f5f5
        borderRadius: "5px",
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 20px',
        [theme.breakpoints.only("xs")]: {
            flexDirection: 'column-reverse',
        },
        // [theme.breakpoints.only("xs")]: {
        //    height: "150px !important"
        // },
    },

    profileHeadLeft: {
        display: "flex",
        padding: '15px 15px 15px 0px',
        [theme.breakpoints.only("xs")]: {
            display: "block",
            order: "2",
        },
    },

    profileHeadRight: {
        padding: '15px 0px 15px 15px',
        [theme.breakpoints.only("xs")]: {
            order: "1",
        },
    },

    profileAvatar: {
        width: "75px !important",
        height: "75px !important",
    },
    profileAvatarWithIcon: {
        width: "40px !important",
        height: "40px !important",
    },
    profileBody: {
        alignItems: "center",
        display: "flex",
        //  marginTop: "-55px", //"-80px",
        gap: "20px",//"10px",
        [theme.breakpoints.only("xs")]: {

            marginLeft: "0px",
        },

        "& $profileAvatar": {
            width: "75px !important",
            height: "75px !important",
            [theme.breakpoints.only("xs")]: {
                width: "60px !important",
                height: "60px !important",
            },
        }
    },


    iframe: {
        "& iframe": {
            width: "100%",
            height: "500px"
        }
    },

    textWrap: {
        textWrap: "wrap"
    },


    drawer: {
        width: "71%",
        left: "15%",
        right: "15%",
        [theme.breakpoints.down("sm")]: {
            left: "5%",
            right: "5%",
            width: "90%",
        }
    },
    popover: {
        outline: 0,
        zIndex: 999,
        position: "absolute",
        minWidth: "16rem",
        overflowX: "hidden",
        overflowY: "auto",
        backgroundColor: "white",
    },

    cardRoot: {
        maxWidth: 345,
        marginTop: '50px'
    },
    cardMedia: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    cardExpand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: 'red',
    },
    replySelectTemplate: {
        "& div": {
            "& >div": {
                "& >input": {
                    padding: "10px !important"
                }
            }
        }
    },
    searchTagField: {
        width: "100%",
        "& div": {
            borderRadius: "4px !important",
        },
        "& input": {
            padding: "10px 12px !important",
        }
    },

}));
