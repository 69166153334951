import React, { useState, useEffect, useReducer, useRef, useContext } from 'react'
import {
  Grid,
  Button,
  InputLabel,
  List,
  Box,
  SwipeableDrawer,
  FormControl,
  Typography,
  Backdrop,
  Tooltip,
  CircularProgress,
  TextField,
  Switch,
  Divider,
  Link
} from "@material-ui/core";
import useStyles from "./style";
import globalStyles from "../../themes/style.js";
import MUIDataTable from "mui-datatables";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import PageTitle from "../../components/PageTitle/PageTitle";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { Visibility as ViewIcon, Close as CloseIcon, } from "@material-ui/icons";
import { viewAllReplyMessage, addReplyMessage, viewReplyMessage, changeMessageToPrimary, editReplyMessage } from '../../services/instance.api';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useFormik } from 'formik';
import { replySchema } from "../../components/Yup/Yup";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";

import "react-toastify/dist/ReactToastify.css";
import { viewApprovedTemplates, viewTemplate } from '../../services/template.api.js';
import { Autocomplete } from '@material-ui/lab';
import InstanceContext from '../../context/InstanceContext.js';

function Reply(props) {

  const mobileQuery = useMediaQuery('(max-width:600px)');
  const classes = useStyles();
  const globalClasses = globalStyles();

  var moment = require('moment-timezone');
  const token = localStorage.getItem("webWAInfoapto_PX11925");

  const [loader, setLoader] = useState(false);
  const [replyData, setReplyData] = useState([]);
  const [dataList, setDataList] = useState("ADD");
  const [replyView, setReplyView] = useState({
    data: {}
  });
  const [state, setState] = useState({
    right: false,
  });
  const [autoReply, setAutoReply] = useState({
    message: true,
  })


  //select Template
  const messageRef = useRef();
  const { instanceId, isInstance } = useContext(InstanceContext)
  const [templateData, setTemplateData] = useState([]);
  const [selectTemplateVal, setSelectTemplateVal] = useState({
    template: "",
    mobile_number: "",
    template_name: "",
    language_code: "",
    header: "",
    headerType: "",
    parameters: "",
  })
  const [templateValue, setTemplateValue] = useState(null);
  const [templateButton, setTemplateButton] = useState([]);
  const [messageData, setMessageData] = useState({
    headerField: false,
    bodyField: false,
    //"footerField": false,
    // "buttonField": false,
    message: "",
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  useEffect(() => {
    if (instanceId !== null && isInstance) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceId, isInstance]);

  const fetchData = async () => {
    viewApprovedTemplates({ instance_id: instanceId?.id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          setTemplateData(response.data?.data);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleAutoReplyChange = () => {
    setAutoReply({ message: !autoReply.message });
  };

  function selectTemplate(value) {
    // setAutoReply({
    //   message: false,
    //   templateId: true,
    // })
    setLoader(true);
    if (value?.id) {
      viewTemplate({
        id: value.id,
        instance_id: instanceId?.id,
        token: token,
      })
        .then((response) => {
          if (response.data.status === true) {
            const regex = /\{\{.*?\}\}/g;
            setTemplateValue(value);
            var HEADER = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "HEADER",
            )[0]?.format;
            var BODY = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "BODY",
            )[0]?.text;
            var BUTTONS = JSON.parse(response.data.data.components).filter(type => type?.type === "BUTTONS")[0]?.buttons;

            setTemplateButton(BUTTONS)

            setSelectTemplateVal({
              template: value.id,
              mobile_number: "",
              template_name: response.data.data.name,
              language_code: response.data.data.language,
              header:
                response.data.data.fileUrl === null
                  ? ""
                  : response.data.data.fileUrl,
              headerType: HEADER !== undefined ? HEADER : "",
              parameters:
                BODY !== undefined ? (BODY.match(regex) || []).join(", ") : "",
              //  "footer": FOOTER !==undefined? FOOTER: "",
              //  "button": BUTTONS !==undefined? (BUTTONS.map((list) =>  list?.text+", " ))   : "",
            });

            //  var FOOTER = JSON.parse(response.data.data.components).filter(type => type?.type === "FOOTER")[0]?.text;
            //   var BUTTONS = JSON.parse(response.data.data.components).filter(type => type?.type === "BUTTONS")[0]?.buttons;

            var loop = (
              JSON.parse(response.data.data.components)
                .filter((type) => type?.type === "BODY")[0]
                ?.text.match(regex) || []
            )
              .join(",")
              .split(",");

            var message = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "BODY",
            )[0]?.text;

            for (let i = 0; i < loop.length; i++) {
              const match = `{{${i + 1}}}`;
              message = message.replace(
                match,
                `<b id="${i + 1}">{{${i + 1}}}</b>`,
              );

              setMessageData({
                ...messageData,
                headerField:
                  JSON.parse(response.data.data.components).filter(
                    (type) => type?.type === "HEADER",
                  )[0]?.format !== undefined
                    ? false
                    : true,
                bodyField:
                  JSON.parse(response.data.data.components).filter(
                    (type) => type?.type === "BODY",
                  )[0]?.text !== undefined
                    ? false
                    : true,
                //"footerField": JSON.parse(response.data.data.components).filter(type => type?.type === "FOOTER")[0]?.text !==undefined ? false : true,
                message: message,
              });
            }
          }
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setLoader(false);
      setTemplateValue(null);
    }
  }

  const onReplyAdd = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: replySchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);

      if (autoReply.message) {
        addReplyMessage({ message: values.message, templateId: "", token: token }).then((response) => {

          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);
            forceUpdate();
            resetForm();
            setState({ ...state, right: false });
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
          .catch(function (error) {
            console.log(error);
          })

      } else {
        addReplyMessage({ message: "", templateId: selectTemplateVal.template, token: token }).then((response) => {

          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);
            forceUpdate();
            resetForm();
            setState({ ...state, right: false });
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
          .catch(function (error) {
            console.log(error);
          })
      }
      setLoader(false);
    },
  });


  const onReplyEdit = useFormik({
    initialValues: {
      id: '',
      message: '',
    },
    validationSchema: replySchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      editReplyMessage({ id: onReplyEdit.values.id, message: values.message, token: token }).then((response) => {
        if (response.data.status === true) {
          handleNotificationCall("success", response.data.message);
          forceUpdate();
          resetForm();
          setState({ ...state, right: false });
        } else {
          handleNotificationCall("error", response.data.message);
        }
        setSubmitting(false);
        setLoader(false);
      })
        .catch(function (error) {
          console.log(error);
        })
      setLoader(false);
    },
  });

  const list = (anchor) =>
    dataList === "ADD" ? (
      <Box sx={{ width: "100%" }} role="presentation">
        <List>
          <Card className={classes.root}>
            <CardHeader>
              <Grid
                container
                direction="row"
                spacing={1}
                className={globalClasses.drawerHeader}
              >
                <Typography variant="subtitle1"> Add Auto Reply</Typography>

                <Grid className={globalClasses.drawerClose}>
                  <CloseIcon
                    className={globalClasses.closeBtn}
                    size="14px"
                    onClick={toggleDrawer(anchor, false)}
                  />
                </Grid>
              </Grid>
            </CardHeader>
            <form onSubmit={onReplyAdd.handleSubmit}  >
              <CardContent className={globalClasses.drawerContent}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'baseline' }}>
                    <Typography variant="subtitle1"> Switch to send message type</Typography>
                    <Switch
                      checked={autoReply.message}
                      onChange={handleAutoReplyChange}
                      name="switchTemplate"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                  {autoReply.message === true ? (
                    <Grid item xs={12}>
                      <InputLabel shrink htmlFor="message"> Message </InputLabel>
                      <FormControl className={classes.margin}>
                        <TextField
                          multiline
                          rows={3}
                          classes={{ root: globalClasses.customTextField }}
                          InputProps={{ disableUnderline: true }}
                          size="small"
                          placeholder="Enter message"
                          type="text"
                          name="message"
                          value={onReplyAdd.values.message}
                          onChange={onReplyAdd.handleChange}
                          error={onReplyAdd.touched.message && Boolean(onReplyAdd.errors.message)}
                          helperText={onReplyAdd.touched.message && onReplyAdd.errors.message}
                        />
                      </FormControl>
                    </Grid>
                  )
                    :
                    <>
                      <Grid item xs={12}>
                        <InputLabel shrink htmlFor="message"> Select Template </InputLabel>
                        <FormControl className={classes.margin}>
                          <Autocomplete
                            className={classes.replySelectTemplate}
                            underlineShow={false}
                            options={templateData}
                            getOptionLabel={(option) =>
                              option.name !== undefined ? `${option.name} ` : ""
                            }
                            value={templateValue}
                            onChange={(e, value) => {
                              selectTemplate(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="template"
                                fullWidth
                                margin="normal"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                type="text"
                              // error={
                              //   onSendTemplate.touched.template_name &&
                              //   Boolean(onSendTemplate.errors.template_name)
                              // }
                              // helperText={
                              //   onSendTemplate.touched.template_name &&
                              //   onSendTemplate.errors.template_name
                              // }
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid className={globalClasses.p24x8}>
                        <Typography variant="h6" className={globalClasses.center}>
                          {messageData.message ? "Preview" : ""}
                        </Typography>
                        <br />
                        <Typography variant="text">
                          {selectTemplateVal.header &&
                            selectTemplateVal.headerType === "IMAGE" ? (
                            <img
                              src={selectTemplateVal.header}
                              alt="logo"
                              style={{ width: "100%", height: "200px" }}
                            />
                          ) : selectTemplateVal.header &&
                            selectTemplateVal.headerType === "VIDEO" ? (
                            <video controls width={"100%"}>
                              <source src={selectTemplateVal.header} />
                            </video>
                          ) : selectTemplateVal.header &&
                            selectTemplateVal.headerType === "DOCUMENT" ? (
                            <iframe
                              src={`https://docs.google.com/a/umd.edu/viewer?url=${selectTemplateVal.header}&embedded=true`}
                              title="File"
                              width="100%"
                              height="200"
                            ></iframe>
                          ) : (
                            ""
                          )}
                          <div
                            ref={messageRef}
                            dangerouslySetInnerHTML={{
                              __html: messageData.message,
                            }}
                          />
                          {templateButton && templateButton.length > 0 ?
                            <>
                              <Divider style={{ margin: '15px 0px' }} />
                              <div style={{ paddingTop: '5px', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'row', gap: '20px' }}>
                                {
                                  templateButton.map((button, index) => (

                                    <Link key={index} type={button.type} href={button?.url} target='_blank'
                                      underline='hover'
                                      style={{ cursor: 'pointer', color: '#3cc1e7', fontSize: '1.2rem' }}
                                    >
                                      {button.text}
                                    </Link>
                                  ))
                                }
                              </div>
                            </>
                            :
                            <></>
                          }
                        </Typography>
                      </Grid>
                    </>
                  }
                  {/* {autoReply.templateId === true && autoReply.message === true && (
                    <FormControl className={classes.margin}>
                      <div className='breakMeassageOR'>or</div>
                    </FormControl>
                  )} */}
                </Grid>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={globalClasses.drawerFooter}
                >


                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    disabled={onReplyAdd.isSubmitting}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={toggleDrawer(anchor, false)}
                  >

                    Close
                  </Button>
                </Grid>
              </CardActions>
            </form>
          </Card>
        </List>
      </Box>

    ) : dataList === "EDIT" ? (
      <Box sx={{ width: "100%" }} role="presentation">
        <List>
          <Card className={classes.root}>
            <CardHeader>
              <Grid
                container
                direction="row"
                spacing={1}
                className={globalClasses.drawerHeader}
              >
                <Typography variant="subtitle1"> Edit Auto Reply</Typography>

                <Grid className={globalClasses.drawerClose}>
                  <CloseIcon
                    className={globalClasses.closeBtn}
                    size="14px"
                    onClick={toggleDrawer(anchor, false)}
                  />
                </Grid>
              </Grid>
            </CardHeader>


            <form onSubmit={onReplyEdit.handleSubmit}  >

              <CardContent className={globalClasses.drawerContent}>
                <Grid container direction="row" spacing={2}>


                  <Grid item xs={12}>
                    <InputLabel shrink htmlFor="message"> Message </InputLabel>

                    <FormControl className={classes.margin}>
                      <TextField
                        multiline
                        rows={3}
                        classes={{ root: globalClasses.customTextField }}
                        InputProps={{ disableUnderline: true }}
                        size="small"
                        placeholder="Enter message"
                        type="text"
                        name="message"
                        value={onReplyEdit.values.message}
                        onChange={onReplyEdit.handleChange}
                        error={onReplyEdit.touched.message && Boolean(onReplyEdit.errors.message)}
                        helperText={onReplyEdit.touched.message && onReplyEdit.errors.message}
                      />


                    </FormControl>
                  </Grid>


                </Grid>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={globalClasses.drawerFooter}
                >


                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    disabled={onReplyEdit.isSubmitting}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={toggleDrawer(anchor, false)}
                  >

                    Close
                  </Button>
                </Grid>
              </CardActions>
            </form>
          </Card>
        </List>
      </Box>

    ) : (
      <>
        <Box sx={{ width: "100%" }} role="presentation">
          <List>
            <Card className={classes.root}>
              <CardHeader>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  className={globalClasses.drawerHeader}
                >
                  <Typography variant="subtitle1"> View Reply Message</Typography>

                  <Grid className={globalClasses.drawerClose}>
                    <CloseIcon
                      className={globalClasses.closeBtn}
                      size="14px"
                      onClick={toggleDrawer(anchor, false)}
                    />
                  </Grid>
                </Grid>
              </CardHeader>

              <CardContent className={globalClasses.drawerContent}>
                <Grid container direction="row" spacing={2}>


                  <Grid item xs={12} sm={6} md={6} lg={6}>    <Typography className={classes.boldtext}>   Message  </Typography>     </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {replyView.data?.message}    </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}> <Typography className={classes.boldtext}> Posted Date   </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}> {replyView.data?.created ? moment(replyView.data?.created).format("DD-MM-YYYY") : ""}  </Grid>


                </Grid>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={globalClasses.drawerFooter}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={toggleDrawer(anchor, false)}
                  >

                    Close
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </List>
        </Box>
      </>
    );

  useEffect(() => {
    fetchReplyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reducerValue]);

  const fetchReplyData = async () => {
    try {
      setLoader(true);
      const response = await viewAllReplyMessage({ token });
      if (response.data.status === true) {
        setReplyData(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };


  function handleStatus(id) {
    setLoader(true);
    changeMessageToPrimary({ id: id, token: token, }).then((response) => {
      if (response.data.status === true) {
        handleNotificationCall("success", response.data.message);
        forceUpdate();
      } else {
        handleNotificationCall("error", response.data.message);
      }
    })
      .catch(function (error) {
        console.log(error);
      })

    setLoader(false);

  }

  function handleShow(values, name) {
    setLoader(true);

    if (name === "EDIT") {
      setDataList("EDIT");
    } else {
      setDataList("VIEW");
    }

    viewReplyMessage({ id: values, token: token }).then((response) => {
      if (response.data.status === true) {

        setReplyView({
          ...replyView,
          data: response.data.data
        });

        onReplyEdit.setValues(response.data.data);

        setState({ ...state, right: true });
        setLoader(false);
      }
    })
      .catch(function (error) {
        console.log(error);
      })


  }

  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>

          <PageTitle title="Auto Reply" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={globalClasses.drawerClose}>
          <div className={globalClasses.lgButton}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              onClick={(e) => {
                onReplyAdd.resetForm();

                setDataList("ADD");
                setState({ ...state, right: true });
              }}
            >
              Add New Auto Reply
            </Button>
          </div>

          <div className={globalClasses.smButton}>
            <Button
              variant="contained"
              size="small"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              color="primary"
              onClick={(e) => {
                onReplyAdd.resetForm();

                setDataList("ADD");

                setState({ ...state, right: true });
              }}
            >

              Add
            </Button>
          </div>

        </Grid>
      </Grid>


      <Grid container spacing={2}>
        <Grid item xs={12}>


          <MUIDataTable
            options={{
              pagination: true,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
              responsive: mobileQuery === true ? 'vertical' : 'standard',
              //   customToolbar: () => <HeaderElements />,
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              }
            }}
            columns={[
              {
                name: "S.No",
              },

              {
                name: "Message",
              },
              {
                name: "Primary",
              },

              {
                name: "Posted Date",
              },
              {
                name: "Actions",
              },

            ]}
            data={replyData?.map((item, index) => {
              let replayMessage = ""
              if (item.message !== "") {
                replayMessage = item.message
              }
              // else if(item.templateId !==""){
              //   // var HEADER = JSON.parse(item.components).filter(
              //   //   (type) => type?.type === "HEADER",
              //   // )[0]?.format;
              //   replayMessage = JSON.parse(item.components).filter(
              //     (type) => type?.type === "BODY",
              //   )[0]?.text;
              //   // var BUTTONS = JSON.parse(item.components).filter(type => type?.type === "BUTTONS")[0]?.buttons;
              // }

              return [
                index + 1,
                replayMessage,
                <Switch
                  checked={parseInt(item.isPrimary) === 1 ? true : false}
                  onChange={(e) => {
                    handleStatus(item.id);
                  }}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }} />,
                item?.created ? moment(item?.created).tz(moment.tz.guess()).format('DD-MM-YYYY') : "",
                <>
                  <Grid container className={globalClasses.space}>
                    <Grid item xs className={globalClasses.toolAlign}>
                      <Tooltip
                        title="Edit Reply"
                        placement="bottom"
                        aria-label="edit"
                      >
                        <EditIcon
                          className={globalClasses.toolIcon}
                          onClick={(e) => {
                            handleShow(item.id, "EDIT");
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title="View Reply"
                        placement="bottom"
                        aria-label="view"
                      >
                        <ViewIcon
                          className={globalClasses.toolIcon}
                          onClick={(e) => {
                            handleShow(item.id, "VIEW");
                          }}
                        />
                      </Tooltip>

                    </Grid>
                  </Grid>
                </>,
              ];
            })}
          />


          <SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            classes={{ paper: globalClasses.drawer }}
            disableSwipeToOpen={false}
          >

            {list("right")}

          </SwipeableDrawer>
          {/* <Grid container spacing={2} className={classes.pagination}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={count}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid> */}
        </Grid>
      </Grid>


      <Backdrop className={globalClasses.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default Reply