import { api } from "./api";

export const addAuthentication = async (data, value) => {
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/addAuthentication", {
    phoneNumberId: data.phoneNumberId,
    accessToken: data.accessToken,
    waBusinessId: data.waBusinessId,
  });
  return response;
};


export const editAuthentication = async (data, value) => {
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/editAuthentication", {
    id: data.id,
    phoneNumberId: data.phoneNumberId,
    accessToken: data.accessToken,
    waBusinessId: data.waBusinessId,
  });
  return response;
};


export const viewAuthentication = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'
  const response = await api.post("instance/viewAuthentication", {
    userId: data.userId,
  });
  return response;
};

export const uploadFile = async (data, values) => {
  api.defaults.headers.common['Authorization'] = values.token;
  api.defaults.headers.common['Content-Type'] = 'multipart/form-data'
  const response = await api.post("instance/uploadFile", data);

  return response;
};

export const deleteInstance = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/deleteInstance", {
    id: data.id,
  });
  return response;
};


export const restartInstance = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/restartInstance", {
    id: data.id,
  });
  return response;
};



export const resetInstance = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/resetInstance", {
    id: data.id,
  });
  return response;
};

export const isInitiated = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/isInitiated", {
    mobile: data.mobile,
  });
  return response;
};


export const getUserInstance = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/getUserInstance", {
    instance_id: data.instance_id
  });
  return response;
};

export const changeAutoReplyStatus = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/changeAutoReplyStatus", {
    instance_id: data.instance_id
  });
  return response;
};



export const addReplyMessage = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'
  let reqData = {}
  if (data.message === "") {
    reqData = { templateId: data.templateId }
  } else if (data.templateId === "") {
    reqData = { message: data.message }
  }

  const response = await api.post("instance/addReplyMessage",
    reqData
  );
  return response;
};


export const viewAllReplyMessage = async (data) => {

  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/viewAllReplyMessage", {});

  return response;

};


export const viewReplyMessage = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/viewReplyMessage", {
    id: data.id
  });
  return response;
};



export const changeMessageToPrimary = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/changeMessageToPrimary", {
    id: data.id
  });
  return response;
};





export const editReplyMessage = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type'] = 'application/json'

  const response = await api.post("instance/editReplyMessage", {
    id: data.id,
    message: data.message
  });
  return response;
};