import React, { useState } from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import {
  Collapse,
  IconButton,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 4,
    margin: `${theme.spacing(2)}px auto`,
    padding: '8px !important' ,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 2),
    },
  },
  description: {
    lineHeight: 1.35,
  },
  expandIconBox: {
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  expanded: {
    marginTop: theme.spacing(2),
  },
  pointer: {
    cursor: "pointer",
  },
}));

const ApiExpandedItem = ({ row }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      className={clsx(classes.root, {
        [classes.pointer]: !expanded,
      })}
      onClick={handleToggle}
    >
      <Grid container display="flex" alignItems="center" justifyContent="space-around" flexDirection="row" style={{background: row.backgroundColor,padding:'8px' }}>
          <Grid item xs={1}>
            <Box 
                display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton>{row.customIcon}</IconButton>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box flex={1}>
              <Typography
                variant="body1"
                component="p"
                style={{ wordWrap: "break-word" }}
              >
                {row.title}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              className={classes.expandIconBox}
            >
              <IconButton
                size="small"
                aria-expanded={expanded}
                onClick={() => setExpanded(!expanded)}
              >
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </IconButton>
            </Box>
          </Grid>
          {/* <Box ml={2}>
          <Typography variant="body1">$10.00</Typography>
        </Box> */}
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid
          container
          spacing={2}
          className={classes.expanded}
          onClick={(event) => event.stopPropagation()}
        >
          <Grid item xs={12}>
            <div
              className="article-body"
              dangerouslySetInnerHTML={{
                __html: row.message,
              }}
            ></div>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default ApiExpandedItem;
