import { api } from "./api";
 


export const addContact = async (data, value) => {
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("contact/addContact", {
      name: data.name,
       email: data.email,
       mobile: value.mobile.slice(value.countryCode?.length),
       countryCode:value.countryCode,
       client_name: data.client_name,
    });
    return response;
  };

export const editContact = async (data, value) => {
 
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
   const response = await api.post("contact/editContact", {
    id: value.id,
    name: data.name,
    email: data.email,
    mobile: value.mobile,
    countryCode:value.countryCode,
    client_name: data.client_name,
  });
  return response;
};


export const deleteContacts = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("contact/deleteContacts", {
    contacts: data.contacts, 
 });
 return response;
};


export const deleteNoWhatsAppContact = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("contact/deleteNoWhatsAppContact", {
    phone_number: data.phone_number, 
 });
 return response;
};

export const viewContact = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("contact/viewContact", {
        id: data.id,
    });
    return response;
  };

  export const viewAllContact = async (data) => {

     
    var url= "contact/viewAllContacts"; 
    if(data?.role_code === "USER"){
      url= "contact/viewAllSubUserContact";
    }  
 
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
     try { 
      const response = await api.post(url, { 
        page: data.page,
        phone_number: data.phone_number
      });
      return response; 
      
    } catch (err) {
       console.error(err.message);
    }
   
  };

 



  
export const setContactTag = async (data) => {
 
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("contact/setContactTag", {
      tag_id: data.tag_id,
      message_contact_id: data.message_contact_id, 
    });
    return response;
  };
 

export const removeContactTag = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("contact/removeContactTag", {
    id: data.id, 
 });
 return response;
};


export const viewAllContactTags = async (data) => {

  var url= "contact/viewAllContactTags"; 
  if(data?.role_code === "USER"){
    url= "contact/viewAllSubUserContactTags";
  }  

  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post(url, {
    phoneNumber: data.phoneNumber, 
 });
 return response;
};




export const getContactsByTag = async (data) => {
  var url= "contact/getContactsByTag"; 
  if(data?.role_code === "USER"){
    url= "contact/getContactsByTagSubuser";
  }  
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post(url, {
    page: data.page,
    tag_id: data.tag_id, 
    phone_number: data?.phone_number
 });
 return response;
};



export const getNonWhatsAppContacts = async (data) => {

   
  var url= "contact/getNonWhatsAppContacts"; 
  if(data?.role_code === "USER"){
    url= "contact/getNonWhatsAppContactsSubUser";
  }  

  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post(url, {
    page: data.page, 
 });
 return response;
};


 
export const contactList = async (data) => {
  
  var url= "contact/contactList"; 
  if(data?.role_code === "USER"){
    url= "contact/contactList";
  }  

  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post(url, { 
    mobile:  data.mobile,
 });
 return response;
};