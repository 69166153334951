 import { makeStyles } from "@material-ui/styles";


export default makeStyles(theme => ({

    lgButton:{
        display: "none !important",
        [theme.breakpoints.up("sm")]: {
          display: "block !important",
        },
       
      },
      smButton:{ 
        display: "block !important",
        [theme.breakpoints.up("sm")]: {
          display: "none !important",
        }, 
      },


      
drawerHeader: {
    width: "50% !important",
    position: "fixed",
    display: "flex",
    padding: "10px 0px !important",
    marginLeft: "0",
    alignItems: "center",
    borderBottom: "1px solid rgb(0 0 0 / 10%)",
    justifyContent: "space-between",
    top: "0px",
    background: "#fff",
    zIndex:1, 
    [theme.breakpoints.only("sm")]: {
      width: "58% !important",
    },
    [theme.breakpoints.only("xs")]: {
     
      width: "79% !important",
     
    },
  },
  
  drawerSmHeader: {
    width: "30% !important",
    position: "fixed",
    display: "flex",
    padding: "10px 0px !important",
    marginLeft: "0",
    alignItems: "center",
    borderBottom: "1px solid rgb(0 0 0 / 10%)",
    justifyContent: "space-between",
    top: "0px",
    background: "#fff",
    zIndex:1, 
    [theme.breakpoints.only("sm")]: {
      width: "48% !important",
    },
    [theme.breakpoints.only("xs")]: {
     
      width: "59% !important",
      
    }, 
  },
  drawerContent:{
    marginTop:"25px",  
   // marginBottom: "70px",
   // height: "80vh",
   // height: "100%"
  }, 

  drawerViewContent:{
    marginTop:"25px",  
   height: "100vh", 
  }, 
  
  drawerClose: {
    position: "absolute",
    right:"20px",
    zIndex:10
  },

  drawerFooter: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1) !important",
    position: "fixed !important",
    bottom: "0 !important",
    width: "48% !important",
    justifyContent: "center !important",
    padding: "10px",
    background: "white",
    zIndex: "120",
    margin: "0px !important",
    gap: "10px",
  
    [theme.breakpoints.only("sm")]: {
      width: "58% !important",
    },
    [theme.breakpoints.only("xs")]: {
     
      width: "79% !important",
      position: "fixed  !important",
    }, 
  },

  
  drawerSmFooter: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1) !important",
    position: "fixed !important",
    bottom: "0 !important",
    width: "28% !important",
    justifyContent: "center !important",
    padding: "10px",
    background: "white",
    zIndex: "120",
    margin: "0px !important",
    gap: "10px",
  
    [theme.breakpoints.only("sm")]: {
      width: "48% !important",
    },
    [theme.breakpoints.only("xs")]: {
     
      width: "59% !important",
      position: "fixed  !important",
    }, 
  },

  closeBtn: {
    cursor: "pointer"
  },


  
  backdrop: {
    zIndex: 3500,
    color: '#00f',
  },

   drawer: {
    width: "50%",
    padding: "10px",
    [theme.breakpoints.only("xs")]: {
      width: "85%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "60%",
    }
  },

  smallDrawer:{
    width: "30%",
    padding: "10px",
    [theme.breakpoints.only("xs")]: {
      width: "65%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "50%",
    }
  },

  customSelectField:{
    paddingLeft: "10px !important",
    "& input:placeholder": {
      fontSize: "13px"
    },
    marginTop: "5px !important",
   // padding: "10px 12px !important",
   // border: "1px solid #ced4da",

   "& input":{
    padding: "10px 12px !important",
   
   },
   "& textarea":{
    padding: "10px 12px !important",
   },
   "& MuiInputBase-formControl":{
    padding: "10px 12px !important",
    border: "1px solid #edecec",
   }
  },
  customTextField: {
    "& input:placeholder": {
      fontSize: "13px"
    },
    marginTop: "5px !important",
   // padding: "10px 12px !important",
   // border: "1px solid #ced4da",

   "& input":{
    padding: "10px 12px !important",
   
   },
   "& textarea":{
    padding: "10px 12px !important",
   },
   "& MuiInputBase-formControl":{
    padding: "10px 12px !important",
    border: "1px solid #edecec",
   }
  },

  loginTextField: {
    "& input:placeholder": {
      fontSize: "13px"
    },
    marginTop: "5px !important",
   // padding: "10px 12px !important",
   // border: "1px solid #ced4da",

   "& input":{
    padding: "15px 12px !important",
   
   },
   "& textarea":{
    padding: "15px 12px !important",
   },
   "& MuiInputBase-formControl":{
    padding: "15px 12px !important",
    border: "1px solid #edecec",
   }
  },

  regCustomTextField: {
    "& input:placeholder": {
      fontSize: "13px"
    },
    marginTop: "5px !important", 
   "& input":{
    padding: "15px 12px !important",
    // borderLeft: "1px solid #ced4da !important",
   },
    
  },

  fields: {
    display: "flex", 
    alignItems: "center",
    maxWidth: "33.3333% !important",
    flexBasis: "33.333333% !important",
    padding: "10px"    
  },
 
  gap10:{
    gap: "10px",
    display: "flex",
  },

  displayBreak:{
    "& $gap10":{
     
        [theme.breakpoints.only("xs")]: {
        display: "block",
        }
    }
},

  button: {
    display: "flex", 
    gap: "10px",
    //  padding: "0px 10px",
     
    whiteSpace: "nowrap",
       [theme.breakpoints.down("xs")]: { 
        display: "inline-flex",
        flexDirection:"column"
    },
  },

  alignItemsEnd:{
    alignItems: "self-end"
  
  },
 
  marginTop15: {
    marginTop: "15px",
  },
  toolAlign: {
    display: "flex",
    gap: "3px"
  },

  marginTop:{
    marginTop: "5px",
  },
    
  toolIcon: {
    cursor: "pointer",
    width: "25px",
    height: "25px",
    padding: "5px",
   // backgroundColor: "#064be2",
    backgroundColor: "#2196f3",
    borderRadius: "100%",
    color: "white",
    boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)"
  },

  toolIconDelete: {
    cursor: "pointer",
    width: "25px",
    height: "25px",
    padding: "5px",
    backgroundColor: "#f50057",
    color: "white",
    borderRadius: "100%",
    boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)"
  },


  scrollCard: {
    padding: "40px 10px !important"
  },

 center: {
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
 
  alignItemsCenter: {
  display: "flex", 
  alignItems: "center" 
},

  formContainer:{
    paddingTop: "15px",
    display: "flex",
    gap: "10px",
    margin: "10px"
  },

  displayEnd: {
    display: 'flex',
    justifyContent: 'end',
    gap: "10px", 
  },

 
  customOutlinedTextField: {
    "& input:placeholder": {
      fontSize: "13px"
    },
    marginTop: "5px !important",
      
   "& MuiInputBase-formControl":{
     border: "1px solid #edecec",
   }
  },

spaceBetween:{
  justifyContent: "space-between",
  display: "flex"
},

tab:{
  cursor: "pointer", padding: "6px 15px",  gap:"10px", height: "60px",
  "& div":{
    minWidth: "36px !important"
  }

},

p24x8:{
  padding: "24px 8px",
  [theme.breakpoints.down("md")]: { 
    padding: "24px",
},
}, 
 
hide:{
  display: "none"
},
 
block:{
  display:"block"
},
 

active:{
  background: "#2196f3 ",
  borderRadius: "15px",
  "& span":{
    color: "white !important",
  },
  "& div":{
    color: "white !important",
    alignItems: "center",
    display: "flex"
  }
},

 
chip: {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '& > *': {
    margin: theme.spacing(0.5),
  },
},

HeaderElements: {
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  gap: '10px',
 },

 Blink:{
  animation: "$blinker 1s linear infinite",
  color:"red",  
 },

 '@keyframes blinker': {
  '50%': {
   opacity: 0,
  }, 
  }, 

  dialogTitle:{
    height: "50px",
    padding:10,
   // backgroundColor:"#064be2 !important",
    backgroundColor: "#2196f3 !important", 
 "& p":{ 
  color:"#fff",
  fontSize:"22px"
 }
  },

  a:{
    textDecoration:"none",
    color:"white"
  },
  pagination: {
    
    padding: "10px",
    paddingBottom: "0px",
    justifyContent: 'end',
    borderBottom: "1px solid rgba(224, 224, 224, 1)"
  },

  weightPB10: {
    fontWeight:"bold", 
    paddingBottom: "10px"
  },
 border:{
  borderRadius: "25px !important",
  padding: "0px 10px !important", 
  boxShadow: "0px 2px 13px 5px #2f3c4a14 !important",
  "& input":{
    borderLeft: "1px solid #2f3c4a36 !important", 
  }  
 },
 

 
filterGap: {
  display: "flex",
  justifyContent: "center",
  alignItems: "center", 
  columnGap: "10px",
  rowGap: "10px",
  paddingTop: "10px",
  
},
filterFullWidth:{ 
  width: "240px",
  [theme.breakpoints.only("xs")]: {
    width: "calc(100% - 8%)",
  },
  [theme.breakpoints.only("sm")]: {
    width: "150px;", 
  },
  "& input":{
    padding: "10px 12px !important",
   
   }
},



selectFullWidth:{ 
  width: "240px",
  [theme.breakpoints.only("xs")]: {
    width: "calc(100% - 8%)",
  },
  [theme.breakpoints.only("sm")]: {
    width: "150px;", 
  },
  "& input":{
    
   
   }
},
 

buttons: {
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  gap: "10px",
  whiteSpace: "nowrap",
      
},
pointerEventsNone:{
  pointerEvents: "none"
},

tabpanel3:{   
  [theme.breakpoints.only("xs")]: { 
  padding: "24px 0px !important",  
}
},

dialogHeader:{
  alignItems: "center",justifyContent: "space-between", color:"white", display: "flex", padding:"0px 20px"

},

fontMargin:{
  fontSize:14, 
  marginRight:5,
  [theme.breakpoints.only("xs")]: {
      fontSize:20, 
    },
},
searchContactTable:{
  [theme.breakpoints.down("sm")]: { 
    marginTop: "24px"
  }
},
searchContactInput:{
  position: "absolute",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    width: "100%",
    top:"28px",
    [theme.breakpoints.down("sm")]: { 
      top:"16px",
    }
},
form:{
  gap: 10,
  display: "flex",
  height: 40
}
 
}));
