import React, { useState, useEffect, useContext, useRef } from "react";
//  import Container from '@material-ui/core/Container';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {
  deleteInstance,
  restartInstance,
  resetInstance,
} from "../../services/instance.api";
import instanceIcon from "../../images/instance.svg"
// import { getAllPlans } from '../../services/plans.api';
// import { addUserTransaction, getPaymentSuccess } from '../../services/transaction.api';
import CloseIcon from "@material-ui/icons/Close";
// import useRazorpay from "react-razorpay";

import {
  FcPrivacy,
  FcNews,
  FcVoicePresentation,
} from "react-icons/fc";
import { AiOutlineClear } from "react-icons/ai";
import { RiRestartFill } from "react-icons/ri";

import useStyles from "./style";
import globalStyles from "../../themes/style";
import Notification from "../../components/Notification/Notification";
import { toast } from "react-toastify";
import {
  Button,
  Grid,
  Typography,
  Box,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from "@material-ui/core";
import InstanceContext from "../../context/InstanceContext";
import Settings from "./Settings";

function TabPanel(props) {
  const globalClasses = globalStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          style={{ padding: "14px 0px" }}
          className={index === 3 ? globalClasses.tabpanel3 : ""}
        >
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// const StyledBadge = withStyles((theme) => ({
// badge: {
// backgroundColor: '#44b700',
// color: '#44b700',
// boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
// '&::after': {
// position: 'absolute',
// top: 0,
// left: 0,
// width: '100%',
// height: '100%',
// borderRadius: '50%',
// animation: '$ripple 1.2s infinite ease-in-out',
// border: '1px solid currentColor',
// content: '""',
// },
// },
// '@keyframes ripple': {
// '0%': {
// transform: 'scale(.8)',
// opacity: 1,
// },
// '100%': {
// transform: 'scale(2.4)',
// opacity: 0,
// },
// },
// }))(Badge);

export default function Home(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();

  const queryParams = new URLSearchParams(props.location.search);
  const values = queryParams.get("value");
  const mobileViewOnly = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  // const [payOpen, setPayOpen] = useState(false);

  // const [Razorpay] = useRazorpay();

  // const handlePayClose = () => {
  //   setPayOpen(false);

  // };

  // const handlePayOpen = (url) => {
  //   setPayOpen(true);
  // };
  const [loader, setLoader] = useState(false);
  const { instanceId, setInstanceId, reset } = useContext(InstanceContext);

  //var moment = require('moment-timezone');
  const [restart, setRestart] = React.useState(false);
  // const [reset, setReset] = React.useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("webWAInfoapto_PX11925");
  // const [instanceId, setInstanceId] = React.useState();
  // const [planList, setPlanList] = React.useState([]);

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const adminTitlePaths = [
    "authentication",
    "template",
    "sendMessage",
    "infoMessages",
  ];

  const userTitlePaths = [
    "template",
    "sendMessage",
    "infoMessages",
  ];

  const titlePaths = user?.role_code === "ADMIN" ? adminTitlePaths : userTitlePaths;

  const activeTabIndex = titlePaths.findIndex((path) =>
    pathname.includes(path),
  );

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  const [value, setValue] = React.useState(parseInt(values ? values : 0));

  const [removeOpen, setRemoveOpen] = React.useState(false);

  const handleRemoveOpen = () => {
    setRemoveOpen(true);
  };

  const handleRemoveClose = () => {
    setRemoveOpen(false);
  };

  // useEffect(() => {
  //   if (user?.role_code !== "SUPERADMIN") {
  //     const changeInstanceId = () => {
  //       getInstanceId(user?.userId);
  //     };

  //     getInstanceId(user?.userId);
  //     window.addEventListener("storage", changeInstanceId);
  //     return () => {
  //       window.removeEventListener("storage", changeInstanceId);
  //     };
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // function getInstanceId(id) {
  //   viewAuthentication({ userId: id, token: token })
  //     .then((response) => {
  //       if (response.data.status === true) {
  //         setInstanceId(JSON.parse(JSON.stringify(response?.data?.data)));

  //         if (parseInt(response?.data?.data?.istrash) === 1) {
  //           // setReset(true);
  //         }
  //       } else {
  //         if (user?.role_code === "USER") {
  //           handleNotificationCall("error", "Instance not found");
  //           setTimeout(() => {
  //             history.push("dashboard");
  //           }, 5000);
  //         }
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  // function getAllPlanList(){
  //   getAllPlans({id: user?.userId, token:token}).then((response) => {

  //     if (response.data.status === true) {

  //       handlePayOpen();
  //       setPlanList(response.data.data);
  //      }
  //   })
  //   .catch(function (error) {
  //    console.log(error);
  //  })
  // }

  function onDeleteInstance() {
    setLoader(true);
    deleteInstance({ id: instanceId?.id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          handleNotificationCall("success", response.data.message);
          setInstanceId();
          setValue(user?.role_code === "ADMIN" ? 0 : 1);
        } else {
          handleNotificationCall("error", response.data.message);
        }

        handleRemoveClose();
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function onRestartInstance() {
    setLoader(true);
    restartInstance({ id: instanceId?.id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          window.dispatchEvent(new Event("storage"));
          handleNotificationCall("success", response.data.message);
          setInstanceId();
        } else {
          handleNotificationCall("error", response.data.message);
        }
        handleRemoveClose();
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function onResetInstance() {
    setLoader(true);
    resetInstance({ id: instanceId?.id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          window.location.reload();
        } else {
          handleNotificationCall("error", response.data.message);
        }
        handleRemoveClose();
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // function onAddUserTransaction(plan_id){
  //   setLoader(true);

  //   addUserTransaction({plan_id: plan_id, token:token}).then((response) => {

  //     if (response.data.status === true) {
  //    handlePayClose();
  //    setLoader(false);
  //       const options = {
  //           key: process.env.REACT_APP_RAZOR_KEY,
  //           currency: "INR",
  //           order_id: response.data.data.id,
  //           handler: (res) => {
  //             setLoader(true);

  //             getPaymentSuccess(res).then((result) => {

  //                     if (result.data.status === true) {
  //                       handleNotificationCall("success", result.data.message);
  //                       window.location.reload();
  //                      } else {
  //                       handleNotificationCall("error", "Something went wrong!!");
  //                      }
  //                      setLoader(false);
  //                   })
  //                   .catch(function (error) {
  //                    console.log(error);
  //                  })
  //           },
  //           theme: {
  //               color: "#3399cc",
  //           },
  //       };

  //       if (Razorpay) {
  //           const rzpay = new Razorpay(options);
  //           rzpay.open();
  //       }

  //      } else {
  //       setLoader(false);
  //       handleNotificationCall("error", "Something went wrong!!");
  //      }
  //   })
  //   .catch(function (error) {
  //    console.log(error);
  //  })
  // }
  const [showProfile, setShowProfile] = useState(true);

  useEffect(() => {
    const localStorageShowProfile = localStorage.getItem("is_proflie_slide_toggle");
    if (localStorageShowProfile !== null) {
      setShowProfile(localStorageShowProfile === "true");
    }
  }, []);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowProfile(false);
        localStorage.setItem("is_proflie_slide_toggle",false)
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleProfile = () => {
    setShowProfile(prevState => !prevState);
    localStorage.setItem("is_proflie_slide_toggle",!showProfile)
  };

  return (
        
      <div style={{position:'relative'}} ref={containerRef}>
        <div className="slide-toggle-btn toggle-arrow-top" onClick={toggleProfile}>
          <img src={instanceIcon} width="100%" alt="instance_ico" />
        </div> 

        <div className={`wrapper`}>
        <Grid
          container
          direction="row"
          spacing={2}
          className={`${showProfile ? 'slideIn' : 'slideOut'} ${classes.profileHead}`}
        >
          <div className={classes.profileHeadLeft}>
            <Grid container spacing={1} className={classes.profileBody}>
              <div>
                <Avatar
                  alt="Image"
                  className={classes.profileAvatar}
                  sizes="large"
                  src={instanceId ? instanceId?.profileImage : user?.firstname}
                />
              </div>
              <div
                className={classes.userDetails}
              >
                <Typography variant="h6">
                  {instanceId?.name ? instanceId?.name : "Instance"}
                </Typography>
                <Typography variant="body1"> {instanceId?.mobile}</Typography>
                {/* <Typography variant='body1'> Valid till { moment(instanceId?.validity_end_date).tz(moment.tz.guess()).format("DD-MM-YYYY")}</Typography>  */}
                {/* <Typography variant='body1'> { moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >  moment(instanceId?.validity_end_date).tz(moment.tz.guess()).format("YYYY-MM-DD")  ? <div className={globalClasses.Blink}>Your Instance Validity Has Expired Please Recharge!  </div> : " "  } </Typography> */}
              </div>
            </Grid>
          </div>

          <div className={`${classes.profileHeadRight} ${classes.bannerBtnContainer}`}>
            <div>
              <Settings/>
            </div>
            {user?.role_code === "ADMIN" && (
              <div className={classes.bannerBtn}>
                {reset === false ? (
                  <>
                    <Button
                      startIcon={<RiRestartFill style={{ color: "#fff" }} />}
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={(e) => {
                        setRestart(true);
                        handleRemoveOpen();
                      }}
                    >
                      Restart
                    </Button>
                    <Button
                      startIcon={<AiOutlineClear style={{ color: "#fff" }} />}
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={(e) => {
                        setRestart(false);
                        handleRemoveOpen();
                      }}
                    >
                      Clear
                    </Button>
                  </>
                ) : (
                  <Button
                    startIcon={<RiRestartFill style={{ color: "#fff" }} />}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={(e) => {
                      setRestart(null);
                      handleRemoveOpen();
                    }}
                  >
                    Reset
                  </Button>
                )}
              </div>
            )}
          </div>
        </Grid>
        </div>

        <AppBar position="static" className={globalClasses.marginTop15} style={{ background: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",border: "2px solid #EEEEEE", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",zIndex:1}}>
          <Tabs
            value={activeTabIndex}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className={classes.instanceTabList}
            TabIndicatorProps={
              {
                style: {
                  backgroundColor: "#D97D54",
                  borderRadius: "8px",
                  zIndex:0
                }
              }
            }
          >
            {user?.role_code === "ADMIN" && (
              <Tab
                label={mobileViewOnly && "Authentication"}
                icon={<FcPrivacy style={{ fontSize: 30 }} />}
                onClick={() => history.push("authentication")}
              />
            )}
            {reset === false && parseInt(instanceId?.is_valid) === 1 && (
              <Tab
                label={mobileViewOnly && "Template"}
                icon={<FcNews style={{ fontSize: 30 }} />}
                onClick={() => history.push("template")}
              />
            )}
            {reset === false && parseInt(instanceId?.is_valid) === 1 && (
              <Tab
                label={mobileViewOnly &&"Send Message"}
                icon={<FcVoicePresentation style={{ fontSize: 30 }} />}
                onClick={() => history.push("sendMessage")}
              />
            )}
            {reset === false && parseInt(instanceId?.is_valid) === 1 && (
              <Tab
                label={mobileViewOnly &&"Message"}
                icon={<WhatsAppIcon style={{ color: "green" }} />}
                onClick={() => history.push("infoMessages")}
              />
            )}
          </Tabs>
        </AppBar>

        {user?.role_code === "ADMIN" && (
          <TabPanel value={value} index={0}>

          </TabPanel>
        )}
        <TabPanel value={value} index={user?.role_code === "ADMIN" ? 1 : 0}>
        </TabPanel>
        <TabPanel value={value} index={user?.role_code === "ADMIN" ? 2 : 1}>
        </TabPanel>

        <TabPanel
          value={value}
          index={user?.role_code === "ADMIN" ? 3 : 2}
          className={globalClasses.padding10}
        >
        </TabPanel>

        {user?.role_code === "ADMIN" && (
          <TabPanel value={value} index={user?.role_code === "ADMIN" ? 4 : 3}>
          </TabPanel>
        )}

      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.drawerFooter}
      >
        {/* <Dialog onClose={handlePayClose} aria-labelledby="dialog-title" open={payOpen} width='md' PaperProps={{ style: {  width: '100%' } }}>
           <DialogContent style={{ padding: "0px"}} > 
        <Grid className={globalClasses.center +" "+globalClasses.dialogTitle}  >
 
<Grid
            container
            direction="row"
            spacing={2}
            
          >
                <Grid item xs={12} className={globalClasses.dialogHeader}>
            <Typography  variant="subtitle1"> Pricing</Typography>

          
              <CloseIcon
                className={globalClasses.closeBtn}
                size="14px"
                onClick={(e)=>[
                  handlePayClose()
                ]}
              />
            </Grid>
          </Grid>
      </Grid>
     
      <Container   component="main" style={{ paddingBottom: "20px"}}>
        <Grid container spacing={2} alignItems="center" justifyContent='center' className={globalClasses.gap10 + " " +globalClasses.marginTop15}>
          {planList.map((item) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={item.validity} xs={5}  className={classes.card}>
              <Card>
                <CardHeader
                  title={item.title}
                  subheader={item.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                 
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.cardPricing}>
                    <Typography component="h6" variant="h6" color="textPrimary">
                    ₹ {item.price} 
                    </Typography>
                    
                  </div>
                   
                  
                      <Typography  variant="body1"    >
                      {item.description}
                      </Typography>
                    
                  
                </CardContent>
                <CardActions>
                  <Button fullWidth  variant="contained"   size="small" color="primary"
                  
                  onClick={(e)=>{
                onAddUserTransaction(item.id);
                  }}>
                 Pay Now
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

     

             </DialogContent>

</Dialog> */}

        <Dialog
          onClose={handleRemoveClose}
          aria-labelledby="dialog-title"
          open={removeOpen}
          width="md"
          PaperProps={{ style: { width: "100%" } }}
        >
          <DialogContent style={{ padding: "0px" }}>
            <Grid
              className={globalClasses.center + " " + globalClasses.dialogTitle}
            >
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} className={globalClasses.dialogHeader}>
                  <Typography variant="subtitle1">
                    {restart === true
                      ? "Are you sure you want to restart?"
                      : restart === false
                      ? "Are you sure you want to clear?"
                      : "Are you sure you want to reset?"}
                  </Typography>

                  <CloseIcon
                    className={globalClasses.closeBtn}
                    size="14px"
                    onClick={(e) => [handleRemoveClose()]}
                  />
                </Grid>
              </Grid>
            </Grid>

            <DialogActions className={globalClasses.marginTop15}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => {
                  restart === true
                    ? onRestartInstance()
                    : restart === false
                    ? onDeleteInstance()
                    : onResetInstance();
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={handleRemoveClose}
              >
                No
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Grid>
      <div id={loader === true ? "spinner" : ""}></div>
      <div className={loader === true ? "main" : ""}></div>
    </div>
  );
}
