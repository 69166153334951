import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  avatar: {
    width: "40px !important",
    height: "40px !important",
    padding:'20px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
  },
  text: {
    color: "white",
  },
  chatListAvatar: {
    width: "40px !important",
    height: "40px !important"
},
}));
