import { api } from "./api";
 
 

export const getAllMessages = async (data) => {
  var url= "instance/getAllMessages"; 
  
  if(data?.role_code === "USER"){
    url= "instance/getAllSubUserMessages";
  }  

  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, {
      phone_number: data.phone_number,
      page: data.page
    });
    return response;
  };
 

  export const markMessageRead = async (data) => {
    var url= "recive/markMessageRead"; 
   
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, {
      mobile_number: data.mobile_number,
    });
    return response;
  };
 

  export const viewAllMessageContacts = async (data) => {
    var url= "contact/viewAllMessageContacts"; 
    
    if(data?.role_code === "USER"){
      url= "contact/viewAllSubUserMessageContact";
    }  

    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    
    try { 
     const response = await api.post(url, { page:data.page});
     return response; 
     
   } catch (err) {
      console.error(err.message);
   }
  
 };


 export const viewAllMessageContactCaptions = async (data) => {
  var url= "contact/viewDisplayDetails"; 
  
  // if(data?.role_code === "USER"){
  //   url= "contact/viewAllSubUserMessageContact";
  // }  

  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  
  try { 
   const response = await api.post(url, { mobile:data.mobile});
   return response; 
   
 } catch (err) {
    console.error(err.message);
 }

};
 
 export const deleteMessages = async (data) => {
  var url= "instance/deleteMessages"; 
  
  if(data?.role_code === "USER"){
    url= "instance/deleteMessages";
  }  

  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  
  try { 
   const response = await api.post(url, { 
    messages : data.messages,
   });
   return response; 
   
 } catch (err) {
    console.error(err.message);
 }

};
  




export const deleteConversation = async (data) => {
  var url= "instance/deleteConversation"; 
  
  if(data?.role_code === "USER"){
    url= "instance/deleteConversation";
  }  

  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  
  try { 
   const response = await api.post(url, { 
    mobile: data.mobile
   });
   return response; 
   
 } catch (err) {
    console.error(err.message);
 }

};
