import React, { useState, useEffect, useContext } from "react";
import useStyles from "./style";
import globalStyles from "../../themes/style.js";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  Button,
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Backdrop,
  Chip,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { saveTemplates, viewAllTemplates } from "../../services/template.api";

import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import InstanceContext from "../../context/InstanceContext.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function Template() {
  const classes = useStyles();
  const globalClasses = globalStyles();

  const [value, setValue] = useState(0);
  const [templateData, setTemplateData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { instanceId, isInstance } = useContext(InstanceContext);

  var token = localStorage.getItem("webWAInfoapto_PX11925");

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (instanceId !== null && isInstance) {
      fetchData();
    }
  }, [instanceId, isInstance]);

  const fetchData = async () => {
    viewAllTemplates({ instance_id: instanceId?.id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          setTemplateData(response.data?.data);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function templatesSave() {
    setLoader(true);

    saveTemplates({ instance_id: instanceId?.id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          handleNotificationCall("success", response.data.message);
        } else {
          handleNotificationCall("error", response.data.message);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className={globalClasses.displayEnd}>
          {parseInt(instanceId?.is_valid) === 1 ? (
            <Button
              startIcon={<AutorenewIcon />}
              variant="contained"
              size="small"
              onClick={(e) => {
                templatesSave();
              }}
            >
              Template sync
            </Button>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} className={classes.tabsContainer}>
          {/* Template Tab Panel */}
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              className={classes.tempTabList}
              TabIndicatorProps={
                {
                  // style: {
                  //   display: "none"
                  // }
                }
              }
            >
              <Tab
                label="All"
                icon={
                  <span className={classes.countCircle + " " + classes.gray}>
                    {templateData?.length}
                  </span>
                }
                iconPosition="start"
                {...a11yProps(0)}
              />
              <Tab
                label="Submitted"
                icon={
                  <span className={classes.countCircle + " " + classes.blue}>
                    {
                      templateData.filter(
                        (status) => status?.status === "SUBMITTED",
                      )?.length
                    }
                  </span>
                }
                {...a11yProps(1)}
              />
              <Tab
                label="Approved"
                icon={
                  <span className={classes.countCircle + " " + classes.green}>
                    {
                      templateData.filter(
                        (status) => status?.status === "APPROVED",
                      )?.length
                    }
                  </span>
                }
                {...a11yProps(2)}
              />
              <Tab
                label="Rejected"
                icon={
                  <span className={classes.countCircle + " " + classes.orange}>
                    {
                      templateData.filter(
                        (status) => status?.status === "REJECTED",
                      )?.length
                    }
                  </span>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} className={classes.TabPanelList}>
            <List className={classes.tempListContainer}>
              {templateData?.length !== 0 ? (
                templateData?.map((item, index) => {
                  return (
                    <div key={index}>
                      <ListItem>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            className={
                              globalClasses.spaceBetween+" "+globalClasses.weightPB10
                            }
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={10} md={10} lg={10}>
                                <p>
                                  {item.name}
                                </p>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                md={2}
                                lg={2}
                                className={globalClasses.displayEnd}
                              >
                                <Chip
                                  className={
                                    item.status === "SUBMITTED"
                                      ? classes.blue
                                      : item.status === "APPROVED"
                                      ? classes.green
                                      : classes.orange
                                  }
                                  label={item.status}
                                />
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="text"
                            className={globalClasses.marginTop}
                          >
                            {
                              JSON.parse(item.components).filter(
                                (type) => type?.type === "BODY",
                              )[0].text
                            }
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </div>
                )
                })
              ) : (
                <div
                  className={globalClasses.center}
                  style={{ height: "300px" }}
                >
                  No data found
                </div>
              )}
            </List>
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.TabPanelList}>
            <List className={classes.tempListContainer}>
              {templateData?.filter((status) => status?.status === "SUBMITTED")
                ?.length !== 0 ? (
                templateData
                  ?.filter((status) => status?.status === "SUBMITTED")
                  ?.map((item, index) => {
                    return [
                      <div key={index}>
                        <ListItem>
                          <ListItemText>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold" }}
                            >
                              {item.name}
                            </Typography>
                            <Typography variant="text">
                              {
                                JSON.parse(item.components).filter(
                                  (type) => type?.type === "BODY",
                                )[0].text
                              }
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </div>,
                    ];
                  })
              ) : (
                <div
                  className={globalClasses.center}
                  style={{ height: "300px" }}
                >
                  No data found
                </div>
              )}
            </List>
          </TabPanel>
          <TabPanel value={value} index={2} className={classes.TabPanelList}>
            <List className={classes.tempListContainer}>
              {templateData?.filter((status) => status?.status === "APPROVED")
                ?.length !== 0 ? (
                templateData
                  ?.filter((status) => status?.status === "APPROVED")
                  ?.map((item, index) => {
                    return [
                      <div key={index}>
                        <ListItem>
                          <ListItemText>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold" }}
                            >
                              {item.name}
                            </Typography>
                            <Typography variant="text">
                              {
                                JSON.parse(item.components).filter(
                                  (type) => type?.type === "BODY",
                                )[0].text
                              }
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </div>,
                    ];
                  })
              ) : (
                <div
                  className={globalClasses.center}
                  style={{ height: "300px" }}
                >
                  No data found
                </div>
              )}
            </List>
          </TabPanel>
          <TabPanel value={value} index={3} className={classes.TabPanelList}>
            <List className={classes.tempListContainer}>
              {templateData?.filter((status) => status?.status === "REJECTED")
                ?.length !== 0 ? (
                templateData
                  ?.filter((status) => status?.status === "REJECTED")
                  ?.map((item, index) => {
                    return [
                      <div key={index}>
                        <ListItem>
                          <ListItemText>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold" }}
                            >
                              {item.name}
                            </Typography>
                            <Typography variant="text">
                              {
                                JSON.parse(item.components).filter(
                                  (type) => type?.type === "BODY",
                                )[0].text
                              }
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </div>,
                    ];
                  })
              ) : (
                <div
                  className={globalClasses.center}
                  style={{ height: "300px" }}
                >
                  No data found
                </div>
              )}
            </List>
          </TabPanel>
        </Grid>
      </Grid>
      <Backdrop className={globalClasses.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Template;
