import React from "react";
import Highlighter from "react-highlight-words";

import Linkify from "react-linkify";

const WACaptions = ({ row, messageText }) => {
  const { caption } = row || "";

  const urlRegex = /([a-zA-Z0-9]+:\/\/)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\.[A-Za-z]{2,4})(:[0-9]+)?(\/.*)?/g;
  const containsUrl = urlRegex.test(caption);

  // const wrapUrlsInAnchorTags = (text) => {
  //   return text.replace(urlRegex, (url) => {
  //     const href = url.match(/^[a-zA-Z0-9]+:\/\//) ? url : `http://${url}`;
  //     return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  //   });
  // };

  const linkDecorator = (decoratedHref, decoratedText, key) => (
    <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
      {decoratedText}
    </a>
  );


  return (
    <>
    {containsUrl ? (
        <Linkify componentDecorator={linkDecorator}>{caption}</Linkify>
      ) : (
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={[messageText]}
          autoEscape={true}
          textToHighlight={caption}
        />
      )}
    </>
  );
};

export default WACaptions;
