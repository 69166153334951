import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  Switch,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./style";
//import globalStyles from "../../themes/style.js";
import {
  changeAutoReplyStatus,
} from "../../services/instance.api";
import Notification from "../../components/Notification/Notification";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InstanceContext from "../../context/InstanceContext";

function Settings() {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const token = localStorage.getItem("webWAInfoapto_PX11925");
  const { instanceId, isInstance } = useContext(InstanceContext);

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  const [replyValue, setReplyValue] = useState(false);

  function handleStatus(value) {
    setLoader(true);
    changeAutoReplyStatus({ instance_id: instanceId?.id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          handleNotificationCall("success", response.data.message);
          // handleInstanceId();
          setReplyValue(!replyValue)
        } else {
          handleNotificationCall("error", response.data.message);
        }

        setReplyValue(value);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (instanceId !== null && isInstance) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceId, isInstance]);

  const fetchData = () => {
    setReplyValue(
      parseInt(instanceId?.is_enable_autoreply) === 1 ? true : false,
    );
  }

 
  // function handleInstanceId() {
  //   viewAuthentication({ userId: user?.userId, token: token })
  //     .then((response) => {
  //       if (response.data.status === true) {
  //         setInstanceId(JSON.parse(JSON.stringify(response?.data?.data)));

  //         if (parseInt(response?.data?.data?.istrash) === 1) {
  //           setInstanceId();
  //         }
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
            <Typography>
              Enable Quick Reply
              <Switch
                checked={replyValue}
                onChange={(e) => {
                  handleStatus(e.target.checked);
                }}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Typography>
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Settings;
