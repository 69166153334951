import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./style";
import globalStyles from "../../themes/style.js";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { instanceSchema } from "../../components/Yup/Yup";
import {
  addAuthentication,
  editAuthentication,
} from "../../services/instance.api";
import { saveTemplates } from "../../services/template.api";
import { useFormik } from "formik";
import InstanceContext from "../../context/InstanceContext.js";

function Authentication() {
  const classes = useStyles();
  const globalClasses = globalStyles();
  // var moment = require('moment-timezone');
  const [loader, setLoader] = useState(false);
  // const [instanceId, setInstanceId] = useState();
  const {instanceId, isInstance} = useContext(InstanceContext)
  const token = localStorage.getItem("webWAInfoapto_PX11925");

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  useEffect(() => {
    if (instanceId !== null && isInstance) {
      fetchData();
    }
  }, [instanceId, isInstance]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoader(true);
  //     viewAuthentication({ userId: user?.userId, token: token })
  //       .then((response) => {
  //         if (response.data.status === true) {
  //           onInstanceEdit.setValues(response.data.data);
  //         } else {
  //           onInstanceEdit.setValues({
  //             id: instanceId?.id,
  //             phoneNumberId: instanceId?.phoneNumberId,
  //             accessToken: instanceId?.accessToken,
  //             waBusinessId: instanceId?.waBusinessId,
  //           });
  //         }
  //         setLoader(false);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   };
  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [reducerValue]);

  // useEffect(() => {
  //   if (user?.role_code !== "SUPERADMIN") {
  //     const changeInstanceId = () => {
  //       getInstanceId(user?.userId);
  //     };

  //     getInstanceId(user?.userId);
  //     window.addEventListener("storage", changeInstanceId);
  //     return () => {
  //       window.removeEventListener("storage", changeInstanceId);
  //     };
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // function getInstanceId(id) {
  //   viewAuthentication({ userId: id, token: token })
  //     .then((response) => {
  //       if (response.data.status === true) {
  //         setInstanceId(JSON.parse(JSON.stringify(response?.data?.data)));

  //         if (parseInt(response?.data?.data?.istrash) === 1) {
  //           setReset(true);
  //         }
  //       } else {
  //         if (user?.role_code === "USER") {
  //           handleNotificationCall("error", "Instance not found");
  //           setTimeout(() => {
  //             history.push("dashboard");
  //           }, 5000);
  //         }
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  const onInstanceEdit = useFormik({
    initialValues: {
      id: "",
      phoneNumberId: "",
      accessToken: "",
      waBusinessId: "",
    },
    validationSchema: instanceSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      editAuthentication(values, { token: token })
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);
            window.dispatchEvent(new Event("storage"));
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const fetchData=()=>{
    if(isInstance){
      onInstanceEdit.setValues(instanceId)
    }else{
      onInstanceEdit.setValues({
        id: instanceId?.id,
        phoneNumberId: instanceId?.phoneNumberId,
        accessToken: instanceId?.accessToken,
        waBusinessId: instanceId?.waBusinessId,
      });
    }
  }

  const onInstanceAdd = useFormik({
    initialValues: {
      phoneNumberId: "",
      accessToken: "",
      waBusinessId: "",
    },
    validationSchema: instanceSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      addAuthentication(values, { token: token })
        .then((response) => {
          if (response.data.status === true) {
            saveTemplates({
              instance_id: response.data.instance_id,
              token: token,
            })
              .then((result) => {
                if (result) {
                  handleNotificationCall("success", response.data.message);
                  resetForm();
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            handleNotificationCall("error", response.data.message);
          }

          fetchData();
          window.dispatchEvent(new Event("storage"));
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });


  return (
    <>
      <Grid
        container
        spacing={2}
        direction="column"
        className={classes.tabsContainer}
      >
        <Grid item xs={12}>
          {instanceId?.id ? (
            <form onSubmit={onInstanceEdit.handleSubmit}>
              <Grid container spacing={2} className={classes.p25}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <InputLabel shrink htmlFor="phoneNumberId">
                    Phone Number Id :
                  </InputLabel>

                  <FormControl className={classes.margin}>
                    <TextField
                      classes={{ root: globalClasses.customTextField }}
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      placeholder="Enter whatsApp business account ID"
                      type="text"
                      name="phoneNumberId"
                      value={onInstanceEdit.values.phoneNumberId}
                      onChange={onInstanceEdit.handleChange}
                      error={
                        onInstanceEdit.touched.phoneNumberId &&
                        Boolean(onInstanceEdit.errors.phoneNumberId)
                      }
                      helperText={
                        onInstanceEdit.touched.phoneNumberId &&
                        onInstanceEdit.errors.phoneNumberId
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <InputLabel shrink htmlFor="waBusinessId">
                    WhatsApp Business Account ID (Waba) :
                  </InputLabel>

                  <FormControl className={classes.margin}>
                    <TextField
                      classes={{ root: globalClasses.customTextField }}
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      placeholder="Enter description"
                      type="text"
                      name="waBusinessId"
                      value={onInstanceEdit.values.waBusinessId}
                      onChange={onInstanceEdit.handleChange}
                      error={
                        onInstanceEdit.touched.waBusinessId &&
                        Boolean(onInstanceEdit.errors.waBusinessId)
                      }
                      helperText={
                        onInstanceEdit.touched.waBusinessId &&
                        onInstanceEdit.errors.waBusinessId
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel shrink htmlFor="accessToken">
                    Access token :
                  </InputLabel>

                  <FormControl className={classes.margin}>
                    <TextField
                      classes={{ root: globalClasses.customTextField }}
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      placeholder="Enter access token"
                      type="text"
                      name="accessToken"
                      value={onInstanceEdit.values.accessToken}
                      onChange={onInstanceEdit.handleChange}
                      error={
                        onInstanceEdit.touched.accessToken &&
                        Boolean(onInstanceEdit.errors.accessToken)
                      }
                      helperText={
                        onInstanceEdit.touched.accessToken &&
                        onInstanceEdit.errors.accessToken
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.tabsFooter}>
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      // disabled={moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >  moment(props.instanceId?.validity_end_date).tz(moment.tz.guess()).format("YYYY-MM-DD")? true: false}
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          ) : (
            <form onSubmit={onInstanceAdd.handleSubmit}>
              <Grid container spacing={2} className={classes.p25}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <InputLabel shrink htmlFor="phoneNumberId">
                    Phone Number Id:
                  </InputLabel>
                  <FormControl className={globalClasses.margin}>
                    <TextField
                      classes={{ root: globalClasses.customTextField }}
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      placeholder="Enter Phone Number Id"
                      type="text"
                      name="phoneNumberId"
                      value={onInstanceAdd.values.phoneNumberId}
                      onChange={onInstanceAdd.handleChange}
                      error={
                        onInstanceAdd.touched.phoneNumberId &&
                        Boolean(onInstanceAdd.errors.phoneNumberId)
                      }
                      helperText={
                        onInstanceAdd.touched.phoneNumberId &&
                        onInstanceAdd.errors.phoneNumberId
                      }
                    />

                    <Typography variant="inherit" color="error"></Typography>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <InputLabel shrink htmlFor="waBusinessId">
                    WhatsApp Business Account ID ( Waba ):
                  </InputLabel>
                  <FormControl className={globalClasses.margin}>
                    <TextField
                      classes={{ root: globalClasses.customTextField }}
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      placeholder="Enter whatsApp business account ID"
                      type="text"
                      name="waBusinessId"
                      value={onInstanceAdd.values.waBusinessId}
                      onChange={onInstanceAdd.handleChange}
                      error={
                        onInstanceAdd.touched.waBusinessId &&
                        Boolean(onInstanceAdd.errors.waBusinessId)
                      }
                      helperText={
                        onInstanceAdd.touched.waBusinessId &&
                        onInstanceAdd.errors.waBusinessId
                      }
                    />

                    <Typography variant="inherit" color="error"></Typography>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <InputLabel shrink htmlFor="accessToken">
                    Access token :
                  </InputLabel>
                  <FormControl className={globalClasses.margin}>
                    <TextField
                      classes={{ root: globalClasses.customTextField }}
                      InputProps={{ disableUnderline: true }}
                      size="small"
                      placeholder="Enter access token"
                      type="text"
                      name="accessToken"
                      value={onInstanceAdd.values.accessToken}
                      onChange={onInstanceAdd.handleChange}
                      error={
                        onInstanceAdd.touched.accessToken &&
                        Boolean(onInstanceAdd.errors.accessToken)
                      }
                      helperText={
                        onInstanceAdd.touched.accessToken &&
                        onInstanceAdd.errors.accessToken
                      }
                    />

                    <Typography variant="inherit" color="error"></Typography>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.tabsFooter}>
                    <Button
                      //startIcon={<SaveIcon/>}

                      variant="contained"
                      size="small"
                      type="submit"
                      disabled={onInstanceAdd.isSubmitting}
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
        <Backdrop className={globalClasses.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </>
  );
}

export default Authentication;
