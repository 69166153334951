import React from "react";
import { HashRouter, Route, Switch, Redirect  } from "react-router-dom"; 
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login/Login"; 
import Forget from "../pages/login/Forget"; 

import Author275 from "../pages/login/Author275"; 
import Register from "../pages/login/Register"; 


import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

 // context
import { useUserState } from "../context/UserContext";
import red from '@material-ui/core/colors/red';
import Verify from "../pages/login/Verify";
import Links from "../pages/others/Links";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
 var user = null;

 if(localStorage.getItem("user")){
  user = JSON.parse(localStorage.getItem("user"));   
 }

  const getMuiTheme = () => createTheme({

    overrides: {
      root: {
        "& .MuiTextField-root ": {
          borderColor: "blue",
          "& fieldset": {
            borderColor: "blue"
          },
          
        }
      },
      MuiFormControl: {
        root: {
          width: "100%",
        },
      },
      MuiAvatar:{
        root:{
          fontFamily: "'Satoshi'"
        }
      },
      MuiMenuItem:{
        root:{
          fontFamily: "'Satoshi'"
        }
      },
      MuiTable:{
        root:{
          "& caption":{ fontFamily: "'Satoshi' !important",
          }
        }
      },
      MuiOutlinedInput:{
        root:{
          border: "1px solid #2f3c4a36",
          borderRadius: "26px !important",
          boxShadow: "0px 2px 13px 5px #2f3c4a14 !important",
          "&.Mui-focused": { 
            borderColor: "#0b80ff !important",
            borderWidth: 1
        },
        },
      },
      MuiInput:{
      root:{
        border: "2px solid #2b374412",
        borderRadius: "26px !important",
        boxShadow: "0px 10px 20px 0px #2b374412 !important",
        "&:hover, &:focus": {
          borderColor: "#0b80ff !important",
        },
      },
      
      underline:{
        "&:before":{
          borderBottom: "none !important"
        },
        "&:after":{
          borderBottom: "none !important"
        },
      }
      },
      MuiInputBase: {
      
        input: {
          width: "100%",
         // border: "none",
          fontSize: "13px",
          display: "block",
           
          borderRadius: "4px",
        },
      }, 
      MuiPaper: {
       
        elevation4: {
          boxShadow: "none", 
        },
        elevation1: {
          boxShadow: "none", 
        },
      },

      MuiTableCell: {
        head: {
          color: "#121224",
          backgroundColor: "#cbe8ff !important", //new change f5f5f5
          fontSize: "16px !important",
          fontWeight: "bold",
          letterSpacing: "0.02em"
        },
        body: {
          color: "#121224",
          fontFamily: "'Satoshi' !important",
          fontSize: "14.5px !important",
          "&:last-child": { whiteSpace: "nowrap",
          },
          root: {
            padding: "14px",
            fontFamily: "'Satoshi' !important",
           
          },
        },
      },
      MUIDataTable:{
        responsiveBase:{
          boxShadow: "0px 20px 20px 5px #2f3c4a14 !important"
        }
      },
      MuiAppBar:{
        colorPrimary:{
         // backgroundColor: "#064be2 !important", 
          backgroundColor: "#2196f3 !important", 
          
        }
      },
      MuiTab:{
        textColorPrimary:{
          "&$selected": {       
            color: "#006494", 
          },
        },
       
      },
      MuiTreeItem:{
        iconContainer:{
          width: "5px",
        }
      },
      MuiListItemAvatar:{
        root:{
         minWidth: "50px",
         
        }
      },
      MuiDialogActions:{
        root:{
          justifyContent: "center !important"
        }
      },

      MuiSvgIcon:{
      root:{
        width: "1em !important",
       height: "1em !important"
      }
      },
     
      MUIDataTableToolbar: {
        actions: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        },
        icon: {
         // color: "#064be2",
          color: "#2196f3",
          
          "& svg": {
            color: "white",
            width: "25px",
            cursor: "pointer",
            height: "25px",
            padding: "5px",
            boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)",
            borderRadius: "100%",
            //backgroundColor: "#064be2",
            backgroundColor: "#2196f3",
          },
        },

        iconActive: {
         // color: "#064be2",
          color: "#2196f3",
          "& svg": {
            color: "white",
            width: "25px",
            cursor: "pointer",
            height: "25px",
            padding: "5px",
            boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)",
            borderRadius: "100%",
           // backgroundColor: "#064be2",
            backgroundColor: "#2196f3",
          },
        },
      },
     
      MuiTypography: {
        subtitle1: {
          fontWeight: "500",
          fontFamily: "'Satoshi' !important",
        },
        body1: {
          color: "#121224",
          fontFamily: "'Satoshi' !important",
          fontSize: "14px",
        },
        body2: {
          color: "#121224",
          fontFamily: "'Satoshi' !important",
          fontSize: "13px",
        }, 
        text: {
          fontFamily: "'Satoshi' !important",
        },
        h1: {
         
          fontFamily: "'Satoshi' !important",
        },
        h2: {
        
          fontFamily: "'Satoshi' !important",
        },
        h3: { 
         
          fontFamily: "'Satoshi' !important",
        },
        h4: {
           
          fontFamily: "'Satoshi' !important",
        },
        h5: {
          
          fontFamily: "'Satoshi' !important",
        }, 
        h6: {
          fontSize: "18px",
          fontFamily: "'Satoshi' !important",
        }, 
      },
      MuiAutocomplete: {
        input: {
          width: "100% !important",
        },
      },  
      MuiFilledInput: {
          root: {
            width: "100%",
            display: "block",
            padding: "0px 25px 0px 0px !important",
            position: "relative",
            fontSize: "13px",
            marginTop: "24px",
           
            backgroundColor: "white",
            "&:hover": { backgroundColor: "unset !important",
            },
            "&.Mui-focused": { backgroundColor: "unset !important",
            },
          },

           
        },
      
      MuiButton: { 
        startIcon:{
          marginRight: "4px !important"
        },
        root:{ 
            textTransform: "initial !important",
            fontFamily: "'Satoshi' !important",
            fontSize: "14px",
            fontWeight: 500,
            boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3) !important",
            "@media (max-width:959.95px)": {
              fontSize: "10px !important",
            }, 
        },
        label: {
          fontFamily: "'Satoshi' !important",
          fontSize: "14px",
          "@media (max-width:959.95px)": {
            fontSize: "10px !important",
          },
        },
        containedPrimary:{ 
        //  backgroundColor: "#064be2 !important",   
          backgroundColor: "#2196f3 !important", 
          textTransform:  "initial !important", 
          '&:active':{
           // backgroundColor: "#064be2 !important",
            backgroundColor: "#2196f3 !important", 
            color:"#fff !important",
          },
          '&:hover':{
            //backgroundColor: "#064be2 !important",
            backgroundColor: "#2196f3 !important", 
            color:"#fff !important",
          },
          '&:disabled':{
           // backgroundColor: "#064be2c7 !important",
            backgroundColor: "#2196f3 !important", 
            color:"#e4e4e4 !important",
          }
      },
      contained:{ 
       
        '&:disabled':{
         
           color:"#e4e4e4 !important",
        }
    },
      containedSizeSmall:{
        textTransform:  "initial !important", 
        padding:  "6px 38px !important",
        fontWeight: "300 !important",
        borderRadius: "15px"
      },
      containedSecondary:{ 
        backgroundColor: red[500] +"!important",
        '&:active':{
          backgroundColor: red[500] +"!important",
          color:"#fff !important",
        },
        '&:hover':{
          backgroundColor: red[500] +"!important",
          color:"#fff !important",
        },
        '&:disabled':{ 
          color:"#e4e4e4 !important",
       }
      },
      },
      MuiFormLabel: {
        root: {
         fontFamily: "'Satoshi' !important",
          fontSize: "17px",
          "@media (max-width:959.95px)": { fontSize: "15px !important",
          },
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
      MuiTooltip:{
        tooltip:{
          fontFamily: "'Satoshi' !important",
        },
        // popper:{
        //   top: "-34px !important",
        //   left: '-45px !important'
        // }
      },
      MuiOutlined: {
        MuiChip: {
          avatar: { margin: "0px",
          },
        },
      },
    },
  
  });


  const getMuiNewTheme = () => createTheme({
    overrides: {
      root: {
        "& .MuiTextField-root ": {
          borderColor: "blue",
          "& fieldset": {
            borderColor: "blue"
          },
          
        }
      },
      MuiFormControl: {
        root: {
          width: "100%",
        },
      },
      MuiAvatar:{
        root:{
          fontFamily: "'Satoshi'"
        }
      },
      MuiMenuItem:{
        root:{
          fontFamily: "'Satoshi'"
        }
      },
       
      MuiOutlinedInput:{
        root:{
          border: "1px solid #2f3c4a36",
         // borderRadius: "26px !important",
         // boxShadow: "0px 2px 13px 5px #2f3c4a14 !important",
          "&.Mui-focused": { 
            borderColor: "#0b80ff !important",
            borderWidth: 1
        },
        },
      },
      MuiInput:{
      root:{
        border: "2px solid #2b374412",
       // borderRadius: "26px !important",
       // boxShadow: "0px 10px 20px 0px #2b374412 !important",
        "&:hover, &:focus": {
          borderColor: "#0b80ff !important",
        },
      },
      
      underline:{
        "&:before":{
          borderBottom: "none !important"
        },
        "&:after":{
          borderBottom: "none !important"
        },
      }
      },
      MuiInputBase: {
      
        input: {
          width: "100%",
         // border: "none",
          fontSize: "13px",
          display: "block",
           
          borderRadius: "4px",
        },
      }, 
      MuiPaper: {
        elevation4: {
          boxShadow: "none", 
        },
        elevation1: {
          boxShadow: "none", 
        },
      },

    
      MuiAppBar:{
        colorPrimary:{
         // backgroundColor: "#064be2 !important", 
          backgroundColor: "#2196f3 !important", 
          
        }
      },
        
      // MuiDialogActions:{
      //   root:{
      //     justifyContent: "center !important"
      //   }
      // },

      MuiSvgIcon:{
      root:{
        width: "1em !important",
       height: "1em !important"
      }
      },
     
      
      MuiTypography: {
        subtitle1: {
          fontWeight: "500",
          fontFamily: "'Satoshi' !important",
        },
        body1: {
          color: "#121224",
          fontFamily: "'Satoshi' !important",
          fontSize: "14px",
        },
        body2: {
          color: "#121224",
          fontFamily: "'Satoshi' !important",
          fontSize: "13px",
        }, 
        text: {
          fontFamily: "'Satoshi' !important",
        },
        h1: {
         
          fontFamily: "'Satoshi' !important",
        },
        h2: {
        
          fontFamily: "'Satoshi' !important",
        },
        h3: { 
         
          fontFamily: "'Satoshi' !important",
        },
        h4: {
           
          fontFamily: "'Satoshi' !important",
        },
        h5: {
          
          fontFamily: "'Satoshi' !important",
        }, 
        h6: {
          fontSize: "18px",
          fontFamily: "'Satoshi' !important",
        }, 
      },
      MuiAutocomplete: {
        input: {
          width: "100% !important",
        },
      },  
      MuiFilledInput: {
          root: {
            width: "100%",
            display: "block",
            padding: "0px 25px 0px 0px !important",
            position: "relative",
            fontSize: "13px",
            marginTop: "24px",
           
            backgroundColor: "white",
            "&:hover": { backgroundColor: "unset !important",
            },
            "&.Mui-focused": { backgroundColor: "unset !important",
            },
          },

           
        },
      
      MuiButton: { 
        startIcon:{
          marginRight: "4px !important"
        },
        root:{ 
            textTransform: "initial !important",
            fontFamily: "'Satoshi' !important",
            fontSize: "14px",
            fontWeight: 500,
            boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3) !important",
            "@media (max-width:959.95px)": {
              fontSize: "12px !important",
            }, 
        },
        label: {
          fontFamily: "'Satoshi' !important",
          fontSize: "14px",
          "@media (max-width:959.95px)": {
            fontSize: "12px !important",
          },
        },
        containedPrimary:{ 
        //  backgroundColor: "#064be2 !important",   
          backgroundColor: "#2196f3 !important", 
          textTransform:  "initial !important", 
          '&:active':{
           // backgroundColor: "#064be2 !important",
            backgroundColor: "#2196f3 !important", 
            color:"#fff !important",
          },
          '&:hover':{
            //backgroundColor: "#064be2 !important",
            backgroundColor: "#2196f3 !important", 
            color:"#fff !important",
          },
          '&:disabled':{
           // backgroundColor: "#064be2c7 !important",
            backgroundColor: "#2196f3 !important", 
            color:"#e4e4e4 !important",
          }
      },
      contained:{ 
       
        '&:disabled':{
         
           color:"#e4e4e4 !important",
        }
    },
      containedSizeSmall:{
        textTransform:  "initial !important", 
        padding:  "6px 38px !important",
        fontWeight: "300 !important",
        borderRadius: "15px"
      },
      containedSecondary:{ 
        backgroundColor: red[500] +"!important",
        '&:active':{
          backgroundColor: red[500] +"!important",
          color:"#fff !important",
        },
        '&:hover':{
          backgroundColor: red[500] +"!important",
          color:"#fff !important",
        },
        '&:disabled':{ 
          color:"#e4e4e4 !important",
       }
      },
      },
      MuiFormLabel: {
        root: {
         fontFamily: "'Satoshi' !important",
          fontSize: "17px",
          "@media (max-width:959.95px)": { fontSize: "15px !important",
          },
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
      MuiTooltip:{
        tooltip:{
          fontFamily: "'Satoshi' !important",
        },
        // popper:{
        //   top: "-34px !important",
        //   left: '-45px !important'
        // }
      },
      MuiOutlined: {
        MuiChip: {
          avatar: { margin: "0px",
          },
        },
      },
    },
    
    });
  return (
    // baseName="/front"
    <HashRouter >
      <Switch>
      {/* <Route exact path="/app/dashboard" render={() =>  user !== null? user?.role_code=== "SUPERADMIN"? <Redirect to="/app/users" /> : <Redirect to="/app/dashboard" /> : <Redirect to="/login" /> }  />   */}
        <Route exact path="/" render={() => user !== null? user?.role_code=== "SUPERADMIN"? <Redirect to="/app/users" /> :   <Redirect to="/app/instance/authentication" /> : <Redirect to="/login" />}  />
         <Route  exact  path="/app" render={() =>  user !== null? user?.role_code=== "SUPERADMIN"? <Redirect to="/app/users" /> : <Redirect to="/app/instance/authentication" /> :  <Redirect to="/login" /> }  />

        <PrivateRoute path="/app" component={Layout} />
      
        <MuiThemeProvider theme={getMuiTheme()}>  
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/056c79490b1f472ce24d940f249ceffd" component={Author275} />  
        <PublicRoute path="/register" component={Register} />    
        <PublicRoute path="/forget/:id" component={Forget} />
        <PublicRoute path="/verify/:token" component={Verify} />
        
        <MuiThemeProvider theme={getMuiNewTheme()}>  
        <PublicRoute path="/link" component={Links} />
        </MuiThemeProvider> 

        </MuiThemeProvider> 
        <Route component={Error} />
      </Switch>
    </HashRouter >
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          React.createElement(component, props)
        }
      />
    );
  }
}
