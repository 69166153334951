import React, { useEffect, useRef, useState, useReducer, useContext } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import {
  Button,
  InputBase,
  Paper,
  TextField,
  Box,
  Link,
  InputLabel,
  Backdrop,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Tooltip,
  Dialog,
  SwipeableDrawer,
  Chip,
  ClickAwayListener,
  Fab,
  useMediaQuery,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import fullScreenKey from "../../images/fullScreenKey.png"
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import RefreshIcon from "@material-ui/icons/Refresh";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import BrushIcon from "@material-ui/icons/Brush";
import Avatar from "@material-ui/core/Avatar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import SendIcon from "@material-ui/icons/Send";
import Popover from "@material-ui/core/Popover";
import { IoMdCheckmark } from "react-icons/io";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import GetAppIcon from "@material-ui/icons/GetApp";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import PersonIcon from "@material-ui/icons/Person";
import RoomIcon from "@material-ui/icons/Room";
import Highlighter from "react-highlight-words";
import red from "@material-ui/core/colors/red";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import ImageIcon from "@material-ui/icons/Image";
import VideocamIcon from "@material-ui/icons/Videocam";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HeadsetIcon from "@material-ui/icons/Headset";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {
  FcGallery,
  FcAudioFile,
  FcVideoFile,
  FcDocument,
} from "react-icons/fc";
import { FaUserTag, FaUserPlus } from "react-icons/fa";
// import CardContent from "@material-ui/core/CardContent";
// import CardActions from "@material-ui/core/CardActions";
// import io from "socket.io-client";
import whatsappSponcerImg from "../../images/whatsapp_image.png"
import PhoneInput from "react-phone-input-2";
import parseMobile from "libphonenumber-js/mobile";
import parsePhoneNumber from "libphonenumber-js";

import useStyles from "./styles";
import globalStyles from "../../themes/style.js";
import templateStyles from "../admin/style";

import { contactSchema, contactWithoutSchema } from "../../components/Yup/Yup";
import {
  viewApprovedTemplates,
  viewTemplate,
} from "../../services/template.api";

import {
  getAllMessages,
  markMessageRead,
  viewAllMessageContacts,
  deleteMessages,
  deleteConversation,
  viewAllMessageContactCaptions,
} from "../../services/chat.api";

import { addTag, viewAllTags } from "../../services/tags.api";

import {
  addContact,
  setContactTag,
  viewAllContactTags,
  removeContactTag,
} from "../../services/contact.api";

import {
  sendTemplateSchema,
  sendTemplatewithioutSchema,
} from "../../components/Yup/Yup";

import {
  sendTemplateMessage,
  sendTextMessage,
  sendAudioMessage,
  sendDocumentMessage,
  sendImageMessage,
  sendVideoMessage,
} from "../../services/message.api";
import { isInitiated, uploadFile } from "../../services/instance.api";
import { tagSchema } from "../../components/Yup/Yup";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFormik } from "formik";
import CustomAvatar from "../../components/UserAvatar/CustomAvatar.js";
import { ArrowUpwardRounded } from "@material-ui/icons";
import InstanceContext from "../../context/InstanceContext.js";
import { useHistory, useLocation } from "react-router-dom";
import WACaptions from "../../components/wpText/WACaptions.js";
function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiListItem: {
        root: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        },
        gutters: {
          paddinLeft: "5px !important",
          paddingRight: "5px",
        },
      },
      MuiBox: {
        root: {
          padding: "0px !important",
        },
      },
      MuiTab: {
        root: {
          maxWidth: "100% !important",
        },
      },
      MuiInputBase: {
        input: {
          padding: "10px !important",
          fontSize: "13px",
        },
        root: {
          width: "100%",
        },
      },
      MuiFormControl: {
        root: {
          width: "100% !important",
        },
      },
      MuiTabScrollButton: {
        vertical: {
          backgroundColor: "transparent",
        },
      },
      MuiTypography: {
        subtitle1: {
          fontWeight: "500",
          fontFamily: '"Satoshi", serif !important',
        },
        body1: {
          color: "#121224",
          fontFamily: '"Satoshi", serif !important',
          fontSize: "14px",
        },
        body2: {
          color: "#121224",
          fontFamily: '"Satoshi", serif !important',
          fontSize: "13px",
        },
      },
      MuiDialogActions: {
        root: {
          justifyContent: "center !important",
        },
      },
      MuiPaper: {
        rounded: {
          // borderRadius: "30px"
        },
      },
      MuiDrawer: {
        // paperAnchorBottom: {
        //   width: "50%",
        //   left: "30%"
        // }
      },
      MuiChip: {
        root: {
          height: "26px !important",
        },
        deleteIcon: {
          height: "16px !important",
        },
        label: {
          fontSize: "12px !important",
        },
      },
      MuiOutlinedInput: {
        root: {
          border: "none !important",
          borderColor: "unset !important",
          height: "40px !important",
          "& input": {
            width: "100% !important",
            height: "10px !important",
          },
        },
      },
      MuiButton: {
        root: {
          fontFamily: '"Lora", serif!important',
          fontSize: "14px",
          fontWeight: 500,
          boxShadow: "10px 20px 20px 5px #2f3c4a14 !important",
          "@media (max-width:959.95px)": {
            fontSize: "12px !important",
          },
        },
        label: {
          fontFamily: '"Satoshi", serif !important',
          fontSize: "14px",
          "@media (max-width:959.95px)": {
            fontSize: "12px !important",
          },
        },
        containedPrimary: {
          //  backgroundColor: "#064be2 !important",
          backgroundColor: "#2196f3 !important",
          textTransform: "initial !important",
          "&:active": {
            //  backgroundColor: "#064be2 !important",
            backgroundColor: "#2196f3 !important",
            color: "#fff !important",
          },
          "&:hover": {
            //backgroundColor: "#064be2 !important",
            backgroundColor: "#2196f3 !important",
            color: "#fff !important",
          },
          "&:disabled": {
            // backgroundColor: "#064be2c7 !important",
            backgroundColor: "#2196f3 !important",
            color: "#fff !important",
          },
        },
        containedSizeSmall: {
          textTransform: "initial !important",
          padding: "4px 10px !important",
          fontWeight: "300 !important",
        },
        containedSecondary: {
          backgroundColor: red[500] + "!important",
          "&:active": {
            backgroundColor: red[500] + "!important",
            color: "#fff !important",
          },
          "&:hover": {
            backgroundColor: red[500] + "!important",
            color: "#fff !important",
          },
        },
      },
    },
  });

const Home = () => {
  var moment = require("moment-timezone");

  const classes = useStyles();
  const globalClasses = globalStyles();
  const templateClasses = templateStyles();
  const location = useLocation()
  const history = useHistory()
  const mobileViewOnly = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const { instanceId } = useContext(InstanceContext)

  const [loader, setLoader] = useState(false);
  const [isFullScreenWhatsapp, setIsFullScreenWhatsapp] = useState(false);
  const [imageLoad, setImageLoad] = useState(null);
  const [templateData, setTemplateData] = React.useState([]);
  const [templateHeader, setTemplateHeader] = React.useState();
  const [templateButton, setTemplateButton] = React.useState([]);

  var [profileMenu, setProfileMenu] = useState(null);

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  const [tagData, setTagData] = React.useState([]);
  const [tagValue, setTagValue] = React.useState(null);

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState({
    profile: false,
    search: false,
    tag: false,
    contact: false,
  });

  const [view, setView] = useState("File");
  const [tabOpen, setTabOpen] = useState(false);
  const [text, setText] = useState("");
  const [messageText, setMessageText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const messageRef = useRef(null);
  const fileRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const searchRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const hiddenImageInput = useRef(null);
  const hiddenVideoInput = useRef(null);
  const hiddenAudioInput = useRef(null);

  const [mobile, setMobile] = useState("");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFullScreen = () => {
    history.push(`/app/infoMessages`);
  }

  const fullScreenModeRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'f') {
        handleFullScreen();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const [isValid, setIsValid] = useState(true);

  const [removeOpen, setRemoveOpen] = React.useState(false);
  const [removeBulkOpen, setRemoveBulkOpen] = React.useState(false);

  const handleRemoveBulkOpen = () => {
    setRemoveBulkOpen(true);
  };

  const handleRemoveBulkClose = () => {
    setRemoveBulkOpen(false);
  };

  const handleRemoveOpen = () => {
    handlePopoverDropClose();
    setRemoveOpen(true);
  };

  const handleRemoveClose = () => {
    setRemoveOpen(false);
  };

  const handleImageChange = (e) => {
    setimageValue(e.target.files[0]);
    setAudioValue(null);
    setVideoValue(null);
    setFileValue(null);
    setAnchorEl(null);
    if (
      e.target.files[0]?.size < 5000000 &&
      (e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png")
    ) {
      setState({ ...state, bottom: true });
    } else {
      handleNotificationCall(
        "error",
        "Please check your uploaded file format and size",
      );
    }
  };

  const handleFileChange = (e) => {
    setimageValue(null);
    setAudioValue(null);
    setVideoValue(null);
    setFileValue(e.target.files[0]);
    setAnchorEl(null);
    if (
      e.target.files[0]?.size < 100000000 &&
      (e.target.files[0].type === "text/plain" ||
        e.target.files[0].type === "application/pdf" ||
        e.target.files[0].type === "application/vnd.ms-powerpoint" ||
        e.target.files[0].type === "application/msword" ||
        e.target.files[0].type === "application/vnd.ms-excel" ||
        e.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        e.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        e.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setState({ ...state, bottom: true });
    } else {
      handleNotificationCall(
        "error",
        "Please check your uploaded file format and size",
      );
    }
  };

  const handleAudioChange = (e) => {
    setimageValue(null);
    setAudioValue(e.target.files[0]);
    setVideoValue(null);
    setFileValue(null);
    setAnchorEl(null);
    if (
      e.target.files[0]?.size < 16000000 &&
      (e.target.files[0].type === "audio/aac" ||
        e.target.files[0].type === "audio/mp4" ||
        e.target.files[0].type === "audio/mpeg" ||
        e.target.files[0].type === "audio/amr" ||
        e.target.files[0].type === "audio/ogg")
    ) {
      setState({ ...state, bottom: true });
    } else {
      handleNotificationCall(
        "error",
        "Please check your uploaded file format and size",
      );
    }
  };

  const handleVideoChange = (e) => {
    setimageValue(null);
    setAudioValue(null);
    setVideoValue(e.target.files[0]);
    setFileValue(null);
    setAnchorEl(null);
    if (
      e.target.files[0]?.size < 16000000 &&
      (e.target.files[0].type === "video/mp4" ||
        e.target.files[0].type === "video/3gp")
    ) {
      setState({ ...state, bottom: true });
    } else {
      handleNotificationCall(
        "error",
        "Please check your uploaded file format and size",
      );
    }
  };

  const [imageUrl, setImageUrl] = useState(undefined);
  const [imageOpen, setImageOpen] = useState(false);
  const [fileValue, setFileValue] = useState(null);
  const [imageValue, setimageValue] = useState(null);

  const [audioValue, setAudioValue] = useState(null);
  const [videoValue, setVideoValue] = useState(null);

  const [templateValue, setTemplateValue] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorDropEl, setAnchorDropEl] = useState(null);

  const handlePopoverDropOpen = (event) => {
    setAnchorDropEl(event.currentTarget);
  };

  const handlePopoverDropClose = () => {
    setAnchorDropEl(null);
  };

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const opensDrop = Boolean(anchorDropEl);
  const idsDrop = opensDrop ? "simple-popover" : undefined;

  const opens = Boolean(anchorEl);
  const ids = opens ? "simple-popover" : undefined;

  const handleImageClose = () => {
    setImageOpen(false);
    setImageUrl(undefined);
  };

  const handleImageOpen = (url) => {
    setImageOpen(true);
    setImageUrl(url);
  };

  const [state, setState] = useState({
    top: false,
    bottom: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const handleImageClick = () => {
    hiddenImageInput.current.click();
  };

  const handleDocumentClick = () => {
    hiddenFileInput.current.click();
  };

  const handleAudioClick = () => {
    hiddenAudioInput.current.click();
  };

  const handleVideoClick = () => {
    hiddenVideoInput.current.click();
  };

  const [profileList, setProfileList] = useState([]);

  const [foundUsers, setFoundUsers] = useState([]);
  const [messageUsers, setMessageUsers] = useState([]);

  const [conversations, setConversations] = useState([]);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");

  const [valid, setValid] = useState(false);

  const token = localStorage.getItem("webWAInfoapto_PX11925");
  const user = JSON.parse(localStorage.getItem("user"));

  // const [InstanceId, setInstanceId] = useState(false);

  const [messageData, setMessageData] = React.useState({
    headerField: false,
    bodyField: false,
    message: "",
  });

  const [selectedItems, setSelectedItems] = useState([]);
  const [scrollPage, setScrollPage] = useState(1);
  const [scrollContact, setScrollContact] = useState(1);
  const [loading, setLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [newContactData, setNewContactData] = useState([]);
  const [newChatData, setNewChatData] = useState([]);

  function getMoreMessage() {
    setLoading(true);
    setScrollPage(scrollPage + 1);

    getAllMessages({
      phone_number: mobile,
      role_code: user?.role_code,
      page: scrollPage + 1,
      token: token,
    }).then((response) => {
      if (response.data?.status === true) {
        setConversations((prevData) => [...response.data?.data, ...prevData]);
        setNewChatData(response.data?.data);
      } else {
        // setConversationData([]);
      }
      setLoading(false);
      //  if (response.data?.data?.length === 0) {
      //   setHasMore(false);
      // }
    });
  }

  const [loadMoreBtn, setLoadMoreBtn] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollThreshold = 10;
      const scrollTop = scrollContainerRef.current.scrollTop;
      if (scrollTop > scrollThreshold) {
        setLoadMoreBtn(true);
      }
    }

    scrollContainerRef?.current?.addEventListener("scroll", handleScroll);

    return () =>
      scrollContainerRef?.current?.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollContainerRef?.current?.scrollTop]);

  const handleDeleteMessage = () => {
    handlePopoverDropClose();
    setLoader(true);
    deleteMessages({
      messages: selectedItems,
      role_code: user?.role_code,
      token: token,
    })
      .then((response) => {
        if (response.data.status === true) {
          getmessage(mobile);

          handleNotificationCall("success", response.data.message);
        } else {
          handleNotificationCall("error", response.data.message);
        }
        handleRemoveClose();

        selectedItems([]);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSendTemplate = useFormik({
    initialValues: {
      template: { name: "" },
      template_name: "",
      parameters: "",
      mobile_number: mobile,
      language_code: "",
      header: "",
      headerType: "",
    },
    validationSchema:
      templateHeader === "IMAGE" ||
        templateHeader === "VIDEO" ||
        templateHeader === "DOCUMENT"
        ? sendTemplateSchema
        : sendTemplatewithioutSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      sendTemplateMessage(values, {
        template_id: templateValue?.id,
        instance_id: instanceId?.id,
        message: messageRef.current?.innerText,
        token: token,
      })
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);
            getmessage(mobile);
            setState({ ...state, bottom: false });
            resetForm();
            setMessageData({});
            setTemplateButton([]);

            setTemplateValue(null);
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  function selectTemplate(value) {
    setLoader(true);
    setImageLoad(null);
    if (value?.id) {
      viewTemplate({ id: value.id, instance_id: instanceId?.id, token: token })
        .then((response) => {
          if (response.data.status === true) {
            const regex = /\{\{.*?\}\}/g;
            setTemplateValue(value);
            var HEADER = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "HEADER",
            )[0]?.format;
            var BODY = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "BODY",
            )[0]?.text;
            var BUTTONS = JSON.parse(response.data.data.components).filter(type => type?.type === "BUTTONS")[0]?.buttons;

            setTemplateButton(BUTTONS)
            //  var FOOTER = JSON.parse(response.data.data.components).filter(type => type?.type === "FOOTER")[0]?.text;
            //   var BUTTONS = JSON.parse(response.data.data.components).filter(type => type?.type === "BUTTONS")[0]?.buttons;

            setTemplateHeader(HEADER);
            onSendTemplate.setValues({
              template: templateData.indexOf(value),
              mobile_number: onSendTemplate.values.mobile_number,
              template_name: response.data.data.name,
              language_code: response.data.data.language,
              header:
                response.data.data.fileUrl === null
                  ? ""
                  : response.data.data.fileUrl,
              headerType: HEADER !== undefined ? HEADER : "",
              parameters:
                BODY !== undefined ? (BODY.match(regex) || []).join(", ") : "",
              //  "footer": FOOTER !==undefined? FOOTER: "",
              //  "button": BUTTONS !==undefined? (BUTTONS.map((list) =>  list?.text+", " ))   : "",
            });

            var loop = (
              JSON.parse(response.data.data.components)
                .filter((type) => type?.type === "BODY")[0]
                ?.text.match(regex) || []
            )
              .join(",")
              .split(",");

            var message = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "BODY",
            )[0]?.text;

            for (let i = 0; i < loop.length; i++) {
              const match = `{{${i + 1}}}`;
              message = message.replace(
                match,
                `<b id="${i + 1}">{{${i + 1}}}</b>`,
              );

              setMessageData({
                ...messageData,
                headerField:
                  JSON.parse(response.data.data.components).filter(
                    (type) => type?.type === "HEADER",
                  )[0]?.format !== undefined
                    ? false
                    : true,
                bodyField:
                  JSON.parse(response.data.data.components).filter(
                    (type) => type?.type === "BODY",
                  )[0]?.text !== undefined
                    ? false
                    : true,
                //"footerField": JSON.parse(response.data.data.components).filter(type => type?.type === "FOOTER")[0]?.text !==undefined ? false : true,
                message: message,
              });
            }
          }
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setLoader(false);
      setTemplateValue(null);
    }
  }

  function handleSendTemplateImage(event, name) {
    var FormData = require("form-data");
    var data = new FormData();
    if (
      name === "image" &&
      event.target.files[0]?.size < 5000000 &&
      (event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png")
    ) {
      setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);

      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          setImageLoad(false);
          onSendTemplate.setValues({
            template: templateData.indexOf(onSendTemplate.values.template),
            mobile_number: onSendTemplate.values.mobile_number,
            template_name: onSendTemplate.values.template_name,
            language_code: onSendTemplate.values.language_code,
            header: response.data?.file_url,
            headerType: onSendTemplate.values.headerType,
            parameters: onSendTemplate.values.parameters,
            //"footer": onSendTemplate.values.footer,
            // "button": onSendTemplate.values.button,
          });
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);
        }

        setLoader(false);
      });
    } else if (
      name === "audio" &&
      event.target.files[0]?.size < 16000000 &&
      (event.target.files[0].type === "audio/aac" ||
        event.target.files[0].type === "audio/mp4" ||
        event.target.files[0].type === "audio/mpeg" ||
        event.target.files[0].type === "audio/amr" ||
        event.target.files[0].type === "audio/ogg")
    ) {
      setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          setImageLoad(false);
          onSendTemplate.setValues({
            template: templateData.indexOf(onSendTemplate.values.template),
            mobile_number: onSendTemplate.values.mobile_number,
            template_name: onSendTemplate.values.template_name,
            language_code: onSendTemplate.values.language_code,
            header: response.data?.file_url,
            headerType: onSendTemplate.values.headerType,
            parameters: onSendTemplate.values.parameters,
            // "footer": onSendTemplate.values.footer,
            //"button": onSendTemplate.values.button,
          });
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);
        }
        setLoader(false);
      });
    } else if (
      name === "video" &&
      event.target.files[0]?.size < 16000000 &&
      (event.target.files[0].type === "video/mp4" ||
        event.target.files[0].type === "video/3gp")
    ) {
      setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          setImageLoad(false);
          onSendTemplate.setValues({
            template: templateData.indexOf(onSendTemplate.values.template),
            mobile_number: onSendTemplate.values.mobile_number,
            template_name: onSendTemplate.values.template_name,
            language_code: onSendTemplate.values.language_code,
            header: response.data?.file_url,
            headerType: onSendTemplate.values.headerType,
            parameters: onSendTemplate.values.parameters,
            // "footer": onSendTemplate.values.footer,
            // "button": onSendTemplate.values.button,
          });
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);
        }

        setImageLoad(false);
      });
    } else if (
      name === "document" &&
      event.target.files[0]?.size < 100000000 &&
      (event.target.files[0].type === "text/plain" ||
        event.target.files[0].type === "application/pdf" ||
        event.target.files[0].type === "application/vnd.ms-powerpoint" ||
        event.target.files[0].type === "application/msword" ||
        event.target.files[0].type === "application/vnd.ms-excel" ||
        event.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        event.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        event.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          setLoader(false);
          onSendTemplate.setValues({
            template: templateData.indexOf(onSendTemplate.values.template),
            mobile_number: onSendTemplate.values.mobile_number,
            template_name: onSendTemplate.values.template_name,
            language_code: onSendTemplate.values.language_code,
            header: response.data?.file_url,
            headerType: onSendTemplate.values.headerType,
            parameters: onSendTemplate.values.parameters,
            // "footer": onSendTemplate.values.footer,
            // "button": onSendTemplate.values.button,
          });
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);
        }

        setImageLoad(false);
      });
    } else {
      handleNotificationCall(
        "error",
        "Please check your uploaded file format and size",
      );
    }
  }

  function messageTextArea(e) {
    var loop = e.target.value.split(",");

    var message = messageData.message;

    for (let i = 0; i < loop.length; i++) {
      const regex = new RegExp(`<b id="${i + 1}">.*?</b>`, "g");
      const newText = '<b id="' + [i + 1] + '">' + loop[i] + "</b>";
      message = message.replace(regex, newText);
      setMessageData({
        ...messageData,
        message: message.replace(regex, newText),
      });
    }
  }

  const onTagAdd = useFormik({
    initialValues: {
      tag_name: "",
    },
    validationSchema: tagSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      addTag({ tag_name: values.tag_name, token: token })
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);
            forceUpdate();
            resetForm();
            setOpen({
              ...open,
              tag: false,
            });
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const onContactAdd = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
    },
    validationSchema: phoneNumber === "" ? contactSchema : contactWithoutSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      addContact(values, {
        mobile: phoneNumber,
        countryCode: countryCode,
        token: token,
      })
        .then((response) => {
          if (response?.data?.status === true) {
            handleNotificationCall("success", response.data.message);
            forceUpdate();
            resetForm();
            setOpen({
              ...open,
              contact: false,
            });
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const handlePhoneChange = (values, allDetails) => {
    setCountryCode(allDetails.dialCode);
    setPhoneNumber(values);
  };

  useEffect(() => {
    fetchAllContact(scrollContact);
    checkFullScreenWp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkFullScreenWp = () => {
    if (location.pathname === "/app/infoMessages") {
      setIsFullScreenWhatsapp(true)
    }
  }

  const fetchAllContact = (scrollContact) => {
    setContactLoading(true);
    viewAllMessageContacts({
      role_code: user?.role_code,
      page: scrollContact,
      token: token,
    })
      .then(async (response) => {
        if (response.data.status === true) {
          const newContacts = response.data.data;

          for (const contact of newContacts) {
            await fetchAllContactCaptions(contact.phno);
          }

          setNewContactData(newContacts);
          if (newContacts.length > 0) {
            setProfileList([...foundUsers, ...newContacts]);
            if (searchValue === "") {
              setFoundUsers([...foundUsers, ...newContacts]);
            }
            setScrollContact(scrollContact + 1);
          }
        }
        setContactLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setContactLoading(false);
      });
  };

  const fetchAllContactCaptions = (mobileNumber) => {
    viewAllMessageContactCaptions({
      role_code: user?.role_code,
      mobile: mobileNumber,
      token: token,
    })
      .then((response) => {
        if (response.data.status === true) {
          const contactCaptionData = response.data.data;
          setFoundUsers((prevFoundUsers) =>
            prevFoundUsers.map((contact) =>
              contact.phno === mobileNumber
                ? { ...contact, ...contactCaptionData }
                : contact
            )
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleContactTabChange = (event, newValue) => {
    setScrollContact(newValue + 1);
  };

  function handleScrollContact(event) {
    const target = event.target;

    const isScrolledToEnd =
      Math.abs(target.scrollTop + target.clientHeight - target.scrollHeight) <
      1;

    if (isScrolledToEnd && newContactData.length > 0) {
      fetchAllContact(scrollContact);
    }
  }

  useEffect(() => {
    // setLoader(true);
    viewAllTags({ role_code: user?.role_code, token: token })
      .then((response) => {
        if (response.data.status === true) {
          //setLoader(false);
          setTagData(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reducerValue]);

  const handleDeleteConversation = () => {
    setLoader(true);
    handleRemoveBulkClose();
    deleteConversation({
      mobile: mobile,
      role_code: user?.role_code,
      token: token,
    })
      .then((response) => {
        if (response.data.status === true) {
          setTabOpen(false);

          forceUpdate();

          handleNotificationCall("success", response.data.message);
        } else {
          handleNotificationCall("error", response.data.message);
        }
        setSelectedItems([]);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  function handleTag(value, id) {
    setContactTag({ tag_id: value.id, message_contact_id: id, token: token })
      .then((response) => {
        if (response) {
          setTagValue(null);
          handleTagClose();
          getAllContactTags(mobile);

          if (response.data.status === false) {
            handleNotificationCall("error", response.data.message);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  function getAllContactTags(value) {
    setLoader(true);
    viewAllContactTags({
      phoneNumber: value,
      role_code: user?.role_code,
      token: token,
    })
      .then((response) => {
        if (response.data.status === true) {
          setChipData(response?.data?.data);
        } else {
          setChipData([]);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  // function getChatUsers() {
  //   setLoader(true);

  //   axios({
  //     method: 'post',
  //     url: `${process.env.REACT_APP_SERVER}chat/getChatUsers`,
  //     data: {},
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': token
  //     },

  //   })
  //     .then(function (response) {

  //       if (response.data.status === true) {
  //         setLoader(false);

  //         setProfileList(response.data.chatdata)
  //         setFoundUsers(response.data.chatdata);

  //       }

  //     })
  // }

  function scroll() {
    const timeout = setTimeout(() => {
      const element = document.getElementById(
        "section" + localStorage.getItem("mobile"),
      );

      element?.scrollIntoView({ behavior: "smooth" });
    }, 500);

    return () => clearTimeout(timeout);
  }

  const [intervalId, setIntervalId] = useState(null);
  const stopInterval = () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  function getmessage(mobile) {
    stopInterval();
    //setLoader(true);
    const id = setInterval(() => {
      const element = document.getElementById("section" + mobile);
      if (element) {
        getAllMessages({
          phone_number: mobile,
          role_code: user?.role_code,
          page: scrollPage,
          token: token,
        })
          .then((response) => {
            setMobile(mobile);
            localStorage.setItem("mobile", mobile);

            if (response.data.status === true) {
              //getmessageIni(mobile);
              setView("Message");
              setConversations(response.data.data);
              setNewChatData(response.data?.data);
              scroll();
            } else {
              setConversations([]);
              setScrollPage(1);
            }
            setLoader(false);
          })
          .catch(function (error) {
            console.log(error);
          });

        markMessageRead({ mobile_number: mobile, token: token })
          .then((response) => {
            if (response) {
              forceUpdate();
            }
          })
          .catch(function (error) {
            console.log(error);
          });

        if (valid !== true) {
          isInitiated({ mobile, token: token })
            .then((response) => {
              if (response) {
                setValid(response.data.result);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    }, 60000);

    getAllMessages({
      phone_number: mobile,
      role_code: user?.role_code,
      page: 1,
      token: token,
    })
      .then((response) => {
        setMobile(mobile);
        localStorage.setItem("mobile", mobile);

        if (response.data.status === true) {
          //getmessageIni(mobile);
          setView("Message");
          setConversations(response.data.data);
          setNewChatData(response.data?.data);
          scroll();
        } else {
          setConversations([]);
          setScrollPage(1);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });

    setIntervalId(id);
    setMessageText("");
    setMessageUsers([]);

    isInitiated({ mobile, token: token })
      .then((response) => {
        if (response) {
          setValid(response.data.result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    markMessageRead({ mobile_number: mobile, token: token })
      .then((response) => {
        if (response) {
          forceUpdate();
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    viewAllContactTags({
      phoneNumber: mobile,
      role_code: user?.role_code,
      token: token,
    })
      .then((response) => {
        if (response.data.status === true) {
          setChipData(response.data.data);
        } else {
          setChipData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function sendFile(mobile, Id) {
    var FormData = require("form-data");
    var data = new FormData();
    if (
      imageValue !== null &&
      imageValue?.size < 5000000 &&
      (imageValue.type === "image/jpeg" || imageValue.type === "image/png")
    ) {
      setLoader(true);

      data.append("file", imageValue);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          sendImageMessage({
            mobile_number: mobile,
            file_url: response.data?.file_url,
            file_path: response.data?.file_path,
            instance_id: Id?.id,
            token: token,
          }).then((result) => {
            if (result.data.status === true) {
              handleNotificationCall("success", result.data.message);
              setimageValue(null);

              scrollDelay(mobile);
              getmessage(mobile);
              setState({ ...state, bottom: false });
            } else {
              handleNotificationCall("error", result.data.message);
            }
            setLoader(false);
          });
        } else {
          setLoader(false);
          handleNotificationCall("error", response.data.message);
        }
      });
    } else if (
      audioValue !== null &&
      audioValue?.size < 16000000 &&
      (audioValue.type === "audio/aac" ||
        audioValue.type === "audio/mp4" ||
        audioValue.type === "audio/mpeg" ||
        audioValue.type === "audio/amr" ||
        audioValue.type === "audio/ogg")
    ) {
      setLoader(true);

      data.append("file", audioValue);
      data.append("token", token);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          sendAudioMessage({
            mobile_number: mobile,
            file_url: response.data?.file_url,
            file_path: response.data?.file_path,
            instance_id: Id?.id,
            token: token,
          })
            .then((result) => {
              if (result.data.status === true) {
                handleNotificationCall("success", result.data.message);

                setAudioValue(null);
                scrollDelay(mobile);
                getmessage(mobile);
                setState({ ...state, bottom: false });
              } else {
                handleNotificationCall("error", result.data.message);
              }

              setLoader(false);
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          handleNotificationCall("error", response.data.message);
        }
      });
    } else if (
      videoValue !== null &&
      videoValue?.size < 16000000 &&
      (videoValue.type === "video/mp4" || videoValue.type === "video/3gp")
    ) {
      setLoader(true);

      data.append("file", videoValue);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          sendVideoMessage({
            mobile_number: mobile,
            file_url: response.data?.file_url,
            file_path: response.data?.file_path,
            instance_id: Id?.id,
            token: token,
          })
            .then((result) => {
              if (result.data.status === true) {
                handleNotificationCall("success", result.data.message);
                setVideoValue(null);
                scrollDelay(mobile);
                getmessage(mobile);
                setState({ ...state, bottom: false });
              } else {
                handleNotificationCall("error", result.data.message);
              }

              setLoader(false);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    } else if (
      fileValue !== null &&
      fileValue?.size < 100000000 &&
      (fileValue.type === "text/plain" ||
        fileValue.type === "application/pdf" ||
        fileValue.type === "application/vnd.ms-powerpoint" ||
        fileValue.type === "application/msword" ||
        fileValue.type === "application/vnd.ms-excel" ||
        fileValue.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        fileValue.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        fileValue.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setLoader(true);

      data.append("file", fileValue);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          sendDocumentMessage({
            mobile_number: mobile,
            file_url: response.data?.file_url,
            file_path: response.data?.file_path,
            instance_id: Id?.id,
            token: token,
          })
            .then((result) => {
              if (result.data.status === true) {
                handleNotificationCall("success", result.data.message);
                setFileValue(null);
                scrollDelay(mobile);
                getmessage(mobile);
                setState({ ...state, bottom: false });
              } else {
                handleNotificationCall("error", result.data.message);
              }
              setLoader(false);
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          setLoader(false);
          handleNotificationCall("error", response.data.message);
        }
      });
    } else {
      handleNotificationCall(
        "error",
        "Please check your uploaded file format and size",
      );
    }
  }
  // function getmessageIni(mobile) {

  //   axios({
  //     method: 'post',
  //     url: `${process.env.REACT_APP_SERVER}chat/getIniChat`,
  //     data: {
  //       "chatUserId": mobile
  //     },
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': token
  //     },

  //   })
  //     .then(function (response) {

  //       if (response.data.status === true) {
  //         if (response.data.data === null) {
  //           setView("undefined");
  //         } else {
  //           setView("Message");
  //         }

  //       }

  //     })

  // }

  // function chatmessage(data) {
  //      getmessage(data.from);
  //    forceUpdate();
  // }

  // useEffect(() => {
  //    const socket = io('ws://ec2-100-26-197-18.compute-1.amazonaws.com:8080', { transports : ['websocket'] });

  //    socket.on('connect', () => {
  //     console.log('Connected to the socket server');
  //   });

  //   socket.on('chat message', (data) => {
  //     console.log('Received message from server:', data);
  //     chatmessage(data);
  //   });

  //    socket.emit('clientMessage', 'Hello, server!');

  //    return () => {
  //     socket.disconnect();
  //   };
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const filter = (e) => {
    const keyword = e.target.value;

    setSearchValue(e.target.value);

    if (keyword !== "") {
      const results = foundUsers.filter((row) => {
        return (
          (row?.name !== null &&
            row?.name.toLowerCase().includes(keyword.toLowerCase())) ||
          (row?.from_number !== null &&
            row?.from_number.includes(keyword.toLowerCase())) ||
          (row?.to_number !== null &&
            row?.to_number.includes(keyword.toLowerCase())) ||
          (row?.caption !== null &&
            row?.caption.toLowerCase().includes(keyword.toLowerCase())) ||
          (row?.phno !== null && row?.phno.includes(keyword.toLowerCase()))
        );
      });

      setFoundUsers(results);

      // setLoader(true);
      // axios({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_SERVER}chat/searchChat`,
      //   data: { "search": searchValue },
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': token
      //   },

      // })
      //   .then(function (response) {

      //     if (response.data.status === true) {

      //       setFoundUsers(response.data.data);

      //     } else {

      //       setConversations([]);
      //     }
      //     setLoader(false);

      //   })
    } else {
      setFoundUsers(profileList);
    }
  };

  const search = (e) => {
    const keyword = e.target.value;
    setMessageText(e.target.value);

    if (keyword !== "") {
      setLoader(true);

      const results = conversations.filter((msg) => {
        return msg.caption?.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
      });

      setLoader(false);

      setMessageUsers(results);
    } else {
      setMessageUsers([]);
    }
  };

  function scrollDelay(mobile) {
    const timeout = setTimeout(() => {
      const element = document.getElementById("section" + mobile);

      element.scrollIntoView({ behavior: "smooth" });
    }, 1000);

    return () => clearTimeout(timeout);
  }

  const handleClick = (instanceId) => {
    setMessageData({});
    setTemplateButton([]);
    viewApprovedTemplates({ instance_id: instanceId?.id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          setTemplateData(response.data?.data);
        }

        onSendTemplate.setValues({
          template: { name: "" },
          mobile_number: mobile,
          template_name: "",
          language_code: "",
          header: "",
          headerType: "",
          parameters: "",
        });

        setState({ ...state, bottom: true });
        setTemplateValue(null);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [chipData, setChipData] = useState([]);

  const handleDelete = (chipToDelete) => () => {
    // setChipData((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));

    setLoader(true);
    removeContactTag({ id: chipToDelete.contactTags_id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          handleNotificationCall("success", response.data.message);
          getAllContactTags(mobile);
        } else {
          handleNotificationCall("error", response.data.message);
        }

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function sendMessage(mobile, instanceId) {
    const form = messageRef.current;

    //setLoader(true);
    sendTextMessage(
      {
        mobile_number: mobile,
        message: `${form["text"].value}`,
      },
      { instance_id: instanceId?.id, token: token },
    )
      .then((response) => {
        if (response.data.status === true) {
          scrollDelay(mobile);
          getmessage(mobile);
          setText("");
          form.reset();
        } else {
          handleNotificationCall("error", response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function TabList(props) {
    const { value, index } = props;

    return (
      <>
        <Tab
          style={{ width: "100%" }}
          label={
            <>
              <Grid className={classes.chatList}>
                <CustomAvatar
                  name={
                    value.name === null || value.name === ""
                      ? value.phno
                      : value.name
                  }
                />
                <Grid container direction="row" spacing={2}>
                  <div className={classes.space}>
                    <Typography className={classes.userName}>
                      {value.name ? value.name : value.phno}
                    </Typography>
                    <span className={classes.time}>
                      <Typography variant="body2" className={classes.text}>
                        {formatDate(value.created)}
                      </Typography>
                    </span>
                  </div>
                  <div className={classes.space}>
                    <div className={classes.userCaptionsContainer}>
                      {value.addTick === "true" && (
                        value.status === 'delivered' ? (
                          <IoCheckmarkDoneSharp
                            size={20}
                            style={{ color: '#58595a' }}
                          />
                        ) : value.status === 'read' ? (
                          <IoCheckmarkDoneSharp size={20} style={{ color: 'blue' }} />
                        ) : (
                          <>
                            <IoMdCheckmark size={20} style={{ color: '#58595a' }} />
                          </>
                        )
                      )}

                      {value?.caption !== null && value?.caption !== "" ? (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.userCaption}
                          >
                            {value?.caption?.length > 10
                              ? value?.caption
                              : value?.caption}
                          </Typography>
                        </>
                      ) : value.format === "image" ? (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.userCaption}
                          >
                            <ImageIcon /> Photo
                          </Typography>
                        </>
                      ) : value.format === "audio" ? (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.userCaption}
                          >
                            <HeadsetIcon /> Audio
                          </Typography>
                        </>
                      ) : value.format === "video" ? (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.userCaption}
                          >
                            <VideocamIcon /> Video
                          </Typography>
                        </>
                      ) : value.format === "document" ? (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.userCaption}
                          >
                            <DescriptionIcon /> Document
                          </Typography>
                        </>
                      ) : value.format === "contact" ? (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.userCaption}
                          >
                            <PersonIcon /> Contact
                          </Typography>
                        </>
                      ) : value.format === "location" ? (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.userCaption}
                          >
                            <RoomIcon /> Location
                          </Typography>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={classes.deliveryContainer}>
                      <div>
                        {parseInt(value?.delivered_count) > 0 ?
                          <div className={classes.unreadMessageCount}>{parseInt(value?.delivered_count)}</div> : <></>}
                      </div>
                    </div>
                    {/* <span className={classes.time}>
                      {value?.from_number ? (
                        value?.status !== "marked_read" ? (
                          <>
                            <Typography
                              variant="body2"
                              className={classes.chatListMessage}
                              style={{ fontSize: "9px" }}
                            >
                              1
                            </Typography>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </span> */}
                  </div>
                </Grid>
              </Grid>
            </>
          }
          onClick={(e) => {
            setText("");
            setScrollPage(1);
            getmessage(value.phno);
            setTabOpen(true);
            setOpen({
              ...open,
              search: false,
              profile: false,
            });
            setFoundUsers(prevUsers =>
              prevUsers.map(user =>
                user.phno === value.phno
                  ? { ...user, delivered_count: 0 }
                  : user
              )
            );
          }}
          {...a11yProps(index)}
          key={index}
        />
      </>
    );
  }

  const formatDate = (created) => {
    const createdMoment = moment.utc(created).local();
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");

    if (createdMoment.isSame(today, "day")) {
      return createdMoment.format("h:mm a");
    } else if (createdMoment.isSame(yesterday, "day")) {
      return "Yesterday";
    } else {
      return createdMoment.format("DD-MM-YYYY");
    }
  };

  const [anchorTagEl, setAnchorTagEl] = React.useState(null);

  const handleTagClick = (event) => {
    setAnchorTagEl(event.currentTarget);
  };

  const handleTagClose = () => {
    setAnchorTagEl(null);
  };

  const openTag = Boolean(anchorTagEl);
  const idTag = openTag ? "simple-popover" : undefined;

  const list = (anchor, Id) => (
    <Box sx={{ width: "100%" }} role="presentation">
      <div className={classes.drawerHeader}>
        <Typography variant="subtitle1">
          {imageValue !== null && "Send Image"}
          {fileValue !== null && "Send Document"}
          {videoValue !== null && "Send Video"}
          {audioValue !== null && "Send Audio"}
        </Typography>
        <Grid className={globalClasses.drawerClose}>
          <CloseIcon
            className={globalClasses.closeBtn}
            size="14px"
            onClick={toggleDrawer(anchor, false)}
          />
        </Grid>
      </div>

      <List className={classes.messagearea}>
        <div className={classes.messageRight}>
          <PersonPinIcon className={classes.messageRightAvatar} />

          <div
            className={
              imageValue !== null
                ? classes.messageRightImage
                : classes.messageRightFile
            }
          >
            {imageValue !== null && (
              <img
                src={URL.createObjectURL(imageValue)}
                className={classes.fullWidth}
                alt="chat_image"
              />
            )}

            {fileValue !== null && (
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <FcDocument style={{ fontSize: "30px" }} />
                  </ListItemAvatar>
                  <div className={classes.lgButton}>
                    <ListItemText primary={fileValue?.name} />
                  </div>
                  {/* <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <GetAppIcon />
                      </IconButton>
                    </ListItemSecondaryAction> */}
                </ListItem>
              </List>
            )}
            {videoValue !== null && (
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <FcVideoFile style={{ fontSize: "30px" }} />
                  </ListItemAvatar>
                  <div className={classes.lgButton}>
                    <ListItemText primary={videoValue?.name} />
                  </div>
                  {/* <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <GetAppIcon />
                    </IconButton>
                  </ListItemSecondaryAction> */}
                </ListItem>
              </List>
            )}
            {audioValue !== null && (
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <FcAudioFile style={{ fontSize: "30px" }} />
                  </ListItemAvatar>
                  <div className={classes.lgButton}>
                    <ListItemText primary={audioValue?.name} />
                  </div>
                  {/* <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <GetAppIcon />
                    </IconButton>
                  </ListItemSecondaryAction> */}
                </ListItem>
              </List>
            )}
          </div>
        </div>
      </List>

      <form
        ref={fileRef}
        onSubmit={(e) => {
          e.preventDefault();
          sendFile(mobile, instanceId?.id);
        }}
        className={classes.chatListBackGround}
      >
        <Paper className={classes.paper}></Paper>

        <Button
          variant="contained"
          type="submit"
          size="small"
          className={classes.sendIconBtn}
        >
          Send
        </Button>
      </form>
    </Box>
  );

  const sendTemplateView = (anchor) => (
    <Box sx={{ width: "100%" }} role="presentation">
      <div className={classes.drawerHeader}>
        <Typography variant="subtitle1"> Send Template</Typography>
        <Grid className={globalClasses.drawerClose}>
          <CloseIcon
            className={globalClasses.closeBtn}
            size="14px"
            onClick={toggleDrawer(anchor, false)}
          />
        </Grid>
      </div>

      <List>
        <Grid
          container
          direction="row"
          spacing={2}
          style={{ justifyContent: "center" }}
          className={globalClasses.gap10}
        >
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <form
              onSubmit={onSendTemplate.handleSubmit}
              className={globalClasses.p24x8}
            >
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <FormControl className={classes.margin}>
                    <Autocomplete
                      underlineShow={false}
                      options={templateData}
                      getOptionLabel={(option) =>
                        option.name !== undefined ? `${option.name} ` : ""
                      }
                      value={templateValue}
                      onChange={(e, value) => {
                        selectTemplate(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label={"Select Template"}
                          {...params}
                          name="template"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          type="text"
                          error={
                            onSendTemplate.touched.template_name &&
                            Boolean(onSendTemplate.errors.template_name)
                          }
                          helperText={
                            onSendTemplate.touched.template_name &&
                            onSendTemplate.errors.template_name
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <TextField
                    label="To"
                    name="mobile_number"
                    placeholder="Ex: 91XXXXXXXXXX"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    classes={{ root: globalClasses.customOutlinedTextField }}
                    value={mobile}
                    error={
                      onSendTemplate.touched.mobile_number &&
                      Boolean(onSendTemplate.errors.mobile_number)
                    }
                    helperText={
                      onSendTemplate.touched.mobile_number &&
                      onSendTemplate.errors.mobile_number
                    }
                  />
                </Grid>

                {/* <Grid item xs={12} sm={12} md={12} lg={6}> 

 
   <TextField 
      
    label="Name" 
    name="template_name"
   placeholder="Enter name"
    fullWidth
    margin="normal"
   InputLabelProps={{
     shrink: true, 
   }}
   InputProps={{
     readOnly: true,
   }}
   variant="outlined"
   classes={{ root: globalClasses.customOutlinedTextField }}
   value={onSendTemplate.values.template_name} 
   onChange={onSendTemplate.handleChange} 
     
   /> 
  
 
</Grid>


<Grid item xs={12} sm={12} md={12} lg={6}> 

 <FormControl className={classes.margin}>
   <TextField 
    label="Language"
    name="language_code"
    placeholder="Enter language"
     fullWidth
     margin="normal"
    InputLabelProps={{
      shrink: true, 
    }}
    InputProps={{
     readOnly: true,
   }}
    variant="outlined"
    classes={{ root: globalClasses.customOutlinedTextField }}
    value={onSendTemplate.values.language_code}
    onChange={onSendTemplate.handleChange} 
     error={onSendTemplate.touched.language_code && Boolean(onSendTemplate.errors.language_code)}
     helperText={onSendTemplate.touched.language_code && onSendTemplate.errors.language_code} 
   /> 
  
 </FormControl>
</Grid> */}

                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <FormControl className={classes.margin}>
                    <TextField
                      label="Header"
                      name="header"
                      placeholder="Enter header"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      classes={{ root: globalClasses.customOutlinedTextField }}
                      value={onSendTemplate.values.header}
                      onChange={onSendTemplate.handleChange}
                      error={
                        onSendTemplate.touched.header &&
                        Boolean(onSendTemplate.errors.header)
                      }
                      helperText={
                        onSendTemplate.touched.header &&
                        onSendTemplate.errors.header
                      }
                    />
                  </FormControl>

                  {onSendTemplate.values.headerType === "IMAGE" ? (
                    <>
                      <Grid item xs={12}>
                        <FormControl className={classes.margin}>
                          <div style={{ display: "flex", gap: 10 }}>
                            <TextField
                              id="image"
                              type="file"
                              style={{ display: "none" }}
                              name="file_path"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleSendTemplateImage(e, "image");
                              }}
                              inputProps={{ accept: "image/jpeg, image/png" }}
                            />

                            <label htmlFor="image">
                              <Button
                                variant="contained"
                                color={"primary"}
                                className={classes.button}
                                startIcon={<FcGallery />}
                                aria-label="upload image"
                                component="span"
                              >
                                Upload image
                              </Button>
                              <Typography
                                className={classes.textWrap}
                                style={{ fontSize: "12px" }}
                              >
                                {"Maximum allowed size: 5 MB"}
                              </Typography>
                              <Typography
                                className={classes.textWrap}
                                style={{
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {"Allowed file formats include:"}
                                <Tooltip
                                  title="image/jpeg, image/png"
                                  placement="bottom"
                                  aria-label="edit"
                                >
                                  <InfoIcon
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </Typography>
                            </label>
                            {imageLoad === false ? (
                              <CheckCircleIcon
                                style={{ fontSize: 30, color: "green" }}
                              />
                            ) : imageLoad === true ? (
                              <CircularProgress
                                color="inherit"
                                style={{ color: "blue" }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </FormControl>
                      </Grid>
                    </>
                  ) : onSendTemplate.values.headerType === "VIDEO" ? (
                    <>
                      <Grid item xs={12}>
                        <FormControl className={classes.margin}>
                          <div style={{ display: "flex", gap: 10 }}>
                            <TextField
                              id="video"
                              type="file"
                              style={{ display: "none" }}
                              name="file_path"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleSendTemplateImage(e, "video");
                              }}
                              inputProps={{ accept: "video/mp4, video/3gp" }}
                            />

                            <label htmlFor="video">
                              <Button
                                variant="contained"
                                color={"primary"}
                                className={classes.button}
                                startIcon={<FcVideoFile />}
                                aria-label="upload video"
                                component="span"
                              >
                                Upload video
                              </Button>
                              <Typography
                                className={classes.textWrap}
                                style={{ fontSize: "12px" }}
                              >
                                {"maximum allowed size: 16 MB"}
                              </Typography>
                              <Typography
                                className={classes.textWrap}
                                style={{
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {"Allowed file formats include:"}
                                <Tooltip
                                  title="video/mp4, video/3gp"
                                  placement="bottom"
                                  aria-label="edit"
                                >
                                  <InfoIcon
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </Typography>
                            </label>
                            {imageLoad === false ? (
                              <CheckCircleIcon
                                style={{ fontSize: 30, color: "green" }}
                              />
                            ) : imageLoad === true ? (
                              <CircularProgress
                                color="inherit"
                                style={{ color: "blue" }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </FormControl>
                      </Grid>
                    </>
                  ) : onSendTemplate.values.headerType === "DOCUMENT" ? (
                    <>
                      <Grid item xs={12}>
                        <FormControl className={classes.margin}>
                          <div style={{ display: "flex", gap: 10 }}>
                            <TextField
                              id="document"
                              type="file"
                              style={{ display: "none" }}
                              name="file_path"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleSendTemplateImage(e, "document");
                              }}
                              inputProps={{
                                accept:
                                  "text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                              }}
                            />

                            <label htmlFor="document">
                              <Button
                                variant="contained"
                                color={"primary"}
                                className={classes.button}
                                startIcon={<FcDocument />}
                                aria-label="upload document"
                                component="span"
                              >
                                Upload document
                              </Button>

                              <Typography
                                className={classes.textWrap}
                                style={{ fontSize: "12px" }}
                              >
                                {"maximum allowed size: 100 MB"}
                              </Typography>
                              <Typography
                                className={classes.textWrap}
                                style={{
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {"Allowed file formats include:"}
                                <Tooltip
                                  title="text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  placement="bottom"
                                  aria-label="edit"
                                >
                                  <InfoIcon
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </Typography>
                            </label>
                            {imageLoad === false ? (
                              <CheckCircleIcon
                                style={{ fontSize: 30, color: "green" }}
                              />
                            ) : imageLoad === true ? (
                              <CircularProgress
                                color="inherit"
                                style={{ color: "blue" }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <FormControl className={classes.margin}>
                    <TextField
                      label="Body"
                      name="parameters"
                      placeholder="Enter body"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: messageData.bodyField,
                      }}
                      variant="outlined"
                      classes={{ root: globalClasses.customOutlinedTextField }}
                      value={onSendTemplate.values.parameters}
                      onChange={onSendTemplate.handleChange}
                      onBlur={(e) => {
                        messageTextArea(e);
                      }}
                      error={
                        onSendTemplate.touched.parameters &&
                        Boolean(onSendTemplate.errors.parameters)
                      }
                      helperText={
                        onSendTemplate.touched.parameters &&
                        onSendTemplate.errors.parameters
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} className={templateClasses.formFooter}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    // startIcon={<SendIcon/>}
                    disabled={onSendTemplate.isSubmitting}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={5}
            className={templateClasses.marginBorder}
          >
            <Grid className={globalClasses.p24x8}>
              <Typography variant="h6" className={globalClasses.center}>
                {messageData.message ? "Preview" : ""}
              </Typography>
              <br />
              <Typography variant="text">
                {onSendTemplate.values.header &&
                  onSendTemplate.values.headerType === "IMAGE" ? (
                  <img
                    src={onSendTemplate.values.header}
                    alt="logo"
                    style={{ width: "100%", height: "200px" }}
                  />
                ) : onSendTemplate.values.header &&
                  onSendTemplate.values.headerType === "VIDEO" ? (
                  <video controls width={"100%"}>
                    <source src={onSendTemplate.values.header} />
                  </video>
                ) : onSendTemplate.values.header &&
                  onSendTemplate.values.headerType === "DOCUMENT" ? (
                  <iframe
                    src={`https://docs.google.com/a/umd.edu/viewer?url=${onSendTemplate.values.header}&embedded=true`}
                    title="File"
                    width="100%"
                    height="200"
                  ></iframe>
                ) : (
                  ""
                )}
                <div
                  ref={messageRef}
                  dangerouslySetInnerHTML={{ __html: messageData.message }}
                />
                {templateButton && templateButton.length > 0 ?
                  <>
                    <Divider style={{ margin: '15px 0px' }} />
                    <div style={{ paddingTop: '5px', display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'row', gap: '20px' }}>
                      {
                        templateButton.map((button, index) => (

                          <Link key={index} type={button.type} href={button?.url} target='_blank'
                            underline='hover'
                            style={{ cursor: 'pointer', color: '#3cc1e7', fontSize: '1.2rem' }}
                          >
                            {button.text}
                          </Link>
                        ))
                      }
                    </div>
                  </>
                  :
                  <></>
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </List>
    </Box>
  );

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <div className={classes.mainContent}>
        <div className={classes.main}>
          <Grid container className={classes.fullWidth}>
            <Grid item xs={12} sm={5} md={4} lg={3}>
              {tabOpen === false ? (
                <>
                  <div className={classes.smButton}>
                    {/* <Divider /> */}
                    <Grid item xs={12} className={classes.paperContainer}>
                      <Paper sx={{ p: "2px 4px" }} className={classes.paper}>
                        <FormControl mt={4}>
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            className={classes.inputFocus}
                            inputProps={{ "aria-label": "search" }}
                            onChange={(e) => {
                              filter(e);
                            }}
                            variant="filled"
                            autoFocus
                          />
                        </FormControl>

                        {/* <IconButton aria-label="search" className={classes.searchOutLine} onClick={(e) => filter()}>
                      <SearchOutlined />
                    </IconButton> */}
                      </Paper>
                    </Grid>
                    {/* <Divider /> */}

                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={value}
                      onChange={handleContactTabChange}
                      aria-label="Vertical"
                      className={isFullScreenWhatsapp ? classes.fullScreenLeftSide : classes.leftSide}
                      onScroll={handleScrollContact}
                      TabIndicatorProps={{ style: { background: "none" } }}
                    >
                      {foundUsers?.map((row, index, arr) => {
                        return (
                          <div key={index}>
                            <TabList value={row} index={index} />
                            {/* <Divider /> */}
                          </div>
                        );
                      })}
                      {contactLoading && newContactData.length > 0 ? (
                        <div style={{ textAlign: "center" }}>
                          <CircularProgress
                            style={{
                              color: "#128C7E",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Tabs>
                  </div>
                </>
              ) : (
                ""
              )}

              <div
                className={classes.lgButton}
                style={{ borderRight: "1px solid #d3d3d3c9" }}
              >
                {/* <Divider /> */}
                <Grid item xs={12} className={classes.paperContainer}>
                  <Paper sx={{ p: "2px 2px" }} className={classes.tabPaper}>
                    <FormControl>
                      <InputBase
                        sx={{ flex: 1 }}
                        inputProps={{ "aria-label": "search" }}
                        classes={{ focused: classes.inputFocus }}
                        placeholder="Search Contact"
                        onChange={(e) => {
                          filter(e);
                        }}
                        variant="filled"
                      />
                    </FormControl>
                    <IconButton
                      aria-label="menu"
                      color="#fff"
                      className={classes.searchOutLine + " " + classes.searchBg}
                    >
                      <SearchOutlined />
                    </IconButton>
                  </Paper>
                </Grid>
                {/* <Divider /> */}

                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleContactTabChange}
                  aria-label="Vertical"
                  className={isFullScreenWhatsapp ? classes.fullScreenLeftSide : classes.leftSide}
                  onScroll={handleScrollContact}
                  TabIndicatorProps={{ style: { background: "none" } }}
                >
                  {foundUsers?.map((row, index, arr) => {
                    return (
                      <span key={index}>
                        <TabList value={row} index={index} />

                        {/* <Divider /> */}
                      </span>
                    );
                  })}
                  {contactLoading && newContactData.length > 0 ? (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress
                        style={{
                          color: "#128C7E",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </Tabs>
              </div>
            </Grid>
            {tabOpen === false && mobileViewOnly && (
              <Grid xs={12} sm={7} md={8} lg={9} className={`${classes.contentShiftInviteScreen} ${!isFullScreenWhatsapp ? classes.contentShiftInviteScreenMobile : classes.contentShiftInviteFullScreenMobile}`}>
                {isFullScreenWhatsapp && (
                  <Tooltip
                    title="Back to Screen"
                    placement="right"
                    aria-label="edit"
                  >
                    <ArrowBackIosIcon
                      className={[classes.blueIcon, classes.a]}
                      style={{ fontSize: '20px', position: 'absolute', top: '20px', left: '20px' }}
                      onClick={(e) => {
                        history.push("instance/infoMessages");
                      }}
                    />
                  </Tooltip>
                )}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: "12px" }}>
                  <div>
                    <img src={whatsappSponcerImg} style={{ width: "300px" }} width="100%" alt="whatsapp_image" />
                  </div>
                  <div className={classes.InviteScreenTextConatiner}>
                    <h1 style={{ textAlign: 'center' }}>Easily Interact with Your Clients</h1>
                    <p style={{ marginTop: 0, textAlign: 'center' }}>
                      Access WhatsApp Business seamlessly on all your devices. Engage effortlessly, share updates instantly, and streamline communication. Manage business chats conveniently from any device
                    </p>
                    {!isFullScreenWhatsapp && (
                      <Tooltip
                        title="Press F"
                        placement="right"
                        aria-label="edit"
                      >
                        <Button
                          startIcon={<img src={fullScreenKey} style={{ width: '30px' }} alt="short-key" />}
                          variant="outlined"
                          size="small"
                          className={classes.green}
                          ref={fullScreenModeRef}
                          onClick={handleFullScreen}
                        >
                          Full Screen
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                  <div style={{ position: 'relative' }}>
                    {/* <div style={{position:'absolute', bottom:0, margin: "auto", display: "flex", justifyContent: "center"}}> */}
                    {/* </div> */}
                    <p> <span className="fa-solid fa-lock"></span> Your personal messages are end-to-end encrypted</p>
                  </div>
                </div>
              </Grid>
            )}
            {tabOpen === true ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  md={8}
                  lg={9}
                  className={classes.contentShift}
                >
                  {foundUsers
                    .filter((item) => item.phno === mobile)
                    .map((row, index, arr) => {
                      return (
                        <>
                          <TabPanel value={value} index={index}>
                            <List className={classes.list}>
                              <ListItem key={index + 1}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={8}
                                  lg={9}
                                  className={classes.listGridItem}
                                >
                                  <ArrowBackIosIcon
                                    className={[classes.blueIcon, classes.a]}
                                    onClick={(e) => {
                                      setTabOpen(false);
                                    }}
                                  />

                                  <ListItemIcon
                                    className={classes.listItemIcon}
                                    onClick={() =>
                                      setOpen({ ...open, profile: true })
                                    }
                                  >
                                    {/* <Avatar
                                      alt="Image"
                                      src=""
                                      sx={{ bgcolor: "white" }}
                                    /> */}
                                    {loading && newChatData.length > 0 ? (
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          position: "absolute",
                                          top: "5px",
                                        }}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <CircularProgress
                                          style={{ color: "#fff" }}
                                        />
                                      </Grid>
                                    ) : (
                                      <></>
                                    )}
                                    <CustomAvatar
                                      name={
                                        row.name === null || row.name === ""
                                          ? row.phno
                                          : row.name
                                      }
                                    />
                                    <Typography className={classes.avatarTitle}>
                                      {row.name ? row.name : row.phno}
                                    </Typography>
                                  </ListItemIcon>
                                  {chipData.length > 0 && (
                                    <div className={classes.chip}>
                                      {chipData.slice(0, 2)?.map((data) => {
                                        let icon;

                                        return (
                                          <li key={data.key}>
                                            <Chip
                                              icon={icon}
                                              label={data.name}
                                              onDelete={handleDelete(data)}
                                              color={
                                                data?.role_code === "ADMIN"
                                                  ? "primary"
                                                  : ""
                                              }
                                            />
                                          </li>
                                        );
                                      })}
                                      {chipData?.length > 2 ? (
                                        <Link
                                          component="button"
                                          variant="body2"
                                          style={{
                                            textAlign: "center",
                                            textDecoration: "underline",
                                            color: "#FFFAFA",
                                          }}
                                          onClick={(e) => {
                                            setOpen({ ...open, profile: true });
                                          }}
                                        >
                                          more tags
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={4}
                                  lg={3}
                                  className={classes.listItem}
                                >
                                  {row?.contact_id === null ? (
                                    <Tooltip
                                      title="Add to Contact"
                                      placement="bottom"
                                      aria-label="contact"
                                    >
                                      <IconButton
                                        aria-label="menu"
                                        className={classes.searchOutLine}
                                        onClick={(e) => {
                                          setIsValid(true);
                                          const phoneNumber = parsePhoneNumber(
                                            "+" + row?.phno,
                                          );
                                          if (phoneNumber) {
                                            setCountryCode(
                                              phoneNumber.countryCallingCode,
                                            );
                                            setPhoneNumber(
                                              phoneNumber.countryCallingCode +
                                              phoneNumber.nationalNumber,
                                            );

                                            onContactAdd.resetForm();
                                            onContactAdd.setValues({
                                              name: "",
                                              email: "",
                                              mobile:
                                                phoneNumber.countryCallingCode +
                                                phoneNumber.nationalNumber,
                                            });

                                            setOpen({
                                              ...open,
                                              contact: true,
                                            });
                                          }
                                        }}
                                      >
                                        <FaUserPlus color="#FFFAFA" />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}

                                  <Tooltip
                                    title="Add to Tag"
                                    placement="bottom"
                                    aria-label="tag"
                                  >
                                    <IconButton
                                      aria-label="menu"
                                      aria-describedby={idTag}
                                      className={classes.searchOutLine}
                                      onClick={handleTagClick}
                                    >
                                      <FaUserTag color="#FFFAFA" />
                                    </IconButton>
                                  </Tooltip>

                                  {/* 
 
<Tooltip title="Add New Tag"
placement="bottom"
aria-label="tag"> 

<IconButton aria-label="menu" onClick={() => {
onTagAdd.resetForm();
 setOpen({
    ...open, tag:true})} }>
    <AddCircleIcon />
  </IconButton> 
   
</Tooltip>   */}

                                  <Tooltip
                                    title="Search"
                                    placement="bottom"
                                    aria-label="search"
                                  >
                                    <IconButton
                                      aria-label="menu"
                                      className={classes.searchOutLine}
                                      onClick={() => {
                                        setOpen({
                                          ...open,
                                          search: true,
                                        });
                                        setMessageUsers([]);
                                      }}
                                    >
                                      <SearchOutlined
                                        color="#FFFAFA"
                                        style={{ color: "#FFFAFA" }}
                                      />
                                    </IconButton>
                                  </Tooltip>

                                  <Popover
                                    id={idTag}
                                    open={openTag}
                                    anchorEl={anchorTagEl}
                                    onClose={handleTagClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    <div
                                      style={{ display: "flex", width: "100%" }}
                                    >
                                      <Autocomplete
                                        underlineShow={false}
                                        options={tagData}
                                        getOptionLabel={(option) =>
                                          option?.name !== undefined
                                            ? user?.role_code === "ADMIN"
                                              ? `${option?.name +
                                              " (" +
                                              option?.createdby +
                                              ")"
                                              }`
                                              : option?.name
                                            : ""
                                        }
                                        value={tagValue}
                                        onChange={(e, value) => {
                                          setTagValue(value);
                                          handleTag(
                                            value,
                                            row?.message_contact_id,
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="instance"
                                            fullWidth
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            type="text"
                                            style={{ width: "160px" }}
                                            className={classes.auto}
                                          />
                                        )}
                                      />
                                    </div>
                                  </Popover>

                                  <ClickAwayListener
                                    onClickAway={(e) => setProfileMenu(null)}
                                  >
                                    <div>
                                      <IconButton
                                        aria-haspopup="true"
                                        color="inherit"
                                        className={classes.headerMenuButton}
                                        aria-controls="profile-menu"
                                        onClick={(e) =>
                                          setProfileMenu(e.currentTarget)
                                        }
                                      >
                                        <MoreVertIcon
                                          classes={{ root: classes.headerIcon }}
                                          style={{ color: "#FFFAFA" }}
                                          color="#FFFAFA"
                                        />
                                      </IconButton>
                                      {profileMenu ? (
                                        <div className={classes.dropdown}>
                                          <ListItem
                                            className={
                                              classes.profileMenuIcon +
                                              " " +
                                              globalClasses.gap10
                                            }
                                            onClick={() => {
                                              onTagAdd.resetForm();
                                              setOpen({
                                                ...open,
                                                tag: true,
                                              });
                                            }}
                                          >
                                            <AddCircleIcon
                                              style={{ fontSize: 25 }}
                                            />
                                            Add New Tag
                                          </ListItem>

                                          <ListItem
                                            className={
                                              classes.profileMenuIcon +
                                              " " +
                                              globalClasses.gap10
                                            }
                                            onClick={(e) => getmessage(mobile)}
                                          >
                                            <RefreshIcon
                                              style={{ fontSize: 25 }}
                                            />
                                            Refresh
                                          </ListItem>

                                          <ListItem
                                            className={
                                              classes.profileMenuIcon +
                                              " " +
                                              globalClasses.gap10
                                            }
                                            onClick={(e) => {
                                              handleRemoveBulkOpen();
                                            }}
                                          >
                                            <BrushIcon
                                              style={{ fontSize: 25 }}
                                            />
                                            Clear Chat
                                          </ListItem>
                                        </div>
                                      ) : null}
                                    </div>
                                  </ClickAwayListener>
                                </Grid>
                              </ListItem>
                            </List>

                            <div
                              ref={scrollContainerRef}
                              className={classes.messageScroll}
                              style={{ height: isFullScreenWhatsapp ? "calc(70vh)" : "calc(58vh)" }}
                            >
                              <List className={classes.messagearea}>
                                {loadMoreBtn && newChatData.length > 0 ? (
                                  <Tooltip
                                    title="Load More"
                                    placement="bottom"
                                    aria-label="search"
                                  >
                                    <Fab
                                      color="#128C7E"
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        width: "45px",
                                        height: "45px",
                                        color: "#fff",
                                        backgroundColor: "#128C7E",
                                      }}
                                      onClick={() => getMoreMessage()}
                                      aria-label="add"
                                    >
                                      <ArrowUpwardRounded color="#fff" />
                                    </Fab>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                                {conversations?.map((row, index) => {
                                  return (
                                    <>
                                      {row?.from_number !== null ? (
                                        <>
                                          <div className={classes.message}>
                                            <PersonPinIcon
                                              className={classes.messageAvatar}
                                            />
                                            <div
                                              className={
                                                row?.replyMessageId
                                                  ? classes.messagebubbles
                                                  : row?.caption
                                                    ? row?.caption?.length < 50 &&
                                                      row?.uri === null
                                                      ? classes.messagebubble
                                                      : classes.messagebubbles
                                                    : classes.messagebubbles
                                              }
                                            >
                                              <div
                                                className={
                                                  classes.expandMoreRight
                                                }
                                                key={index}
                                              >
                                                <ExpandMoreIcon
                                                  onClick={(e) => {
                                                    handlePopoverDropOpen(e);
                                                    setSelectedItems([row?.id]);
                                                  }}
                                                />
                                              </div>
                                              {row?.type === "text" &&
                                                row?.format !== "contact" ? (
                                                row?.replyMessageId ? (
                                                  <>
                                                    <div
                                                      className={
                                                        classes.replyMessage
                                                      }
                                                    >
                                                      {conversations.filter(
                                                        (item) =>
                                                          item?.waMessageId ===
                                                          row?.replyMessageId,
                                                      )[0]?.type === "text" &&
                                                        conversations.filter(
                                                          (item) =>
                                                            item?.waMessageId ===
                                                            row?.replyMessageId,
                                                        )[0]?.format !==
                                                        "contact" ? (
                                                        <Highlighter
                                                          highlightClassName="YourHighlightClass"
                                                          searchWords={[
                                                            messageText,
                                                          ]}
                                                          autoEscape={true}
                                                          textToHighlight={
                                                            conversations.filter(
                                                              (item) =>
                                                                item?.waMessageId ===
                                                                row?.replyMessageId,
                                                            )[0]?.caption
                                                          }
                                                        />
                                                      ) : conversations.filter(
                                                        (item) =>
                                                          item?.waMessageId ===
                                                          row?.replyMessageId,
                                                      )[0]?.type === "file" &&
                                                        conversations.filter(
                                                          (item) =>
                                                            item?.waMessageId ===
                                                            row?.replyMessageId,
                                                        )[0]?.format ===
                                                        "image" ? (
                                                        <img
                                                          src={
                                                            `${process.env.REACT_APP_URL}` +
                                                            conversations.filter(
                                                              (item) =>
                                                                item?.waMessageId ===
                                                                row?.replyMessageId,
                                                            )[0]?.url
                                                          }
                                                          className={
                                                            classes.fullWidth
                                                          }
                                                          alt="chat_image"
                                                        />
                                                      ) : conversations.filter(
                                                        (item) =>
                                                          item?.waMessageId ===
                                                          row?.replyMessageId,
                                                      )[0]?.type === "file" &&
                                                        conversations.filter(
                                                          (item) =>
                                                            item?.waMessageId ===
                                                            row?.replyMessageId,
                                                        )[0]?.format ===
                                                        "audio" ? (
                                                        <audio
                                                          src={
                                                            `${process.env.REACT_APP_URL}` +
                                                            conversations.filter(
                                                              (item) =>
                                                                item?.waMessageId ===
                                                                row?.replyMessageId,
                                                            )[0]?.url
                                                          }
                                                          controls
                                                          className={
                                                            classes.fullWidth
                                                          }
                                                        />
                                                      ) : conversations.filter(
                                                        (item) =>
                                                          item?.waMessageId ===
                                                          row?.replyMessageId,
                                                      )[0]?.type === "file" &&
                                                        conversations.filter(
                                                          (item) =>
                                                            item?.waMessageId ===
                                                            row?.replyMessageId,
                                                        )[0]?.format ===
                                                        "document" ? (
                                                        <a
                                                          href={
                                                            `${process.env.REACT_APP_URL}` +
                                                            conversations.filter(
                                                              (item) =>
                                                                item?.waMessageId ===
                                                                row?.replyMessageId,
                                                            )[0]?.url
                                                          }
                                                          target="_blank"
                                                          rel="noreferrer"
                                                          download
                                                        >
                                                          <iframe
                                                            src={`https://docs.google.com/a/umd.edu/viewer?url=${process.env
                                                              .REACT_APP_URL +
                                                              row.url
                                                              }&embedded=true`}
                                                            title="File"
                                                            width="100%"
                                                            height="200"
                                                          ></iframe>
                                                          <br />

                                                          <List>
                                                            <ListItem>
                                                              <ListItemAvatar
                                                                style={{
                                                                  minWidth:
                                                                    "40px",
                                                                }}
                                                              >
                                                                <DescriptionIcon
                                                                  className={
                                                                    classes.descriptionIcon
                                                                  }
                                                                />
                                                              </ListItemAvatar>
                                                              <div
                                                                className={
                                                                  classes.lgButton
                                                                }
                                                              >
                                                                <ListItemText
                                                                  primary={
                                                                    `${process.env.REACT_APP_URL}` +
                                                                    conversations
                                                                      .filter(
                                                                        (
                                                                          item,
                                                                        ) =>
                                                                          item?.waMessageId ===
                                                                          row?.replyMessageId,
                                                                      )[0]
                                                                      ?.url.replace(
                                                                        "assets/Documents/",
                                                                        "",
                                                                      )
                                                                  }
                                                                />
                                                              </div>
                                                              <ListItemSecondaryAction>
                                                                <IconButton
                                                                  edge="end"
                                                                  aria-label="delete"
                                                                >
                                                                  <GetAppIcon />
                                                                </IconButton>
                                                              </ListItemSecondaryAction>
                                                            </ListItem>
                                                          </List>
                                                        </a>
                                                      ) : conversations.filter(
                                                        (item) =>
                                                          item?.waMessageId ===
                                                          row?.replyMessageId,
                                                      )[0]?.type === "file" &&
                                                        conversations.filter(
                                                          (item) =>
                                                            item?.waMessageId ===
                                                            row?.replyMessageId,
                                                        )[0]?.format ===
                                                        "video" ? (
                                                        <video
                                                          controls
                                                          width={"100%"}
                                                        >
                                                          <source
                                                            src={
                                                              `${process.env.REACT_APP_URL}` +
                                                              conversations.filter(
                                                                (item) =>
                                                                  item?.waMessageId ===
                                                                  row?.replyMessageId,
                                                              )[0]?.url
                                                            }
                                                            type="video/mp4"
                                                          />
                                                        </video>
                                                      ) : conversations.filter(
                                                        (item) =>
                                                          item?.waMessageId ===
                                                          row?.replyMessageId,
                                                      )[0]?.format ===
                                                        "contact" ? (
                                                        <List>
                                                          <ListItem
                                                            style={{
                                                              padding: "0px",
                                                            }}
                                                          >
                                                            <ListItemAvatar>
                                                              <Avatar alt="Image" />
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                              primary={
                                                                conversations.filter(
                                                                  (item) =>
                                                                    item?.waMessageId ===
                                                                    row?.replyMessageId,
                                                                )[0]
                                                                  ?.contactName
                                                              }
                                                              secondary={
                                                                conversations.filter(
                                                                  (item) =>
                                                                    item?.waMessageId ===
                                                                    row?.replyMessageId,
                                                                )[0]
                                                                  ?.contactNumber
                                                              }
                                                            />
                                                          </ListItem>
                                                        </List>
                                                      ) : conversations.filter(
                                                        (item) =>
                                                          item?.waMessageId ===
                                                          row?.replyMessageId,
                                                      )[0]?.type === "file" &&
                                                        conversations.filter(
                                                          (item) =>
                                                            item?.waMessageId ===
                                                            row?.replyMessageId,
                                                        )[0]?.format ===
                                                        "sticker" ? (
                                                        <img
                                                          src={
                                                            `${process.env.REACT_APP_URL}` +
                                                            conversations.filter(
                                                              (item) =>
                                                                item?.waMessageId ===
                                                                row?.replyMessageId,
                                                            )[0]?.url
                                                          }
                                                          className={
                                                            classes.fullWidth
                                                          }
                                                          alt="chat_image"
                                                        />
                                                      ) : conversations.filter(
                                                        (item) =>
                                                          item?.waMessageId ===
                                                          row?.replyMessageId,
                                                      )[0]?.format ===
                                                        "location" ? (
                                                        <List>
                                                          <ListItem>
                                                            <ListItemAvatar
                                                              style={{
                                                                minWidth:
                                                                  "40px",
                                                              }}
                                                            >
                                                              <RoomIcon
                                                                className={
                                                                  classes.descriptionIcon
                                                                }
                                                              />
                                                            </ListItemAvatar>
                                                            <div
                                                              className={
                                                                classes.lgButton
                                                              }
                                                            >
                                                              <ListItemText
                                                                primary={
                                                                  <>
                                                                    {"Address: " +
                                                                      conversations.filter(
                                                                        (
                                                                          item,
                                                                        ) =>
                                                                          item?.waMessageId ===
                                                                          row?.replyMessageId,
                                                                      )[0]
                                                                        ?.locationAddress}
                                                                    <br />
                                                                    {"Latitude: " +
                                                                      conversations.filter(
                                                                        (
                                                                          item,
                                                                        ) =>
                                                                          item?.waMessageId ===
                                                                          row?.replyMessageId,
                                                                      )[0]
                                                                        ?.latitude}
                                                                    <br />
                                                                    {"Longitude: " +
                                                                      conversations.filter(
                                                                        (
                                                                          item,
                                                                        ) =>
                                                                          item?.waMessageId ===
                                                                          row?.replyMessageId,
                                                                      )[0]
                                                                        ?.longitude}
                                                                  </>
                                                                }
                                                              />
                                                            </div>
                                                          </ListItem>
                                                        </List>
                                                      ) : (
                                                        <Highlighter
                                                          highlightClassName="YourHighlightClass"
                                                          searchWords={[
                                                            messageText,
                                                          ]}
                                                          autoEscape={true}
                                                          textToHighlight={
                                                            conversations.filter(
                                                              (item) =>
                                                                item?.waMessageId ===
                                                                row?.replyMessageId,
                                                            )[0]?.caption !==
                                                              null
                                                              ? conversations.filter(
                                                                (item) =>
                                                                  item?.waMessageId ===
                                                                  row?.replyMessageId,
                                                              )[0]?.caption
                                                              : ""
                                                          }
                                                        />
                                                      )}
                                                    </div>
                                                    <br />
                                                    <WACaptions row={row} messageText={messageText} />
                                                  </>
                                                ) : (
                                                  <>
                                                    {row?.format ===
                                                      "template_header" &&
                                                      row?.uri !== null ? (
                                                      <>
                                                        <img
                                                          src={row?.uri}
                                                          className={
                                                            classes.fullWidth
                                                          }
                                                          alt="chat_image"
                                                        />
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <WACaptions row={row} messageText={messageText} />
                                                  </>
                                                )
                                              ) : row?.type === "file" &&
                                                row?.format === "image" ? (
                                                <>
                                                  <img
                                                    src={
                                                      `${process.env.REACT_APP_URL}` +
                                                      row?.url
                                                    }
                                                    className={
                                                      classes.fullWidth
                                                    }
                                                    onClick={() =>
                                                      handleImageOpen(row?.url)
                                                    }
                                                    alt="chat_image"
                                                  />
                                                  <br />
                                                  <WACaptions row={row} messageText={messageText} />
                                                </>
                                              ) : row?.type === "file" &&
                                                row?.format === "audio" ? (
                                                <audio
                                                  src={
                                                    `${process.env.REACT_APP_URL}` +
                                                    row?.url
                                                  }
                                                  controls
                                                  className={classes.fullWidth}
                                                />
                                              ) : row?.type === "file" &&
                                                row?.format === "document" ? (
                                                <>
                                                  <a
                                                    href={
                                                      `${process.env.REACT_APP_URL}` +
                                                      row?.url
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    download
                                                  >
                                                    <iframe
                                                      src={`https://docs.google.com/a/umd.edu/viewer?url=${process.env
                                                        .REACT_APP_URL +
                                                        row.url
                                                        }&embedded=true`}
                                                      title="File"
                                                      width="100%"
                                                      height="200"
                                                    ></iframe>
                                                    <br />

                                                    <List>
                                                      <ListItem>
                                                        <ListItemAvatar
                                                          style={{
                                                            minWidth: "40px",
                                                          }}
                                                        >
                                                          <DescriptionIcon
                                                            className={
                                                              classes.descriptionIcon
                                                            }
                                                          />
                                                        </ListItemAvatar>
                                                        <div
                                                          className={
                                                            classes.lgButton
                                                          }
                                                        >
                                                          <ListItemText
                                                            primary={row?.url.replace(
                                                              "assets/Documents/",
                                                              "",
                                                            )}
                                                          />
                                                        </div>
                                                        <ListItemSecondaryAction>
                                                          <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                          >
                                                            <GetAppIcon />
                                                          </IconButton>
                                                        </ListItemSecondaryAction>
                                                      </ListItem>
                                                    </List>
                                                  </a>
                                                </>
                                              ) : row?.format === "contact" ? (
                                                <>
                                                  <List>
                                                    <ListItem
                                                      style={{ padding: "0px" }}
                                                    >
                                                      <ListItemAvatar>
                                                        <Avatar alt="Image" />
                                                      </ListItemAvatar>
                                                      <ListItemText
                                                        primary={
                                                          row?.contactName
                                                        }
                                                        secondary={
                                                          row?.contactNumber
                                                        }
                                                      />
                                                    </ListItem>
                                                  </List>
                                                </>
                                              ) : row?.type === "file" &&
                                                row.format === "video" ? (
                                                <>
                                                  <video
                                                    controls
                                                    width={"100%"}
                                                  >
                                                    <source
                                                      src={
                                                        `${process.env.REACT_APP_URL}` +
                                                        row.url
                                                      }
                                                      type="video/mp4"
                                                    />
                                                  </video>
                                                  <br />
                                                  <WACaptions row={row} messageText={messageText} />
                                                </>
                                              ) : row?.type === "file" &&
                                                row.format === "sticker" ? (
                                                <>
                                                  <img
                                                    src={
                                                      `${process.env.REACT_APP_URL}` +
                                                      row.url
                                                    }
                                                    className={
                                                      classes.fullWidth
                                                    }
                                                    alt="chat_image"
                                                  />
                                                </>
                                              ) : row?.type === "location" ? (
                                                <List>
                                                  <ListItem>
                                                    <ListItemAvatar
                                                      style={{
                                                        minWidth: "40px",
                                                      }}
                                                    >
                                                      <RoomIcon
                                                        className={
                                                          classes.descriptionIcon
                                                        }
                                                      />
                                                    </ListItemAvatar>
                                                    <div
                                                      className={
                                                        classes.lgButton
                                                      }
                                                    >
                                                      <ListItemText
                                                        primary={
                                                          <>
                                                            {"Address: " +
                                                              row.locationAddress}
                                                            <br />
                                                            {"Latitude: " +
                                                              row.latitude}
                                                            <br />
                                                            {"Longitude: " +
                                                              row.longitude}
                                                          </>
                                                        }
                                                      />
                                                    </div>
                                                  </ListItem>
                                                </List>
                                              ) : row?.replyMessageId ? (
                                                <>
                                                  <div
                                                    className={
                                                      classes.replyMessage
                                                    }
                                                  >
                                                    {conversations.filter(
                                                      (item) =>
                                                        item?.waMessageId ===
                                                        row?.replyMessageId,
                                                    )[0]?.caption !== null ? (
                                                      conversations.filter(
                                                        (item) =>
                                                          item?.waMessageId ===
                                                          row?.replyMessageId,
                                                      )[0]?.caption
                                                    ) : (
                                                      <img
                                                        src={
                                                          conversations.filter(
                                                            (item) =>
                                                              item?.waMessageId ===
                                                              row?.replyMessageId,
                                                          )[0]?.uri
                                                        }
                                                        className={
                                                          classes.fullWidth
                                                        }
                                                        alt="chat_image"
                                                      />
                                                    )}
                                                  </div>
                                                  <br />
                                                  <WACaptions row={row} messageText={messageText} />
                                                </>
                                              ) : (
                                                <>
                                                  {row?.uri
                                                    ?.split(".")
                                                    .pop() === "jpeg" ||
                                                    row?.uri?.split(".").pop() ===
                                                    "jpg" ||
                                                    row?.uri?.split(".").pop() ===
                                                    "png" ? (
                                                    <img
                                                      src={row?.uri}
                                                      className={
                                                        classes.fullWidth
                                                      }
                                                      alt="chat_image"
                                                    />
                                                  ) : row?.uri
                                                    ?.split(".")
                                                    .pop() === "mp4" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "3gp" ? (
                                                    <video
                                                      controls
                                                      width={"100%"}
                                                    >
                                                      <source src={row?.uri} />
                                                    </video>
                                                  ) : row?.uri
                                                    ?.split(".")
                                                    .pop() === "txt" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "pdf" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "ppt" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "doc" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "dot" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "xls" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "xlt" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "xla" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "docx" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "pptx" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "xlsx" ? (
                                                    <iframe
                                                      src={`https://docs.google.com/a/umd.edu/viewer?url=${row?.uri}&embedded=true`}
                                                      title="File"
                                                      width="100%"
                                                      height="200"
                                                    ></iframe>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <WACaptions row={row} messageText={messageText} />
                                                </>
                                              )}

                                              <Grid
                                                container
                                                direction="row"
                                                spacing={2}
                                                className={[
                                                  classes.space,
                                                  classes.paperContainer,
                                                ]}
                                              >
                                                <div
                                                  className={classes.bubbleSpan}
                                                >
                                                  {moment(
                                                    moment(row.created).format(
                                                      "YYYY-MM-DD[T]HH:mm:ss[Z]",
                                                    ),
                                                  )
                                                    .tz(moment.tz.guess())
                                                    .format(
                                                      "DD-MM-YYYY HH:mm A",
                                                    )}
                                                </div>
                                              </Grid>
                                            </div>
                                          </div>
                                        </>
                                      ) : row.to_number !== null ? (
                                        <>
                                          <div className={classes.messageRight}>
                                            <PersonPinIcon
                                              className={
                                                classes.messageRightAvatar
                                              }
                                            />

                                            <div
                                              className={classes.expandMoreLeft}
                                              key={index}
                                            >
                                              <ExpandMoreIcon
                                                onClick={(e) => {
                                                  handlePopoverDropOpen(e);
                                                  setSelectedItems([row?.id]);
                                                }}
                                              />
                                            </div>

                                            <div
                                              className={
                                                row?.caption?.length < 50 &&
                                                  row?.uri === null
                                                  ? classes.messageRightBubble
                                                  : classes.messageRightBubbles
                                              }
                                            >
                                              {row?.type === "text" &&
                                                row.format !== "contact" ? (
                                                row?.replyMessageId ? (
                                                  <>
                                                    <div
                                                      className={
                                                        classes.replyMessage
                                                      }
                                                    >
                                                      {conversations.filter(
                                                        (item) =>
                                                          item?.waMessageId ===
                                                          row?.replyMessageId,
                                                      )[0]?.caption !== null ? (
                                                        conversations.filter(
                                                          (item) =>
                                                            item?.waMessageId ===
                                                            row?.replyMessageId,
                                                        )[0]?.caption
                                                      ) : (
                                                        <img
                                                          src={
                                                            conversations.filter(
                                                              (item) =>
                                                                item?.waMessageId ===
                                                                row?.replyMessageId,
                                                            )[0]?.uri
                                                          }
                                                          className={
                                                            classes.fullWidth
                                                          }
                                                          alt="chat_image"
                                                        />
                                                      )}
                                                    </div>
                                                    <br />

                                                    <WACaptions row={row} messageText={messageText} />
                                                  </>
                                                ) : (
                                                  <>
                                                    {row?.uri
                                                      ?.split(".")
                                                      .pop() === "jpeg" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "jpg" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "png" ? (
                                                      <img
                                                        src={row?.uri}
                                                        className={
                                                          classes.fullWidth
                                                        }
                                                        alt="chat_image"
                                                      />
                                                    ) : row?.uri
                                                      ?.split(".")
                                                      .pop() === "mp4" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "3gp" ? (
                                                      <video
                                                        controls
                                                        width={"100%"}
                                                      >
                                                        <source
                                                          src={row?.uri}
                                                        />
                                                      </video>
                                                    ) : row?.uri
                                                      ?.split(".")
                                                      .pop() === "txt" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "pdf" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "ppt" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "doc" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "dot" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "xls" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "xlt" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "xla" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "docx" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "pptx" ||
                                                      row?.uri
                                                        ?.split(".")
                                                        .pop() === "xlsx" ? (
                                                      <iframe
                                                        src={`https://docs.google.com/a/umd.edu/viewer?url=${row?.uri}&embedded=true`}
                                                        title="File"
                                                        width="100%"
                                                        height="200"
                                                      ></iframe>
                                                    ) : (
                                                      ""
                                                    )}

                                                    <WACaptions row={row} messageText={messageText} />
                                                  </>
                                                )
                                              ) : row?.type === "file" &&
                                                row.format === "image" ? (
                                                <>
                                                  <img
                                                    src={
                                                      `${process.env.REACT_APP_URL}` +
                                                      row.url
                                                    }
                                                    className={
                                                      classes.fullWidth
                                                    }
                                                    onClick={() =>
                                                      handleImageOpen(row.url)
                                                    }
                                                    alt="chat_image"
                                                  />
                                                  <br />
                                                  <WACaptions row={row} messageText={messageText} />
                                                </>
                                              ) : row?.type === "file" &&
                                                row.format === "audio" ? (
                                                <audio
                                                  src={
                                                    `${process.env.REACT_APP_URL}` +
                                                    row.url
                                                  }
                                                  controls
                                                  className={classes.fullWidth}
                                                />
                                              ) : row?.type === "file" &&
                                                row.format === "document" ? (
                                                <>
                                                  <a
                                                    href={
                                                      `${process.env.REACT_APP_URL}` +
                                                      row.url
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    download
                                                  >
                                                    <iframe
                                                      src={`https://docs.google.com/a/umd.edu/viewer?url=${process.env
                                                        .REACT_APP_URL +
                                                        row.url
                                                        }&embedded=true`}
                                                      title="File"
                                                      width="100%"
                                                      height="200"
                                                    ></iframe>
                                                    <br />

                                                    <List>
                                                      <ListItem>
                                                        <ListItemAvatar>
                                                          <DescriptionIcon
                                                            className={
                                                              classes.descriptionIcon
                                                            }
                                                          />
                                                        </ListItemAvatar>
                                                        <div
                                                          className={
                                                            classes.lgButton
                                                          }
                                                        >
                                                          <ListItemText
                                                            primary={row?.url.replace(
                                                              "assets/Documents/",
                                                              "",
                                                            )}
                                                          />
                                                        </div>
                                                        <ListItemSecondaryAction>
                                                          <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                          >
                                                            <GetAppIcon />
                                                          </IconButton>
                                                        </ListItemSecondaryAction>
                                                      </ListItem>
                                                    </List>
                                                  </a>
                                                </>
                                              ) : row.format === "contact" ? (
                                                <>
                                                  <List>
                                                    <ListItem
                                                      style={{ padding: "0px" }}
                                                    >
                                                      <ListItemAvatar>
                                                        <Avatar alt="Image" />
                                                      </ListItemAvatar>
                                                      <ListItemText
                                                        primary={
                                                          row.contactName
                                                        }
                                                        secondary={
                                                          row.contactNumber
                                                        }
                                                      />
                                                    </ListItem>
                                                  </List>
                                                </>
                                              ) : row?.type === "file" &&
                                                row.format === "video" ? (
                                                <>
                                                  <video
                                                    controls
                                                    width={"100%"}
                                                  >
                                                    <source
                                                      src={
                                                        `${process.env.REACT_APP_URL}` +
                                                        row.url
                                                      }
                                                      type="video/mp4"
                                                    />
                                                  </video>
                                                  <br />
                                                  <WACaptions row={row} messageText={messageText} />
                                                </>
                                              ) : row?.type === "file" &&
                                                row.format === "sticker" ? (
                                                <>
                                                  <img
                                                    src={
                                                      `${process.env.REACT_APP_URL}` +
                                                      row.url
                                                    }
                                                    className={
                                                      classes.fullWidth
                                                    }
                                                    alt="chat_image"
                                                  />
                                                </>
                                              ) : row?.type === "location" ? (
                                                <List>
                                                  <ListItem>
                                                    <ListItemAvatar>
                                                      <RoomIcon
                                                        className={
                                                          classes.descriptionIcon
                                                        }
                                                      />
                                                    </ListItemAvatar>
                                                    <div
                                                      className={
                                                        classes.lgButton
                                                      }
                                                    >
                                                      <ListItemText
                                                        primary={
                                                          <>
                                                            {"Address: " +
                                                              row.locationAddress}
                                                            <br />
                                                            {"Latitude: " +
                                                              row.latitude}
                                                            <br />
                                                            {"Longitude: " +
                                                              row.longitude}
                                                          </>
                                                        }
                                                      />
                                                    </div>
                                                  </ListItem>
                                                </List>
                                              ) : row?.replyMessageId ? (
                                                <>
                                                  {conversations.filter(
                                                    (item) =>
                                                      item?.waMessageId ===
                                                      row?.replyMessageId,
                                                  )[0]?.caption !== null ? (
                                                    <div
                                                      className={
                                                        classes.replyMessage
                                                      }
                                                    >
                                                      {
                                                        conversations.filter(
                                                          (item) =>
                                                            item?.waMessageId ===
                                                            row?.replyMessageId,
                                                        )[0]?.caption
                                                      }
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <br />
                                                  <WACaptions row={row} messageText={messageText} />
                                                </>
                                              ) : (
                                                <>
                                                  {row?.uri
                                                    ?.split(".")
                                                    .pop() === "jpeg" ||
                                                    row?.uri?.split(".").pop() ===
                                                    "jpg" ||
                                                    row?.uri?.split(".").pop() ===
                                                    "png" ? (
                                                    <img
                                                      src={row?.uri}
                                                      className={
                                                        classes.fullWidth
                                                      }
                                                      alt="chat_image"
                                                    />
                                                  ) : row?.uri
                                                    ?.split(".")
                                                    .pop() === "mp4" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "3gp" ? (
                                                    <video
                                                      controls
                                                      width={"100%"}
                                                    >
                                                      <source src={row?.uri} />
                                                    </video>
                                                  ) : row?.uri
                                                    ?.split(".")
                                                    .pop() === "txt" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "pdf" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "ppt" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "doc" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "dot" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "xls" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "xlt" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "xla" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "docx" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "pptx" ||
                                                    row?.uri
                                                      ?.split(".")
                                                      .pop() === "xlsx" ? (
                                                    <iframe
                                                      src={`https://docs.google.com/a/umd.edu/viewer?url=${row?.uri}&embedded=true`}
                                                      title="File"
                                                      width="100%"
                                                      height="200"
                                                    ></iframe>
                                                  ) : (
                                                    ""
                                                  )}

                                                  <WACaptions row={row} messageText={messageText} />
                                                </>
                                              )}


                                              <Grid
                                                container
                                                direction="row"
                                                spacing={2}
                                                className={[
                                                  classes.space,
                                                  classes.paperContainer,
                                                ]}
                                              >
                                                <div
                                                  className={classes.bubbleSpan}
                                                >
                                                  {moment(
                                                    moment(row.created).format(
                                                      "YYYY-MM-DD[T]HH:mm:ss[Z]",
                                                    ),
                                                  )
                                                    .tz(moment.tz.guess())
                                                    .format(
                                                      "DD-MM-YYYY HH:mm A",
                                                    )}
                                                </div>

                                                <div
                                                  className={classes.blueTick}
                                                >
                                                  {row?.status ===
                                                    "delivered" ? (
                                                    <DoneAllIcon color="disabled" />
                                                  ) : row?.status === "read" ? (
                                                    <DoneAllIcon color="primary" />
                                                  ) : (
                                                    <DoneIcon color="disabled" />
                                                  )}
                                                </div>
                                              </Grid>
                                              {row?.buttons && row?.buttons !== "" ?
                                                <>
                                                  <Divider style={{ margin: '5px -12px' }} />
                                                  <div style={{ paddingTop: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                                    {
                                                      JSON.parse(row.buttons).buttons.map((button, index) => (

                                                        <Link key={index} type={button.type} href={button?.url} target='_blank'
                                                          underline='hover'
                                                          style={{ cursor: 'pointer', color: '#3cc1e7', fontSize: '0.8rem' }}
                                                        >
                                                          {button.text}
                                                        </Link>
                                                      ))
                                                    }
                                                  </div>
                                                </>
                                                :
                                                <></>
                                              }
                                            </div>
                                          </div>
                                          <div className={classes.messageRightBtnFooterContainer}>
                                            {row?.buttons && row?.buttons !== "" && JSON.parse(row.buttons).type === 'BUTTONS' ?
                                              <div className={classes.messageRightBtnFooter}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', gap: '10px', flexWrap: 'wrap' }}>
                                                  {
                                                    JSON.parse(row.buttons).buttons.map((button, index) => (
                                                      <button className="footer-reply-button" role="button">{button.text}</button>
                                                    ))
                                                  }
                                                </div>
                                              </div>
                                              :
                                              <></>
                                            }
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}

                                <Popover
                                  id={idsDrop}
                                  open={opensDrop}
                                  anchorEl={anchorDropEl}
                                  onClose={handlePopoverDropClose}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                >
                                  <ListItem
                                    button
                                    disableRipple
                                    style={{
                                      display: "block",
                                      padding: "10px",
                                    }}
                                  >
                                    <ListItemText
                                      primary={"Delete Chat"}
                                      onClick={(e) => {
                                        handleRemoveOpen();
                                      }}
                                    />
                                  </ListItem>
                                </Popover>

                                <Drawer
                                  className={classes.drawer}
                                  anchor="right"
                                  open={open.profile}
                                  classes={{
                                    paper: classes.drawerPaper,
                                  }}
                                  onClose={() =>
                                    setOpen({
                                      ...open,
                                      profile: false,
                                    })
                                  }
                                >
                                  <div className={classes.drawerHeader}>
                                    <IconButton
                                      onClick={(e) =>
                                        setOpen({
                                          ...open,
                                          profile: false,
                                        })
                                      }
                                    >
                                      <ChevronLeftIcon />
                                    </IconButton>
                                    <Typography
                                      variant="inherit"
                                      color="textSecondary"
                                    >
                                      Contact Info
                                    </Typography>
                                  </div>
                                  <Divider />
                                  <List className={classes.contactprofile}>
                                    <Avatar
                                      alt="Image"
                                      src=""
                                      className={classes.contactprofileImg}
                                    />
                                  </List>
                                  <List className={classes.contactDetails}>
                                    <Typography
                                      variant="h6"
                                      color="textSecondary"
                                      className={classes.title}
                                    >
                                      {row.name ? row.name : row.phno}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      {row.phno}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      <div className={classes.chip}>
                                        {chipData?.map((data) => {
                                          let icon;

                                          return (
                                            <li key={data.key}>
                                              <Chip
                                                icon={icon}
                                                label={data.name}
                                                size="small"
                                                onDelete={handleDelete(data)}
                                                color={
                                                  data?.role_code === "ADMIN"
                                                    ? "primary"
                                                    : ""
                                                }
                                              />
                                            </li>
                                          );
                                        })}
                                      </div>
                                    </Typography>
                                  </List>
                                </Drawer>

                                <Drawer
                                  className={classes.drawer}
                                  anchor="right"
                                  open={open.search}
                                  classes={{
                                    paper: classes.searchdrawerPaper,
                                  }}
                                  onClose={() =>
                                    setOpen({
                                      ...open,
                                      search: false,
                                    })
                                  }
                                >
                                  <div className={classes.drawerHeader}>
                                    <IconButton
                                      onClick={() =>
                                        setOpen({
                                          ...open,
                                          search: false,
                                        })
                                      }
                                    >
                                      <ChevronLeftIcon />
                                    </IconButton>
                                    <Typography
                                      variant="inherit"
                                      color="textSecondary"
                                    >
                                      Search
                                    </Typography>
                                  </div>
                                  <Divider />
                                  <form ref={searchRef}>
                                    <InputBase
                                      sx={{ ml: 1, flex: 1 }}
                                      inputProps={{ "aria-label": "search" }}
                                      placeholder="Enter Search"
                                      onChange={search}
                                      variant="filled"
                                    />
                                  </form>

                                  <Grid className={classes.messageAreaSingle}>
                                    {messageUsers
                                      .filter(
                                        (item) =>
                                          item?.type === "text" &&
                                          item?.format !== "contact",
                                      )
                                      .map((row, index) => {
                                        return (
                                          <>
                                            {row.to_number === null ? (
                                              <>
                                                <div
                                                  className={classes.message}
                                                >
                                                  <PersonPinIcon
                                                    className={
                                                      classes.messageAvatar
                                                    }
                                                  />
                                                  <div
                                                    className={
                                                      classes.messagebubbles
                                                    }
                                                  >
                                                    <>
                                                      {row?.uri
                                                        ?.split(".")
                                                        .pop() === "jpeg" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "jpg" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "png" ? (
                                                        <img
                                                          src={row?.uri}
                                                          className={
                                                            classes.fullWidth
                                                          }
                                                          alt="chat_image"
                                                        />
                                                      ) : row?.uri
                                                        ?.split(".")
                                                        .pop() === "mp4" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "3gp" ? (
                                                        <video
                                                          controls
                                                          width={"100%"}
                                                        >
                                                          <source
                                                            src={row?.uri}
                                                          />
                                                        </video>
                                                      ) : row?.uri
                                                        ?.split(".")
                                                        .pop() === "txt" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "pdf" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "ppt" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "doc" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "dot" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "xls" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "xlt" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "xla" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "docx" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "pptx" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "xlsx" ? (
                                                        <iframe
                                                          src={`https://docs.google.com/a/umd.edu/viewer?url=${row?.uri}&embedded=true`}
                                                          title="File"
                                                          width="100%"
                                                          height="200"
                                                        ></iframe>
                                                      ) : (
                                                        ""
                                                      )}

                                                      <WACaptions row={row} messageText={messageText} />
                                                    </>

                                                    <Grid
                                                      container
                                                      direction="row"
                                                      spacing={2}
                                                      className={[
                                                        classes.space,
                                                        classes.paperContainer,
                                                      ]}
                                                    >
                                                      <div
                                                        className={
                                                          classes.bubbleSpan
                                                        }
                                                      >
                                                        {moment(
                                                          moment(
                                                            row.created,
                                                          ).format(
                                                            "YYYY-MM-DD[T]HH:mm:ss[Z]",
                                                          ),
                                                        )
                                                          .tz(moment.tz.guess())
                                                          .format(
                                                            "DD-MM-YYYY HH:mm A",
                                                          )}
                                                      </div>
                                                      <div
                                                        className={
                                                          classes.blueTick
                                                        }
                                                      >
                                                        {row?.status ===
                                                          "delivered" ? (
                                                          <DoneAllIcon color="disabled" />
                                                        ) : row?.status ===
                                                          "read" ? (
                                                          <DoneAllIcon color="primary" />
                                                        ) : (
                                                          <DoneIcon color="disabled" />
                                                        )}
                                                      </div>
                                                    </Grid>
                                                  </div>
                                                </div>
                                              </>
                                            ) : row.from_number === null ? (
                                              <>
                                                <div
                                                  className={
                                                    classes.messageRight
                                                  }
                                                >
                                                  <PersonPinIcon
                                                    className={
                                                      classes.messageRightAvatar
                                                    }
                                                  />

                                                  <div
                                                    className={
                                                      classes.messageRightBubbles
                                                    }
                                                  >
                                                    <>
                                                      {row?.uri
                                                        ?.split(".")
                                                        .pop() === "jpeg" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "jpg" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "png" ? (
                                                        <img
                                                          src={row?.uri}
                                                          className={
                                                            classes.fullWidth
                                                          }
                                                          alt="chat_image"
                                                        />
                                                      ) : row?.uri
                                                        ?.split(".")
                                                        .pop() === "mp4" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "3gp" ? (
                                                        <video
                                                          controls
                                                          width={"100%"}
                                                        >
                                                          <source
                                                            src={row?.uri}
                                                          />
                                                        </video>
                                                      ) : row?.uri
                                                        ?.split(".")
                                                        .pop() === "txt" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "pdf" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "ppt" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "doc" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "dot" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "xls" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "xlt" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "xla" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "docx" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "pptx" ||
                                                        row?.uri
                                                          ?.split(".")
                                                          .pop() === "xlsx" ? (
                                                        <iframe
                                                          src={`https://docs.google.com/a/umd.edu/viewer?url=${row?.uri}&embedded=true`}
                                                          title="File"
                                                          width="100%"
                                                          height="200"
                                                        ></iframe>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <WACaptions row={row} messageText={messageText} />
                                                    </>

                                                    <Grid
                                                      container
                                                      direction="row"
                                                      spacing={2}
                                                      className={[
                                                        classes.space,
                                                        classes.paperContainer,
                                                      ]}
                                                    >
                                                      <div
                                                        className={
                                                          classes.bubbleSpan
                                                        }
                                                      >
                                                        {row?.created
                                                          ? moment(
                                                            moment(
                                                              row?.created,
                                                            ).format(
                                                              "YYYY-MM-DD[T]HH:mm:ss[Z]",
                                                            ),
                                                          )
                                                            .tz(
                                                              moment.tz.guess(),
                                                            )
                                                            .format(
                                                              "DD-MM-YYYY HH:mm A",
                                                            )
                                                          : ""}
                                                      </div>
                                                      <div
                                                        className={
                                                          classes.blueTick
                                                        }
                                                      >
                                                        {row?.status ===
                                                          "delivered" ? (
                                                          <DoneAllIcon color="disabled" />
                                                        ) : row?.status ===
                                                          "read" ? (
                                                          <DoneAllIcon color="primary" />
                                                        ) : (
                                                          <DoneIcon color="disabled" />
                                                        )}
                                                      </div>
                                                    </Grid>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })}
                                  </Grid>
                                </Drawer>

                                <Drawer
                                  className={classes.drawer}
                                  anchor="right"
                                  open={open.tag}
                                  classes={{
                                    paper: classes.tagdrawerPaper,
                                  }}
                                  onClose={() =>
                                    setOpen({
                                      ...open,
                                      tag: false,
                                    })
                                  }
                                >
                                  <div className={classes.drawerHeader}>
                                    <IconButton
                                      onClick={() =>
                                        setOpen({
                                          ...open,
                                          tag: false,
                                        })
                                      }
                                    >
                                      <ChevronLeftIcon />
                                    </IconButton>
                                    <Typography
                                      variant="inherit"
                                      color="textSecondary"
                                    >
                                      Add New Tag
                                    </Typography>
                                  </div>
                                  <Divider />

                                  <form onSubmit={onTagAdd.handleSubmit}>
                                    <Grid
                                      container
                                      direction="row"
                                      spacing={2}
                                      className={classes.drawerContent}
                                    >
                                      <Grid item xs={12}>
                                        <InputLabel shrink htmlFor="tag_name">
                                          Tag Name
                                        </InputLabel>

                                        <FormControl className={classes.margin}>
                                          <TextField
                                            classes={{
                                              root: classes.customTextField,
                                            }}
                                            InputProps={{
                                              disableUnderline: true,
                                            }}
                                            size="small"
                                            placeholder="Enter tag name"
                                            type="text"
                                            name="tag_name"
                                            value={onTagAdd.values.tag_name}
                                            onChange={onTagAdd.handleChange}
                                          />
                                        </FormControl>
                                        {onTagAdd.touched.tag_name ? (
                                          <Typography
                                            variant="inherit"
                                            color="error"
                                          >
                                            {onTagAdd.errors.tag_name}
                                          </Typography>
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      container
                                      direction="row"
                                      spacing={2}
                                      className={classes.drawerFooter}
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        type="submit"
                                        disabled={onTagAdd.isSubmitting}
                                      >
                                        Save
                                      </Button>

                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="secondary"
                                        onClick={(e) => {
                                          setOpen({
                                            ...open,
                                            tag: false,
                                          });
                                        }}
                                      >
                                        Close
                                      </Button>
                                    </Grid>
                                  </form>
                                </Drawer>

                                <Drawer
                                  className={classes.drawer}
                                  variant="persistent"
                                  anchor="right"
                                  open={open.contact}
                                  classes={{
                                    paper: classes.searchdrawerPaper,
                                  }}
                                  onClose={() =>
                                    setOpen({
                                      ...open,
                                      contact: false,
                                    })
                                  }
                                >
                                  <div className={classes.drawerHeader}>
                                    <IconButton
                                      onClick={() =>
                                        setOpen({
                                          ...open,
                                          contact: false,
                                        })
                                      }
                                    >
                                      <ChevronLeftIcon />
                                    </IconButton>
                                    <Typography
                                      variant="inherit"
                                      color="textSecondary"
                                    >
                                      Add New Contact
                                    </Typography>
                                  </div>
                                  <Divider />

                                  <form onSubmit={onContactAdd.handleSubmit}>
                                    <Grid
                                      container
                                      direction="row"
                                      spacing={2}
                                      className={classes.drawerContent}
                                    >
                                      <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <InputLabel shrink htmlFor="name">
                                          Name
                                        </InputLabel>

                                        <FormControl className={classes.margin}>
                                          <TextField
                                            classes={{
                                              root: classes.customTextField,
                                            }}
                                            InputProps={{
                                              disableUnderline: true,
                                            }}
                                            size="small"
                                            placeholder="Enter name"
                                            type="text"
                                            name="name"
                                            value={onContactAdd.values.name}
                                            onChange={onContactAdd.handleChange}
                                          />

                                          {onContactAdd.touched.name ? (
                                            <Typography
                                              variant="inherit"
                                              color="error"
                                            >
                                              {onContactAdd.errors.name}
                                            </Typography>
                                          ) : (
                                            ""
                                          )}
                                        </FormControl>
                                      </Grid>

                                      <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <InputLabel shrink htmlFor="email">
                                          Email
                                        </InputLabel>

                                        <TextField
                                          id="email"
                                          classes={{
                                            root: classes.customTextField,
                                          }}
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          name="email"
                                          margin="normal"
                                          placeholder="Enter Email"
                                          type="email"
                                          fullWidth
                                          value={onContactAdd.values.email}
                                          onChange={onContactAdd.handleChange}
                                        />

                                        {onContactAdd.touched.email ? (
                                          <Typography
                                            variant="inherit"
                                            color="error"
                                          >
                                            {onContactAdd.errors.email}
                                          </Typography>
                                        ) : (
                                          ""
                                        )}
                                      </Grid>

                                      <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <InputLabel
                                          shrink
                                          htmlFor="phoneNumber"
                                        >
                                          Mobile
                                        </InputLabel>

                                        <PhoneInput
                                          country={"in"}
                                          id="mobile"
                                          enableSearch={true}
                                          value={phoneNumber}
                                          onChange={(val, allDetails) => {
                                            handlePhoneChange(val, allDetails);
                                            setIsValid(
                                              parseMobile(
                                                "+" + phoneNumber,
                                              )?.isValid(),
                                            );
                                          }}
                                          onBlur={(e) => {
                                            setIsValid(
                                              parseMobile(
                                                "+" + phoneNumber,
                                              )?.isValid(),
                                            );
                                          }}
                                          margin="normal"
                                          name="mobile"
                                          variant="outlined"
                                          disabled={true}
                                        />
                                        {onContactAdd.touched.mobile ? (
                                          <Typography
                                            variant="inherit"
                                            color="error"
                                          >
                                            {onContactAdd.errors.mobile}
                                          </Typography>
                                        ) : isValid !== true ? (
                                          <Typography
                                            variant="inherit"
                                            color="error"
                                          >
                                            {"Invalid phone number format"}
                                          </Typography>
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      container
                                      direction="row"
                                      spacing={2}
                                      className={classes.drawerSearchFooter}
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        type="submit"
                                        disabled={
                                          isValid !== true
                                            ? true
                                            : onContactAdd.isSubmitting
                                        }
                                      >
                                        Save
                                      </Button>

                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="secondary"
                                        onClick={(e) => {
                                          setOpen({
                                            ...open,
                                            contact: false,
                                          });
                                        }}
                                      >
                                        Close
                                      </Button>
                                    </Grid>
                                  </form>
                                </Drawer>
                                <div
                                  id={
                                    "section" + localStorage.getItem("mobile")
                                  }
                                ></div>
                              </List>
                            </div>

                            <Grid container className={classes.foot}>
                              {valid === true && view === "Message" ? (
                                <form
                                  ref={messageRef}
                                  className={classes.fullWidth}
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    sendMessage(mobile, instanceId);
                                  }}
                                >
                                  <>
                                    <Grid
                                      container
                                      direction="row"
                                      spacing={2}
                                      className={classes.space}
                                    >
                                      <Grid item xs={12} lg={11}>
                                        <Paper className={classes.paper}>
                                          <InputBase
                                            required={true}
                                            inputProps={{
                                              "aria-label": "search",
                                            }}
                                            placeholder="Enter Message Here"
                                            value={text}
                                            onChange={(e) =>
                                              setText(e.target.value)
                                            }
                                            name="text"
                                          />
                                        </Paper>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        lg={1}
                                        className={classes.drawerClose}
                                      >
                                        <Button
                                          aria-describedby={ids}
                                          variant="filled"
                                          color="primary"
                                          className={classes.button}
                                          onClick={handlePopoverClick}
                                        >
                                          <AttachFileIcon color="primary" />
                                        </Button>

                                        <Button
                                          type="submit"
                                          color="primary"
                                          className={classes.button}
                                        >
                                          <SendIcon />
                                        </Button>
                                      </Grid>
                                    </Grid>

                                    <Popover
                                      id={ids}
                                      open={opens}
                                      anchorEl={anchorEl}
                                      onClose={handlePopoverClose}
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        style={{ padding: "0px 20px" }}
                                        className={[
                                          classes.fullWidth,
                                          classes.center,
                                        ]}
                                      >
                                        <Grid
                                          item
                                          xs={3}
                                          className={classes.photoLibrary}
                                          onClick={(e) => {
                                            handleImageClick();
                                          }}
                                        >
                                          <FcGallery
                                            style={{ fontSize: "30px" }}
                                          />
                                          <br />
                                          <input
                                            type="file"
                                            ref={hiddenImageInput}
                                            onChange={handleImageChange}
                                            className={classes.nothing}
                                            accept="image/jpeg, image/png"
                                          />
                                          Picture
                                        </Grid>

                                        <Grid
                                          item
                                          xs={3}
                                          className={classes.photoLibrary}
                                          onClick={(e) => {
                                            handleAudioClick();
                                          }}
                                        >
                                          <FcAudioFile
                                            style={{ fontSize: "30px" }}
                                          />
                                          <br />
                                          <input
                                            type="file"
                                            ref={hiddenAudioInput}
                                            onChange={handleAudioChange}
                                            className={classes.nothing}
                                            accept="audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg"
                                          />
                                          Audio
                                        </Grid>

                                        <Grid
                                          item
                                          xs={3}
                                          className={classes.photoLibrary}
                                          onClick={(e) => {
                                            handleVideoClick();
                                          }}
                                        >
                                          <FcVideoFile
                                            style={{ fontSize: "30px" }}
                                          />
                                          <br />
                                          <input
                                            type="file"
                                            ref={hiddenVideoInput}
                                            onChange={handleVideoChange}
                                            className={classes.nothing}
                                            accept="video/mp4, video/3gp"
                                          />
                                          Video
                                        </Grid>

                                        <Grid
                                          item
                                          xs={3}
                                          className={classes.photoLibrary}
                                          onClick={(e) => {
                                            handleDocumentClick();
                                          }}
                                        >
                                          <FcDocument
                                            style={{ fontSize: "30px" }}
                                          />
                                          <br />
                                          <input
                                            type="file"
                                            ref={hiddenFileInput}
                                            onChange={handleFileChange}
                                            className={classes.nothing}
                                            accept="text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                          />
                                          Document
                                        </Grid>
                                      </Grid>
                                    </Popover>
                                  </>
                                </form>
                              ) : (
                                <>
                                  <Grid
                                    container
                                    direction="row"
                                    spacing={2}
                                    className={classes.space}
                                  >
                                    <Grid item xs={12} sm={10} lg={10}>
                                      <TextField
                                        multiline
                                        maxRows={5}
                                        minRows={1}
                                        disabled={true}
                                        inputProps={{ "aria-label": "search" }}
                                        value={
                                          "This is your first message for this contact today use general_message to start chat"
                                        }
                                        name="text"
                                      />
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      lg={2}
                                      className={classes.center}
                                    >
                                      <Button
                                        onClick={(e) => { handleClick(instanceId) }
                                        }
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                      >
                                        Send Here
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>

                            <SwipeableDrawer
                              anchor="bottom"
                              open={state["bottom"]}
                              onClose={toggleDrawer("bottom", false)}
                              onOpen={toggleDrawer("bottom", true)}
                              classes={{ paper: classes.drawer }}
                            >
                              {valid === true && view === "Message"
                                ? list("bottom", instanceId)
                                : sendTemplateView("bottom")}
                            </SwipeableDrawer>
                          </TabPanel>
                        </>
                      );
                    })}

                  <Dialog
                    onClose={handleImageClose}
                    open={imageOpen}
                    className={classes.modalSize}
                  >
                    <img
                      src={`${process.env.REACT_APP_URL}` + imageUrl}
                      className={classes.modalImage}
                      alt="chat_image"
                    />
                  </Dialog>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </div>
      </div>

      <Dialog
        onClose={handleRemoveClose}
        aria-labelledby="dialog-title"
        open={removeOpen}
        width="md"
        PaperProps={{ style: { width: "100%" } }}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogContentText>
            <Grid
              className={globalClasses.center + " " + globalClasses.dialogTitle}
            >
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} className={globalClasses.dialogHeader}>
                  <Typography variant="subtitle1">
                    Are you sure you want to delete this messages?
                  </Typography>

                  <CloseIcon
                    className={globalClasses.closeBtn}
                    size="14px"
                    onClick={(e) => [handleRemoveClose()]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                handleDeleteMessage();
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleRemoveClose}
            >
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleRemoveBulkClose}
        aria-labelledby="dialog-title"
        open={removeBulkOpen}
        width="md"
        PaperProps={{ style: { width: "100%" } }}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogContentText>
            <Grid
              className={globalClasses.center + " " + globalClasses.dialogTitle}
            >
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} className={globalClasses.dialogHeader}>
                  <Typography variant="subtitle1">
                    Are you sure you want to delete this conversation?
                  </Typography>

                  <CloseIcon
                    className={globalClasses.closeBtn}
                    size="14px"
                    onClick={(e) => [handleRemoveBulkClose()]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                handleDeleteConversation();
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleRemoveBulkClose}
            >
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Backdrop className={classes.backdrop} open={loader}>
        {imageLoad === true ? (
          <Typography style={{ color: "white", fontSize: "16px" }}>
            Uploading please wait...
          </Typography>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Backdrop>
    </MuiThemeProvider>
  );
};

export default Home;
