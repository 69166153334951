import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Dot from "../Dot";
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import useStyles from "./styles";

export default function SidebarLink({
  view,
  activePath,
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
}) {
  // local
  var [isOpen, setIsOpen] = useState(false);
  // var isLinkActive =
  //   link &&
  //   (location.pathname === link || location.pathname.indexOf(link) !== -1);

  var isPathActive = location.pathname.includes(activePath)
  var classes = useStyles();
  if (type === "divider") return <Divider className={classes.divider} />;
  if (link && link.includes("http")) {
    return (
      <ListItem
        button
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isPathActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <a className={classes.externalLink} href={link}>
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isPathActive,
            })}
          >
            {nested ? <Dot color={isPathActive && "primary"} /> : icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isPathActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </a>
      </ListItem>
    );
  }
  if (!children)
    return view === true ? (
      <>
        <ListItem
          button
          component={link && Link}
          to={link}
          className={classes.link}
          classes={{
            root: classnames(classes.linkRoot, {
              [classes.linkActive]: isPathActive && !nested,
              [classes.linkNested]: nested,
            }),
          }}
          disableRipple
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isPathActive,
            })}
          >
            {nested ? <Dot color={isPathActive && "primary"} /> : icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isPathActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </ListItem>
      </>
    ) : (
      ""
    );

  return (
    <>
      {view === true ? (
        <>
          <ListItem
            button
            to={link}
            classes={{
              root: classnames(classes.sidedropdownList),
            }}
            selected={location.pathname === link}
            onClick={(e) => {
              toggleCollapse(e, link);
            }}
            disableRipple
          >
            <ListItemIcon
              className={classnames(classes.subMenulinkIcon, {
                [classes.linkIconActive]: isPathActive,
              })}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classnames(classes.linkText, {
                  [classes.linkTextActive]: isPathActive,
                  [classes.linkTextHidden]: !isSidebarOpened,
                }),
              }}
              primary={label}
            />
          </ListItem>
        </>
      ) : (
        ""
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
