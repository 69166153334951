import { makeStyles } from "@material-ui/styles";

import chat_image from "./img/modal.jpg";
const drawerWidth = 600;

export default makeStyles((theme) => ({
  listItem: {
    // border: "1px solid blue",
    display: "flex",
    justifyContent: "end",
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    // "&:hover ~ $listItemSecondaryAction": {
    //     "&:hover $listItemSecondaryAction": {
    //         border: "1px solid green",
    //         visibility: "inherit"
    //     }
    // }
  },
  table: {
    minWidth: 650,
  },

  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: "71%",
    left: "15%",
    right: "15%",
    [theme.breakpoints.down("sm")]: {
      left: "5%",
      right: "5%",
      width: "90%",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "40px",
    justifyContent: "flex-start",
    background: "#f0f2f5",
  },
  drawerClose: {
    display: "flex",
    justifyContent: "end",
    gap: "10px",
  },
  profiledrawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  searchdrawerPaper: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "75%",
    },
  },

  profiledrawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    background: "#f0f2f5",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    background: "#f0f2f5",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
  },
  contentShiftInviteScreen: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    background: "#f0f2f5",
    borderRadius: "0px 8px 8px 0px",
    position: 'relative'
    // border: '1px solid #e0e0e0',
  },
  contentShiftInviteScreenMobile: {
    height: "74vh",
    overflowY: "scroll",
  },
  contentShiftInviteFullScreenMobile: {
    display: "flex",
  },
  InviteScreenTextConatiner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    margin: "0px 15px",
    "& p": {
      [theme.breakpoints.up("lg")]: {
        padding: "0px 100px"
      },
    }
  },
  input: {
    display: "none",
  },

  download: {
    padding: "3px",
    stroke: "#949696",
    strokeWidth: "1px",
    color: "#949696",
    border: "2px solid #949696",
    borderRadius: "50px",
    fontWeight: "bold",
  },

  userName: {
    textAlign: "left",
    textTransform: "capitalize",
    fontWeight: "bold",
  },

  contactName: {
    textAlign: "left",
  },

  contactNumber: {
    textTransform: "capitalize",
    fontWeight: "bold",
  },

  time: {
    textAlign: "end",
    whiteSpace: "nowrap",
    "& p": {
      fontSize: "11px",
      textTransform: "capitalize",
    },
  },

  descriptionIcon: {
    color: "blue",
  },

  chatListBackGround: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    backgroundImage: "url(" + chat_image + ")",
  },

  sendIcon: {
    fontSize: "18px",
    margin: "5px",
  },
  tertiary: {
    // backgroundColor:"#064be2",
    background: "#2196f3",
    color: "white",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#053db7",
    },
  },
  sendIconBtn: {
    margin: "30px 15px",
    color: "white",
    // backgroundColor:"#064be2",
    background: "#2196f3",
    "&:hover": {
      color: "white !important",
      // backgroundColor:"#064be2",
      background: "#2196f3 !important",
    },
  },
  userCaptionsContainer: {
    display: "flex",
    gap: "4px",
  },
  userCaption: {
    // alignItems: "center",
    // display: "flex",
    // gap: "2px",
    textTransform: "capitalize",
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    textAlign: "left !important",
    width: "140px",
    "& svg": {
      width: "17px",
    },
  },
  unreadMessageCount: {
    color: '#fff',
    backgroundColor: '#148c7e',
    borderRadius: "50%",
    fontSize: "12px",
    lineHeight: "12px",
    textAlign: 'center',
    width: "18px",
    height: "18px",
    aspectRatio: 1,
    display: 'grid',
    placeItems: 'center',
  },
  userCaptionContainer: {
    textAlign: "left",
  },

  paperContainer: {
    padding: "10px",
  },
  searchOutLine: {
    padding: "7px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  searchBg: {
    backgroundColor: "#128C7E",
    color: "#fff",
    border: "none",
    borderRadius: "0px 5px 5px 0px !important",
    "&:hover": {
      backgroundColor: "#128C7E",
      color: "#fff",
    },
  },
  listItemIcon: {
    alignItems: "center",
    cursor: "pointer",
  },
  avatarTitle: {
    paddingLeft: "10px",
    color: "#FFFAFA",
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "5px",
    },
  },

  center: {
    textAlign: "center",
  },

  blueTick: {
    textAlign: "end",
    color: "#aab8c2",
    fontSize: "11px",
  },

  // messageStatus: {
  //     alignItems: "center"
  // },

  messageAreaSingle: {
    height: "100vh",
    overflowY: "scroll",
    backgroundImage: "url(" + chat_image + ")",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      overflowX: "hidden",
    },
  },
  chatListMessage: {
    display: "flex ",
    alignItems: "center ",
    justifyContent: "center",
    padding: "10px",
    color: " white",
    background: "#128C7E",
    borderRadius: "50px",
    width: "10px",
    height: "10px",
  },
  mainContent: {
    // background: '#00a884',
    // height: '15%',
    // backgroundColor:'#d3d3d3c9',
    // border: '1px solid #919eab',
    // padding: '8px',
    // margin: '5px',
    borderRadius: "8px",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  },
  leftSide: {
    height: "65vh",
  },
  fullScreenLeftSide: {
    height: "78vh",
  },
  list: {
    backgroundColor: "#128C7E",
    display: "flex ",
    padding: "5px 0px",
    // borderRadius: ' 0px 15px 0px 0px',
    boxShadow: "10px 20px 20px 5px #2f3c4a14 !important",
    borderRadius: "0px 8px 0px 0px",
    // boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
  },
  foot: {
    background: "#fff",
    padding: "10px",
    borderRadius: "0px 0px 8px 0px",
  },
  modalSize: {
    "& div": {
      "& div": {
        boxShadow: "none !important",
        background: "none !important",
      },
    },
  },
  messageRight: {
    padding: "0 58px 15px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0 40px 20px 0",
    },

    "&:hover": {
      "& $expandMoreLeft": {
        display: "block",
      },
    },
  },
  messageRightBubble: {
    background: "#d9fdd3",
    fontSize: "14px",
    padding: "12px 13px",
    borderRadius: "15px 0px 15px 15px",
    color: "black",
    position: "relative",
    float: "right",
    width: "30%",
    lineBreak: "anywhere",
    [theme.breakpoints.down("md")]: {
      padding: "12px 13px",
      float: "right",
      borderRadius: "15px 0px 15px 15px",
      background: "#d9fdd3",
      width: "85%",
    },
    "& img": {
      objectFit: "cover",
      height: "250px",
    },
    "& video": {
      height: "200px",
    },

    "&:hover": {},
  },

  replyMessage: {},
  messageRightBubbles: {
    padding: "12px 13px",
    float: "right",
    borderRadius: "15px 0px 15px 15px",
    background: "#d9fdd3",
    color: "black",
    minWidth: "40%",
    maxWidth: "50%",
    lineBreak: "anywhere",
    [theme.breakpoints.down("md")]: {
      padding: "12px 13px",
      float: "right",
      borderRadius: "15px 0px 15px 15px",
      background: "#d9fdd3",
      width: "90% ",
    },
    "& $replyMessage": {
      padding: "10px",
      borderRight: "2px solid blue",
      background: "#d9fdd3",
      borderRadius: "10px 1px 1px 10px",
      "& img": {
        objectFit: "cover",
        height: "250px",
      },
      "& video": {
        height: "200px",
      },
    },
    "& img": {
      objectFit: "cover",
      height: "250px",
    },
    "& video": {
      height: "200px",
    },
  },

  messageRightImage: {
    background: "#d9fdd3",
    fontSize: "14px",
    fontWeight: "600",
    padding: "12px 13px",
    borderRadius: "15px 0px 15px 15px",
    color: "black",
    position: "relative",
    float: "right",
    width: "53%",
    [theme.breakpoints.down("md")]: {
      padding: "12px 13px",
      float: "right",
      borderRadius: "15px 0px 15px 15px",
      background: "#d9fdd3",
      width: "100%",
    },
    "& $replyMessage": {
      padding: "10px",
      borderRight: "2px solid blue",
      background: "#d9fdd3",
      borderRadius: "10px 1px 1px 10px",
      "& img": {
        objectFit: "cover",
        height: "250px",
      },
      "& video": {
        height: "200px",
      },
    },
  },
  messageRightFile: {
    background: "#d9fdd3",
    fontSize: "14px",
    fontWeight: "600",
    padding: "12px 13px",
    borderRadius: "15px 0px 15px 15px",
    color: "black",
    position: "relative",
    float: "right",
    width: "60%",
    [theme.breakpoints.down("md")]: {
      padding: "12px 13px",
      float: "right",
      borderRadius: "15px 0px 15px 15px",
      background: "#d9fdd3",
      width: "100%",
    },
  },
  photoLibrary: {
    cursor: "pointer",
    padding: "10px",
  },

  nothing: {
    display: "none",
  },
  backdrop: {
    zIndex: 1500,
    color: "#fff",
  },
  top: {
    background: "#f0f2f5",
    padding: "7px",
    borderRight: "1px solid #e0e0e0",
  },
  topSvg: {
    color: "#54656f",
  },
  chatList: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: "10px",
    columnGap: "10px",
    "& $space": {
      padding: "0px 10px",
      gap: "10px",
    },
    "&:hover": {
      // backgroundColor:'#CDF5FD',
      borderRadius: "4px",
      backgroundColor: "#e3fae7",
      cursor: "pointer",
    },
  },
  ChatListName: {
    justifyContent: "space-between",
    display: "flex",
    width: "100% ",
    // textAlign: "left"
  },

  ChatListContent: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    color: "#969b9f",
    textTransform: "initial",
  },
  fullWidth: {
    width: "100%",
  },

  a: {
    textDecoration: "none",
    cursor: "pointer",
  },
  tablist: {
    padding: "10px",
    display: "flex",
  },
  main: {
    // top: "40px",
    // width: "calc(100% - 18px)",
    maxWidth: "1600px",
    position: "relative",
    margin: "0 auto",
    borderRadius: "8px",
    boxShadow: "10px 20px 20px 5px #2f3c4a14 !important",
    background: "white",
    "&:after": {
      background: "#00a884",
    },
    [theme.breakpoints.down("md")]: {
      top: "20px !important",
      width: "calc(100%) !important",
    },
  },
  inputFocus: {
    border: "1px solid #128C7E",
    backgroundColor: "#f0f2f5",
    borderRadius: "8px 0px 0px 8px",
  },
  messageScroll: {
    height: "57vh",
    overflowY: "scroll",
    backgroundImage: "url(" + chat_image + ")",
  },
  messagearea: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      overflowX: "hidden",
    },
  },
  title: {
    color: "black",
    textTransform: "capitalize ",
  },
  modalImage: {
    width: "500px",
  },
  tabPaper: {
    display: "flex ",
    alignItems: "center",
    width: "100%",
    background: "#f0f2f5 ",
    // border:'1px solid #f0f2f5',
    boxShadow: "none ",
    borderRadius: "8px",
  },
  paper: {
    display: "flex ",
    width: "100%",
    background: "#f0f2f5 ",
    boxShadow: "none ",
    borderRadius: "8px",
    // border: "2px solid #f0f2f5"
  },
  message: {
    width: "50%",
    padding: "0 0 20px 58px",
    clear: "both",
    marginBottom: "20px",
    "&:after": {
      background: "#00a884",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "&:hover": {
      "& $expandMoreRight": {
        display: "block",
      },
    },
  },
  chatListAvatar: {
    marginRight: "10px",
    width: "40px !important",
    height: "40px !important",
  },

  messageAvatar: {
    float: "left",
    marginLeft: "-38px",
    borderRadius: "50%",
    width: "30px",
    marginTop: "0px",
    height: "30px",
    color: "#d9fdd3",
  },
  messageRightAvatar: {
    float: "right",
    marginLeft: "0",
    marginRight: "-38px",
    height: "30px",
    color: "white",
  },

  expandMoreLeft: {
    position: "absolute",
    right: 65,
    padding: "10px 10px 0px 10px",
    background: "linear-gradient(45deg, #d9fdd3, #d9fdd3)",
    zIndex: 1,
    cursor: "pointer",
    display: "none",
  },
  expandMoreRight: {
    position: "absolute",
    right: 10,
    //  padding: "10px 10px 0px 10px",
    background: "linear-gradient(45deg, #ffff, #ffff)",
    zIndex: 1,
    cursor: "pointer",
    display: "none",
  },

  // messageRightChecbox: {
  //     float: "right",
  //     height: "30px",
  //     marginRight: "-45px",

  // },
  messageRightSend: {
    float: "right",
    marginTop: "200px",
    height: "30px",
    color: "white",
  },

  bubbleSpan: {
    color: "#aab8c2",
    fontSize: "11px",
    marginTop: "10px",
  },

  "::-webkit-scrollbar": {
    width: "6px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#bab4b4",
  },
  contactprofile: {
    display: "flex",
    justifyContent: "center",
  },
  contactprofileImg: {
    height: "200px",
    width: "200px",
    cursor: "pointer",
    borderRadius: "200px",
  },
  contactDetails: {
    display: "contents",
    textAlign: "center",
    paddingBottom: "10px",
    "& $chip": {
      display: "flex",
      width: "100%",
      gap: "5px",
      padding: "10px 50px",
      textAlign: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        display: "flex !important",
        flexWrap: "wrap",
        padding: 10,
      },
    },
  },
  editProfile: {
    display: "flex",
    justifyContent: "center",
  },
  editProfileImg: {
    height: "200px",
    width: "200px",
    cursor: "pointer",
    borderRadius: "200px",
  },
  Editdetails: {
    marginBottom: "25px ",
    backgroundColor: "white",
    color: "black",
    fontWeight: 400,
    fontSize: "21px",
    paddingLeft: "20px ",
  },
  body: { background: "#f0f2f5" },

  login: {
    padding: "10% ",
    textAlign: "-webkit-center",
    placeSelf: "center",
  },
  sendHere: {
    [theme.breakpoints.down("md")]: {
      fontSize: "21px",
      padding: "4px",
    },
  },
  space: {
    display: "flex",
    //   whiteSpace: "nowrap",
    //  gap: "10px",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  button: {
    minWidth: "10px !important",
    boxShadow: "none !important",
  },
  borderNone: {
    border: "none",
  },
  blue: {
    margin: "4px 0px 0px 4px",
    backgroundColor: "white",
    color: "#00a884",
    fontSize: "14px",
  },
  blueIcon: {
    // backgroundColor: "white",
    color: "#bdbdbd",
    fontSize: "14px",
    // "&:hover": {
    //   backgroundColor: "#00a884",
    //   color: "white",
    // }
  },
  backIcon: {
    color: "#00a884",
    fontSize: "24px",
  },
  lgButton: {
    display: "none !important",
    [theme.breakpoints.up("sm")]: {
      display: "block !important",
    },
  },
  smButton: {
    display: "block !important",
    [theme.breakpoints.up("sm")]: {
      display: "none !important",
    },
  },
  customTextField: {
    "& input:placeholder": {
      fontSize: "13px",
    },
    marginTop: "5px !important",
    border: "1px solid #edecec",
  },

  messagebubble: {
    background: "white",
    fontSize: "14px",
    fontWeight: 400,
    padding: "12px 13px",
    borderRadius: "0px 15px 15px 15px",
    color: "black",
    position: "relative",
    float: "left",
    width: "70%", //53%
    lineBreak: "anywhere",
    [theme.breakpoints.down("md")]: {
      width: "100% ",
    },

    "& $replyMessage": {
      padding: "10px",
      borderRight: "2px solid blue",
      background: "#d9fdd3",
      borderRadius: "10px 1px 1px 10px",
      "& img": {
        objectFit: "cover",
        height: "250px",
      },
      "& video": {
        height: "200px",
      },
    },
  },
  messagebubbles: {
    background: "white",
    fontSize: "14px",
    fontWeight: 400,
    padding: "12px 13px",
    borderRadius: "0px 15px 15px 15px",
    color: "black",
    position: "relative",
    float: "left",
    width: "100%",
    lineBreak: "anywhere",
    "& $replyMessage": {
      padding: "10px",
      borderRight: "2px solid blue",
      background: "#d9fdd3",
      borderRadius: "10px 1px 1px 10px",
      "& img": {
        objectFit: "cover",
        height: "250px",
      },
      "& video": {
        height: "200px",
      },
    },
  },
  gap10: {
    display: "flex",
    gap: "10px",
  },

  tagdrawerPaper: {
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "50% !important",
    },
  },

  drawerContent: {
    padding: "10px",
  },
  drawerFooter: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1) !important",
    position: "fixed !important",
    bottom: "0 !important",
    width: "30%",
    justifyContent: "center !important",
    padding: "10px",
    background: "white",
    zIndex: "120",
    margin: "0px !important",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      width: "50% !important",
    },

    [theme.breakpoints.only("xs")]: {
      position: "fixed  !important",
    },
  },

  drawerSearchFooter: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1) !important",
    position: "fixed !important",
    bottom: "0 !important",
    width: "50%",
    justifyContent: "center !important",
    padding: "10px",
    background: "white",
    zIndex: "120",
    margin: "0px !important",
    gap: "10px",
    [theme.breakpoints.only("sm")]: {
      width: "60% !important",
    },
    [theme.breakpoints.only("xs")]: {
      width: "80% !important",
      position: "fixed  !important",
    },
  },

  auto: {
    "& fieldset": {
      border: "none !important",
    },
  },
  listGridItem: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    width: "70%",

    [theme.breakpoints.only("xs")]: {
      gap: "0px",
      width: "100%",
    },
  },
  chip: {
    display: "flex",
    //  width: "100%",
    // overflow: "scroll",
    gap: "5px",
    padding: "10px 0px",
    [theme.breakpoints.only("sm")]: {
      display: "none !important",
    },
    [theme.breakpoints.only("xs")]: {
      display: "none !important",
    },
  },

  failedMessage: {
    marginTop: "6px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },

  dropdown: {
    outline: 0,
    position: "absolute",
    overflowX: "hidden",
    overflowY: "auto",
    right: "2%",
    top: "80%",
    minWidth: "10rem",
    zIndex: "999",
    backgroundColor: "white",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    [theme.breakpoints.down("sm")]: {
      minWidth: "9rem",
      position: "fixed",
      top: "unset",
      right: "20px",
    },
  },
  profileMenuIcon: {
    cursor: "pointer",
    margin: "15px 0px",
    color: theme.palette.text.primary,
    paddingLeft: 10,
    paddingRight: 5,
  },
  messageRightBtnFooter: {
    padding: "12px 13px",
    float: "right",
    borderRadius: "15px 0px 15px 15px",
    color: "black",
    width: "50%",
    // minWidth:'40%',
    // maxWidth: '50%',
    lineBreak: "anywhere",
    [theme.breakpoints.down("md")]: {
      padding: "12px 13px",
      float: "right",
      borderRadius: "15px 0px 15px 15px",
      width: "90% ",
    },
  },
  messageRightBtnFooterContainer: {
    padding: "0 58px 15px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0 40px 20px 0",
    },

    "&:hover": {
      "& $expandMoreLeft": {
        display: "block",
      },
    },
  },
}));
