import * as Yup from 'yup';

export const registerSchema = Yup.object().shape({
  email: Yup
    .string()
    .email("Please enter vailed email")
    .required("Email is required"),
  password: Yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  firstName: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
    .required("First name is required"),
  lastName: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid last  name")
    .required("Last name is required"),
  mobile: Yup.number().required('Mobile is required'),
  address: Yup.string(),
  captcha: Yup.string().required('Captcha is required'),

});

export const registerWithoutSchema = Yup.object().shape({
  email: Yup
    .string()
    .email("Please enter vailed email")
    .required("Email is required"),
  password: Yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  firstName: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
    .required("First name is required"),
  lastName: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid last  name")
    .required("Last name is required"),
  address: Yup.string(),
  captcha: Yup.string().required('Captcha is required'),

});

export const loginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email must be a Valid Email Address'),
  password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  captcha: Yup.string().required('Captcha is required')
});

export const forgetSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email must be a Valid Email Address'),
});


export const forgetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  confirm: Yup.string().required('Confirm Password is required').min(8, 'Confirm Password must be at least 8 characters'),
});


export const changePasswordSchema = Yup.object().shape({
  old: Yup.string().required('Old Password is required').min(8, 'Old Password must be at least 8 characters'),
  password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  confirm: Yup.string().required('Confirm Password is required').min(8, 'Confirm Password must be at least 8 characters'),
});


export const ProfileSchema = Yup.object().shape({
  fbBaseUrl: Yup.string().required("FB Base Url is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  waToken: Yup.string().required("waToken is required"),
  waId: Yup.string().required("waId is required"),
});


export const groupSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
});


export const contactSchema = Yup.object().shape({
  email: Yup
    .string()
    .email("Please enter vailed email"),
  name: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
    .required("First name is required"),
  mobile: Yup.number().required('Mobile is required'),
});

export const contactWithoutSchema = Yup.object().shape({
  email: Yup
    .string()
    .email("Please enter vailed email"),
  name: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
    .required("First name is required"),
});

export const groupContactSchema = Yup.object().shape({
  group_id: Yup.string().required('Select group'),
  contact_id: Yup.array().required('Select gontact'),
});

export const groupContactwithoutSchema = Yup.object().shape({
  group_id: Yup.string().required('Select Group'),
});


export const instanceSchema = Yup.object().shape({
  phoneNumberId: Yup.string().required('Enter phone Number Id'),
  accessToken: Yup.string().required('Enter access token'),
  waBusinessId: Yup.string().required('Enter whatsApp business account ID'),
});

export const campaignSchema = Yup.object().shape({
  instance: Yup.string().required('Select instance '),
  campaign_name: Yup.string().required('Enter campaign name '),
  group_id: Yup.string().required('Select group name'),
  template_id: Yup.string().required('Select template name'),
  vars: Yup.string(),
  header: Yup.string().required('Enter Header'),
});

export const campaignwithioutSchema = Yup.object().shape({
  instance: Yup.string().required('Select instance'),
  campaign_name: Yup.string().required('Enter campaign name '),
  group_id: Yup.string().required('Select group name'),
  template_id: Yup.string().required('Select template name'),
  vars: Yup.string(),
});



export const campaignTagSchema = Yup.object().shape({
  instance: Yup.string().required('Select instance '),
  campaign_name: Yup.string().required('Enter campaign name '),
  tag_id: Yup.string().required('Select tag name'),
  template_id: Yup.string().required('Select template name'),
  vars: Yup.string(),
  header: Yup.string().required('Enter Header'),
});

export const campaignTagwithioutSchema = Yup.object().shape({
  instance: Yup.string().required('Select instance'),
  campaign_name: Yup.string().required('Enter campaign name '),
  tag_id: Yup.string().required('Select tag name'),
  template_id: Yup.string().required('Select template name'),
  vars: Yup.string(),
});

export const transactionSchema = Yup.object().shape({
  user_id: Yup.string().required('Select user'),
  instance_id: Yup.string().required('Enter instanceId'),
  plan_id: Yup.string().required('Select plan'),

});


export const sendTemplateSchema = Yup.object().shape({
  template_name: Yup.string().required('Select template name'),
  parameters: Yup.string(),
  mobile_number: Yup.string().required('Enter mobile number'),
  language_code: Yup.string(),
  header: Yup.string().required('Enter Header'),
});

export const sendTemplatewithioutSchema = Yup.object().shape({
  template_name: Yup.string().required('Select template name'),
  parameters: Yup.string(),
  mobile_number: Yup.string().required('Enter mobile number'),

  language_code: Yup.string(),
});


export const sendTextMessageSchema = Yup.object().shape({
  mobile_number: Yup.string().required('Enter mobile number'),
  message: Yup.string().required('Enter Message'),
});

export const sendAudioMessageSchema = Yup.object().shape({
  mobile_number: Yup.string().required('Enter mobile number'),
  file_path: Yup.string().required('Choose file'),
});


export const sendDocumentMessageSchema = Yup.object().shape({
  mobile_number: Yup.string().required('Enter mobile number'),
  file_path: Yup.string().required('Choose file'),
});

export const sendImageMessageSchema = Yup.object().shape({
  mobile_number: Yup.string().required('Enter mobile number'),
  file_path: Yup.string().required('Choose file'),
});

export const sendVideoMessageSchema = Yup.object().shape({
  mobile_number: Yup.string().required('Enter mobile number'),
  file_path: Yup.string().required('Choose file'),
});

export const sendContactMessageSchema = Yup.object().shape({
  mobile_number: Yup.string().required('Enter mobile number'),
  contact_number: Yup.string().required('Enter contact number'),
  first_name: Yup.string(),
  last_name: Yup.string(),
});

export const sendLocationMessageSchema = Yup.object().shape({
  mobile_number: Yup.string().required('Enter mobile number'),
  latitude: Yup.string().required('Enter latitude'),
  longitude: Yup.string().required('Enter longitude'),
  address: Yup.string().required('Enter address'),
});



export const updateProfileSchema = Yup.object().shape({
  firstName: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
    .required("First name is required"),
  lastName: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid last  name")
    .required("Last name is required"),
  mobile: Yup.number().required('Mobile is required'),
  address: Yup.string(),

});


export const linkGenerateSchema = Yup.object().shape({
  to: Yup.number().required('Mobile is required'),
  message: Yup.string().required('Message is required'),

});


export const tagSchema = Yup.object().shape({
  tag_name: Yup.string().required('Tag name is required'),
});

export const replySchema = Yup.object().shape({
  message: Yup.string().when('autoReply.message', {
    is: true, // Only required if message type is custom
    then: Yup.string().required('Custom message is required'),
  }),
});



export const subUsersSchema = Yup.object().shape({
  email: Yup
    .string()
    .email("Please enter vailed email")
    .required("Email is required"),
  password: Yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  firstname: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
    .required("First name is required"),
  lastname: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid last  name")
    .required("Last name is required"),
  mobile: Yup.number().required('Mobile is required'),

});

export const subUsersWithoutSchema = Yup.object().shape({
  email: Yup
    .string()
    .email("Please enter vailed email")
    .required("Email is required"),
  password: Yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  firstname: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
    .required("First name is required"),
  lastname: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid last  name")
    .required("Last name is required"),

});


export const subUsersEditSchema = Yup.object().shape({
  email: Yup
    .string()
    .email("Please enter vailed email")
    .required("Email is required"),
  firstname: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid first name")
    .required("First name is required"),
  lastname: Yup
    .string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid last  name")
    .required("Last name is required"),

}); 