import React, { useState, useEffect, useReducer, useRef } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Button, 
   Backdrop,
  CircularProgress, 
  TextField,
  TablePagination
} from "@material-ui/core";
 import PageTitle from "../../components/PageTitle"; 
 import useStyles from "./style.js";
import useGlobalStyles from "../../themes/style";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getReferalTransactions } from '../../services/transaction.api';
 import "react-toastify/dist/ReactToastify.css";


export default function Transaction(props) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  var moment = require('moment-timezone');

  const token = localStorage.getItem("webWAInfoapto_PX11925");  
  const mobileQuery = useMediaQuery('(max-width:600px)');   
   const [transactionData, setTransactionData] = useState([]); 
 

  const [page, setPage] = useState(0);
   const [currerntPage, setCurrerntPage] = useState(1);
  const [reducerValue, forceUpdate ] = useReducer((x) => x + 1, 0);
  const filterRef = useRef(null);

 const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(false);
 
  const HeaderElements = () => <>Total : {count}</>;
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    
    const form = filterRef.current;
    
    getReferalTransactions({
      page: newPage + 1,  
      fromDate: `${form["fromDate"].value}`,
      toDate: `${form["toDate"].value}`,
      token: token
    } ).then((response) => {    
      if (response.data.status === true) { 
        
        setTransactionData(response.data.data);
           setCount(response.data.count);
           setLoader(false);

      }  else{
        setCount(0);
        setTransactionData([]);
       setLoader(false);
     }
     setPage(newPage);
    setCurrerntPage(newPage + 1);
    }) 
    .catch(function (error) {
     console.log(error);
   })

  };


  
  const handleFromDateChange = (event) => {
    setFromDate(filterRef.current.fromDate.value);
  };

  const handleToDateChange = (event) => {
    setToDate(filterRef.current.toDate.value);
  };

  function getFilterData() {
    setLoader(true);
     
    const form = filterRef.current;

    
 
    getReferalTransactions({
      page: 1, 
      fromDate: `${form["fromDate"].value}`,
      toDate: `${form["toDate"].value}`,
      token: token
    }).then((response) => {    
        if (response.data.status === true) { 
       
          setLoader(false);
          setTransactionData(response.data.data);
         
            }  else{
              setCount(0);
              setTransactionData([]);
             setLoader(false);
           }
           
           
          }) 
            .catch(function (error) {
             console.log(error);
           })
           
  }
 
const resetForm = (e) => {
    filterRef.current.reset(); 
    forceUpdate();
  };

 

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
       
      getReferalTransactions({  page: 1,  token: token }).then((response) => {    
         if (response.data.status === true) { 
          setCount(response.data.count);
           setTransactionData(response.data.data);
           setLoader(false);  
         } else {
          setCount(0);
          setTransactionData([]);
         setLoader(false);
         }
        
        
       }) 
       .catch(function (error) {
        console.log(error);
      })
 
    };
    fetchData();
       
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reducerValue]);
  
 
   
  
 
  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
          
          <PageTitle title="Referral Transaction" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={classes.drawerClose}>
          
        </Grid>
      </Grid>
 
      <form
        ref={filterRef}
        onSubmit={(e) => {
          e.preventDefault();
          getFilterData();
        }}
      >
        <Grid container spacing={2} className={globalClasses.filterGap}>
         
        

          <TextField
            name="fromDate"
           label="From"
            InputLabelProps={{ shrink: true }}
            classes={{ root: globalClasses.filterFullWidth }}
            type="date"
            defaultValue={fromDate}
            onChange={handleFromDateChange}
          />

          <TextField
            name="toDate"
           label="To"
            InputLabelProps={{ shrink: true }}
            classes={{ root: globalClasses.filterFullWidth }}
            type="date"
            defaultValue={toDate}
            onChange={handleToDateChange}
          />

          <div className={globalClasses.buttons}>
            <Button
              variant="contained"
              size="small"
              color="primary" 
              type="submit"
            >
              Search
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => resetForm()}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </form>
      

      <Grid container spacing={2}>
        <Grid item xs={12}>
        <MUIDataTable
            title={""}
            options={{
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              },
              pagination: false,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
           customToolbar: () => <HeaderElements />,
              responsive: mobileQuery===true? 'vertical' : 'standard',
            }}
            columns={[
              {
                name: "S.No",
              },
              {
                name: "User Name",
              },
              {
                name: "Invoice Number",
              }, 
              
              {
                name: "Amount",
              },
               
              {
                name: "Invoice Date",
              },
            ]}
            data={transactionData?.map((item, index) => {
              return [
                currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 , 
                item.user_name, 
                item.invoice_number, 
                item.basic_amount, 
                item?.created? moment(item?.created).tz(moment.tz.guess()).format("DD-MM-YYYY") :"",
              ];
            })}
          />

          <Grid container spacing={2} className={globalClasses.pagination}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={count}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      </Grid>


     

      <Backdrop className={globalClasses.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

