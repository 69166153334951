import React, { useState, useEffect } from "react";
import {
  Grid,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import {
  FcNews,
} from "react-icons/fc";
import useStyles from "./style";
import globalStyles from "../../themes/style.js";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  viewAuthentication,
} from "../../services/instance.api";

import "react-toastify/dist/ReactToastify.css";
import ApiExpandedItem from "./ApiExpandedItem.js";

function ApiData(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();

  const token = localStorage.getItem("webWAInfoapto_PX11925");
  const user = JSON.parse(localStorage.getItem("user"));

  const [loader, setLoader] = useState(false);
  const [instanceData, setInstanceData] = useState([]);

  const data = [
    {
      title: `${process.env.REACT_APP_SERVER}messages/sendTemplate`,
      message: `
      { 
        <br/>
        &emsp;  "template_id": "", <br/>
        &emsp;  "template_name": "", <br/>
        &emsp;  "parameters": "", <br/>
        &emsp;  "mobile_number": "91XXXXXXXXXX", <br/>
        &emsp;  "language_code": "en_US", <br/>
        &emsp;  "message": "", <br/>
        &emsp;  "instance_id": "", <br/>
        &emsp;  "headerType": "", <br/>
        &emsp;  "fileLink": "", <br/>
        &emsp;  "user_id": "" ,<br/>
        &emsp;  "token":  " ${instanceData?.token} "
        <br/>
      }
    `,
      customIcon: <FcNews style={{fontSize: "30px" }} />,
      backgroundColor: "#A6CF98",
    },
    
    // Add more data as needed
  ];


  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      viewAuthentication({userId: user?.userId, token: token}).then((response) => {    
        if (response.data.status === true) {  
        setInstanceData(response.data.data); 
        }  
        setLoader(false);
      }) 
      .catch(function (error) {
       console.log(error);
     })
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
          <PageTitle title="Api Data" />
        </Grid>
      </Grid>

      {/* <Grid container spacing={2}>
        {data.map((row, index) => (
          <Grid item xs={12} lg={6}>
            <ExpandableRow key={index} row={row} />
          </Grid>
        ))}
      </Grid> */}

      <Grid container spacing={2}>
      {data.map((row, index) => (
          <Grid item xs={12} lg={6}>
            <ApiExpandedItem key={index} row={row}/>
          </Grid>
          ))}
      </Grid>

      <Backdrop className={globalClasses.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default ApiData;
