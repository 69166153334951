import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Button,
  Backdrop,
  CircularProgress,
  Link,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";
import useStyles from "./style";
import globalStyles from "../../themes/style.js";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import {
  sendTemplateSchema,
  sendTemplatewithioutSchema,
  sendTextMessageSchema,
  sendAudioMessageSchema,
  sendDocumentMessageSchema,
  sendImageMessageSchema,
  sendVideoMessageSchema,
  sendContactMessageSchema,
  sendLocationMessageSchema,
} from "../../components/Yup/Yup";
import {
  sendTemplateMessage,
  sendTextMessage,
  sendAudioMessage,
  sendDocumentMessage,
  sendImageMessage,
  sendVideoMessage,
  sendContactMessage,
  sendLocationMessage,
} from "../../services/message.api";
import { uploadFile } from "../../services/instance.api";
import { contactList } from "../../services/contact.api";

import {
  viewApprovedTemplates,
  viewTemplate,
} from "../../services/template.api";
import { useFormik } from "formik";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Autocomplete } from "@material-ui/lab";
import {
  FcVoicePresentation,
  FcNews,
  FcGallery,
  FcAudioFile,
  FcVideoFile,
  FcDocument,
  FcBusinessContact,
} from "react-icons/fc";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";

import {useLocation, useHistory } from "react-router-dom";
import InstanceContext from "../../context/InstanceContext.js";

function SendMessage() {
  const classes = useStyles();
  const messageRef = useRef();
  const globalClasses = globalStyles();
  var token = localStorage.getItem("webWAInfoapto_PX11925");
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const mobileNumberparam = queryParams.get("mobile");
  const { instanceId, isInstance } = useContext(InstanceContext)
  const [fileData, setFileData] = React.useState({
    image: [],
    audio: [],
    video: [],
    document: [],
    imageUrl: "",
  });

  const [searchNumbers, setSearchNumbers] = React.useState([]);

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  function handleChange(event, name) {
    if (
      name === "image" &&
      event.target.files[0]?.size < 5000000 &&
      (event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png")
    ) {
      setFileData({
        ...fileData,
        image: name === "image" ? event.target.files[0] : "",
        imageUrl:
          name === "image" ? URL.createObjectURL(event.target.files[0]) : "",
      });
    } else if (
      name === "audio" &&
      event.target.files[0]?.size < 16000000 &&
      (event.target.files[0].type === "audio/aac" ||
        event.target.files[0].type === "audio/mp4" ||
        event.target.files[0].type === "audio/mpeg" ||
        event.target.files[0].type === "audio/amr" ||
        event.target.files[0].type === "audio/ogg")
    ) {
      setFileData({
        ...fileData,
        audio: name === "audio" ? event.target.files[0] : "",
      });
    } else if (
      name === "video" &&
      event.target.files[0]?.size < 16000000 &&
      (event.target.files[0].type === "video/mp4" ||
        event.target.files[0].type === "video/3gp")
    ) {
      setFileData({
        ...fileData,
        video: name === "video" ? event.target.files[0] : "",
      });
    } else if (
      name === "document" &&
      event.target.files[0]?.size < 100000000 &&
      (event.target.files[0].type === "text/plain" ||
        event.target.files[0].type === "application/pdf" ||
        event.target.files[0].type === "application/vnd.ms-powerpoint" ||
        event.target.files[0].type === "application/msword" ||
        event.target.files[0].type === "application/vnd.ms-excel" ||
        event.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        event.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        event.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setFileData({
        ...fileData,
        document: name === "document" ? event.target.files[0] : "",
      });
    } else {
      handleNotificationCall(
        "error",
        "Please check your uploaded file format and size",
      );
    }
  }
  const [value, setValue] = React.useState(0);
  const [contactData, setContactData] = React.useState([]);
  const [mobile, setMobile] = useState("");

  useEffect(() => {
    setMobile(mobileNumberparam);
  }, [mobileNumberparam]);

  const handleChangeTab = (value) => {
    setValue(value);
    setFileData({});
    setMessageData({});
    setTemplateButton([]);
    setTemplateValue(null);
    onSendTemplate.resetForm();
    onSendTextMessage.resetForm();
    onSendAudio.resetForm();
    onSendVideo.resetForm();
    onSendImage.resetForm();
    onSendLocation.resetForm();
    onSendContact.resetForm();
    onSendDocument.resetForm();
    onSendVideo.resetForm();

    setSearchNumbers([]);

    if (mobile) {
      onSendTemplate.setValues({
        mobile_number: mobile,
      });

      onSendTextMessage.setValues({
        mobile_number: mobile,
      });

      onSendImage.setValues({
        mobile_number: mobile,
      });

      onSendAudio.setValues({
        mobile_number: mobile,
      });

      onSendVideo.setValues({
        mobile_number: mobile,
      });

      onSendDocument.setValues({
        mobile_number: mobile,
      });

      onSendContact.setValues({
        mobile_number: mobile,
      });

      onSendLocation.setValues({
        mobile_number: mobile,
      });
    }
  };

  const [loader, setLoader] = useState(false);
  const [imageLoad, setImageLoad] = useState(null);

  const [templateData, setTemplateData] = React.useState([]);
  const [templateHeader, setTemplateHeader] = React.useState();
  const [templateButton, setTemplateButton] = React.useState([]);

  const [messageData, setMessageData] = React.useState({
    headerField: false,
    bodyField: false,
    //"footerField": false,
    // "buttonField": false,
    message: "",
  });

  // const [selectedLocation, setSelectedLocation] = useState({
  //   lat: parseFloat(-37.813179),
  //   lng: parseFloat(144.950259),
  // });

  // const BasicMap = withScriptjs(
  //   withGoogleMap(() => (
  //     <GoogleMap
  //       defaultZoom={12}

  //       defaultCenter={selectedLocation}
  //       onClick={(e)=>{

  //   setSelectedLocation({
  //     lat: e.latLng.lat(),
  //     lng: e.latLng.lng(),
  //   });

  // fetchAddress(e.latLng.lat(), e.latLng.lng());

  //       }}
  //     >
  //       {selectedLocation && (
  //           <Marker position={selectedLocation} />
  //         )}
  //     </GoogleMap>
  //   )),
  // );

  // const fetchAddress = (lat, lng) => {

  // const geocoder = new window.google.maps.Geocoder();
  // geocoder.geocode({ location: { lat, lng } }, (results, status) => {

  //   if (status === 'OK') {
  //     if (results[0]) {

  //       onSendLocation.setValues({
  //         "address":  results[0].formatted_address,
  //         "latitude": lat,
  //         "longitude": lng
  //        })
  //     } else {
  //       onSendLocation.setValues({
  //         "address": "",
  //         "latitude": lat,
  //         "longitude": lng
  //        })
  //     }
  //   } else {
  //     onSendLocation.setValues({
  //       "address":  "",
  //       "latitude": lat,
  //       "longitude": lng
  //      })
  //   }
  // });
  // }

  useEffect(() => {
    if (instanceId !== null && isInstance) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceId, isInstance]);

  const fetchData = async () => {
    viewApprovedTemplates({ instance_id: instanceId?.id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          setTemplateData(response.data?.data);
        }

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // useEffect(() => {
  //   setLoader(true);

  //   if (instanceId?.id === undefined) {
  //     viewAuthentication({ userId: user?.userId, token: token })
  //       .then((response) => {
  //         if (response.data.status === true) {
  //           viewApprovedTemplates({
  //             instance_id: response?.data?.data.id,
  //             token: token,
  //           })
  //             .then((result) => {
  //               if (result.data.status === true) {
  //                 setTemplateData(result.data?.data);
  //               }

  //               setLoader(false);
  //             })
  //             .catch(function (error) {
  //               console.log(error);
  //             });
  //         }
  //         setLoader(false);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   } else {
      
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  function getcontactList(mobile) {
    contactList({ mobile: mobile, token: token })
      .then((response) => {
        if (response.data.status === true) {
          setContactData(response.data?.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (mobile) {
      onSendTemplate.setValues({
        mobile_number: mobile,
      });

      onSendTextMessage.setValues({
        mobile_number: mobile,
      });

      onSendImage.setValues({
        mobile_number: mobile,
      });

      onSendAudio.setValues({
        mobile_number: mobile,
      });

      onSendVideo.setValues({
        mobile_number: mobile,
      });

      onSendDocument.setValues({
        mobile_number: mobile,
      });

      onSendContact.setValues({
        mobile_number: mobile,
      });

      onSendLocation.setValues({
        mobile_number: mobile,
      });

      //  history.push("/app/instance");
      // props.setValue(user?.role_code==="ADMIN"?2:1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobile]);

  const [templateValue, setTemplateValue] = React.useState(null);

  function handleSendTemplateImage(event, name) {
    var FormData = require("form-data");
    var data = new FormData();
    if (
      name === "image" &&
      event.target.files[0]?.size < 5000000 &&
      (event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png")
    ) {
      setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);

      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          setImageLoad(false);
          onSendTemplate.setValues({
            template: templateData.indexOf(onSendTemplate.values.template),
            mobile_number: onSendTemplate.values.mobile_number,
            template_name: onSendTemplate.values.template_name,
            language_code: onSendTemplate.values.language_code,
            header: response.data?.file_url,
            headerType: onSendTemplate.values.headerType,
            parameters: onSendTemplate.values.parameters,
            //"footer": onSendTemplate.values.footer,
            // "button": onSendTemplate.values.button,
          });
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);
        }

        setLoader(false);
      });
    } else if (
      name === "audio" &&
      event.target.files[0]?.size < 16000000 &&
      (event.target.files[0].type === "audio/aac" ||
        event.target.files[0].type === "audio/mp4" ||
        event.target.files[0].type === "audio/mpeg" ||
        event.target.files[0].type === "audio/amr" ||
        event.target.files[0].type === "audio/ogg")
    ) {
      setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          setImageLoad(false);
          onSendTemplate.setValues({
            template: templateData.indexOf(onSendTemplate.values.template),
            mobile_number: onSendTemplate.values.mobile_number,
            template_name: onSendTemplate.values.template_name,
            language_code: onSendTemplate.values.language_code,
            header: response.data?.file_url,
            headerType: onSendTemplate.values.headerType,
            parameters: onSendTemplate.values.parameters,
            // "footer": onSendTemplate.values.footer,
            //"button": onSendTemplate.values.button,
          });
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);
        }
        setLoader(false);
      });
    } else if (
      name === "video" &&
      event.target.files[0]?.size < 16000000 &&
      (event.target.files[0].type === "video/mp4" ||
        event.target.files[0].type === "video/3gp")
    ) {
      setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          setImageLoad(false);
          onSendTemplate.setValues({
            template: templateData.indexOf(onSendTemplate.values.template),
            mobile_number: onSendTemplate.values.mobile_number,
            template_name: onSendTemplate.values.template_name,
            language_code: onSendTemplate.values.language_code,
            header: response.data?.file_url,
            headerType: onSendTemplate.values.headerType,
            parameters: onSendTemplate.values.parameters,
            // "footer": onSendTemplate.values.footer,
            // "button": onSendTemplate.values.button,
          });
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);
        }
        setLoader(false);
      });
    } else if (
      name === "document" &&
      event.target.files[0]?.size < 100000000 &&
      (event.target.files[0].type === "text/plain" ||
        event.target.files[0].type === "application/pdf" ||
        event.target.files[0].type === "application/vnd.ms-powerpoint" ||
        event.target.files[0].type === "application/msword" ||
        event.target.files[0].type === "application/vnd.ms-excel" ||
        event.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        event.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        event.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          setImageLoad(false);
          onSendTemplate.setValues({
            template: templateData.indexOf(onSendTemplate.values.template),
            mobile_number: onSendTemplate.values.mobile_number,
            template_name: onSendTemplate.values.template_name,
            language_code: onSendTemplate.values.language_code,
            header: response.data?.file_url,
            headerType: onSendTemplate.values.headerType,
            parameters: onSendTemplate.values.parameters,
            // "footer": onSendTemplate.values.footer,
            // "button": onSendTemplate.values.button,
          });
        } else {
          setImageLoad(null);
          handleNotificationCall("error", response.data.message);
        }
        setLoader(false);
      });
    } else {
      handleNotificationCall(
        "error",
        "Please check your uploaded file format and size",
      );
    }
  }

  function selectTemplate(value) {
    setLoader(true);
    setImageLoad(null);
    if (value?.id) {
      viewTemplate({
        id: value.id,
        instance_id: instanceId?.id,
        token: token,
      })
        .then((response) => {
          if (response.data.status === true) {
            const regex = /\{\{.*?\}\}/g;
            setTemplateValue(value);
            var HEADER = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "HEADER",
            )[0]?.format;
            var BODY = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "BODY",
            )[0]?.text;
            var BUTTONS = JSON.parse(response.data.data.components).filter(type => type?.type === "BUTTONS")[0]?.buttons;

            setTemplateButton(BUTTONS)

            //  var FOOTER = JSON.parse(response.data.data.components).filter(type => type?.type === "FOOTER")[0]?.text;
            //   var BUTTONS = JSON.parse(response.data.data.components).filter(type => type?.type === "BUTTONS")[0]?.buttons;

            setTemplateHeader(HEADER);
            onSendTemplate.setValues({
              template: templateData.indexOf(value),
              mobile_number: onSendTemplate.values.mobile_number,
              template_name: response.data.data.name,
              language_code: response.data.data.language,
              header:
                response.data.data.fileUrl === null
                  ? ""
                  : response.data.data.fileUrl,
              headerType: HEADER !== undefined ? HEADER : "",
              parameters:
                BODY !== undefined ? (BODY.match(regex) || []).join(", ") : "",
              //  "footer": FOOTER !==undefined? FOOTER: "",
              //  "button": BUTTONS !==undefined? (BUTTONS.map((list) =>  list?.text+", " ))   : "",
            });

            var loop = (
              JSON.parse(response.data.data.components)
                .filter((type) => type?.type === "BODY")[0]
                ?.text.match(regex) || []
            )
              .join(",")
              .split(",");

            var message = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "BODY",
            )[0]?.text;

            for (let i = 0; i < loop.length; i++) {
              const match = `{{${i + 1}}}`;
              message = message.replace(
                match,
                `<b id="${i + 1}">{{${i + 1}}}</b>`,
              );

              setMessageData({
                ...messageData,
                headerField:
                  JSON.parse(response.data.data.components).filter(
                    (type) => type?.type === "HEADER",
                  )[0]?.format !== undefined
                    ? false
                    : true,
                bodyField:
                  JSON.parse(response.data.data.components).filter(
                    (type) => type?.type === "BODY",
                  )[0]?.text !== undefined
                    ? false
                    : true,
                //"footerField": JSON.parse(response.data.data.components).filter(type => type?.type === "FOOTER")[0]?.text !==undefined ? false : true,
                message: message,
              });
            }
          }
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setLoader(false);
      setTemplateValue(null);
    }
  }

  function messageText(e) {
    var loop = e.target.value.split(",");

    var message = messageData.message;

    for (let i = 0; i < loop.length; i++) {
      const regex = new RegExp(`<b id="${i + 1}">.*?</b>`, "g");
      const newText = '<b id="' + [i + 1] + '">' + loop[i] + "</b>";
      message = message.replace(regex, newText);
      setMessageData({
        ...messageData,
        message: message.replace(regex, newText),
      });
    }
  }

  const onSendTemplate = useFormik({
    initialValues: {
      template: { name: "" },
      template_name: "",
      parameters: "",
      mobile_number: "",
      language_code: "",
      header: "",
      headerType: "",
    },
    validationSchema:
      templateHeader === "IMAGE" ||
      templateHeader === "VIDEO" ||
      templateHeader === "DOCUMENT"
        ? sendTemplateSchema
        : sendTemplatewithioutSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      sendTemplateMessage(values, {
        template_id: templateValue?.id,
        instance_id: instanceId?.id,
        message: messageRef.current?.innerText,
        token: token,
      })
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);

            resetForm();
            setSearchNumbers([]);
            setMessageData({});
            setTemplateButton([]);
            setTemplateValue(null);
            history.push("/app/instance/sendMessage");
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const onSendTextMessage = useFormik({
    initialValues: {
      mobile_number: "",
      message: "",
    },
    validationSchema: sendTextMessageSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      sendTextMessage(values, {
        instance_id: instanceId?.id,
        token: token,
      })
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);

            resetForm();
            setSearchNumbers([]);
            history.push("/app/instance/sendMessage");
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const onSendImage = useFormik({
    initialValues: {
      mobile_number: "",
      file_path: "",
    },
    validationSchema: sendImageMessageSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);

      var FormData = require("form-data");
      var data = new FormData();
      data.append("file", fileData?.image);

      uploadFile(data, { token: token })
        .then((response) => {
          if (response.data.status === true) {
            sendImageMessage({
              mobile_number: values.mobile_number,
              file_url: response.data?.file_url,
              file_path: response.data?.file_path,
              instance_id: instanceId?.id,
              token: token,
            }).then((result) => {
              if (result.data.status === true) {
                handleNotificationCall("success", result.data.message);
                setFileData({});
                resetForm();
                setSearchNumbers([]);
                history.push("/app/instance/sendMessage");
              } else {
                handleNotificationCall("error", result.data.message);
              }
            });
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const onSendAudio = useFormik({
    initialValues: {
      mobile_number: "",
      file_path: "",
    },
    validationSchema: sendAudioMessageSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);

      var FormData = require("form-data");
      var data = new FormData();
      data.append("file", fileData?.audio);

      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          sendAudioMessage({
            mobile_number: values.mobile_number,
            file_url: response.data?.file_url,
            file_path: response.data?.file_path,
            instance_id: instanceId?.id,
            token: token,
          })
            .then((result) => {
              if (result.data.status === true) {
                handleNotificationCall("success", result.data.message);

                setFileData({});
                resetForm();
                setSearchNumbers([]);
                history.push("/app/instance/sendMessage");
              } else {
                handleNotificationCall("error", result.data.message);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          handleNotificationCall("error", response.data.message);
        }
        setSubmitting(false);
        setLoader(false);
      });
    },
  });

  const onSendVideo = useFormik({
    initialValues: {
      mobile_number: "",
      file_path: "",
    },
    validationSchema: sendVideoMessageSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);

      var FormData = require("form-data");
      var data = new FormData();
      data.append("file", fileData?.video);

      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          sendVideoMessage({
            mobile_number: values.mobile_number,
            file_url: response.data?.file_url,
            file_path: response.data?.file_path,
            instance_id: instanceId?.id,
            token: token,
          })
            .then((result) => {
              if (result.data.status === true) {
                handleNotificationCall("success", result.data.message);
                setFileData({});
                resetForm();
                setSearchNumbers([]);
                history.push("/app/instance/sendMessage");
              } else {
                handleNotificationCall("error", result.data.message);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          handleNotificationCall("error", response.data.message);
        }
        setSubmitting(false);
        setLoader(false);
      });
    },
  });

  const onSendDocument = useFormik({
    initialValues: {
      mobile_number: "",
      file_path: "",
    },
    validationSchema: sendDocumentMessageSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);

      var FormData = require("form-data");
      var data = new FormData();
      data.append("file", fileData?.document);

      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          sendDocumentMessage({
            mobile_number: values.mobile_number,
            file_url: response.data?.file_url,
            file_path: response.data?.file_path,
            instance_id: instanceId?.id,
            token: token,
          })
            .then((result) => {
              if (result.data.status === true) {
                handleNotificationCall("success", result.data.message);
                setFileData({});
                resetForm();
                setSearchNumbers([]);
                history.push("/app/instance/sendMessage");
              } else {
                handleNotificationCall("error", result.data.message);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          handleNotificationCall("error", response.data.message);
        }
        setSubmitting(false);
        setLoader(false);
      });
    },
  });

  const onSendContact = useFormik({
    initialValues: {
      mobile_number: "",
      first_name: "",
      last_name: "",
      contact_number: "",
    },
    validationSchema: sendContactMessageSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      sendContactMessage(values, {
        instance_id: instanceId?.id,
        token: token,
      })
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);

            resetForm();
            setSearchNumbers([]);
            history.push("/app/instance/sendMessage");
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const onSendLocation = useFormik({
    initialValues: {
      mobile_number: "",
      latitude: "",
      longitude: "",
      address: "",
    },
    validationSchema: sendLocationMessageSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      sendLocationMessage(values, {
        instance_id: instanceId?.id,
        token: token,
      })
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);

            resetForm();
            setSearchNumbers([]);
            history.push("/app/instance/sendMessage");
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  function removeDuplicatesFromString(inputString) {
    // Step 1: Split the string into an array
    const stringArray = inputString.split(",");

    // Step 2: Create a new array for unique values
    const uniqueArray = [];

    // Step 3: Iterate through the split array and add unique values to the new array
    for (const item of stringArray) {
      if (!uniqueArray.includes(item.trim())) {
        uniqueArray.push(item.trim());
      }
    }

    // Step 4: Join the unique values array back into a string
    const uniqueString = uniqueArray.join(",");

    return uniqueString;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
        className={globalClasses.displayBreak}
      >
        <Grid item xs={12} className={globalClasses.gap10}>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={2}
            order={{ xs: 2, sm: 1, md: 1, lg: 1 }}
            className={classes.tabsContainer}
          >
            <List style={{ padding: "10px" }}>
              <ListItem
                onClick={() => {
                  handleChangeTab(0);
                }}
                className={
                  value === 0
                    ? globalClasses.active + " " + globalClasses.tab
                    : globalClasses.tab
                }
              >
                <ListItemAvatar>
                  <FcNews style={{ fontSize: "30px" }} />
                </ListItemAvatar>
                <ListItemText primary="Send Template" />
              </ListItem>
              <ListItem
                onClick={() => {
                  handleChangeTab(1);
                }}
                className={
                  value === 1
                    ? globalClasses.active + " " + globalClasses.tab
                    : globalClasses.tab
                }
              >
                <ListItemAvatar>
                  <FcVoicePresentation style={{ fontSize: "30px" }} />
                </ListItemAvatar>
                <ListItemText primary="Send Message" />
              </ListItem>
              <ListItem
                onClick={() => {
                  handleChangeTab(2);
                }}
                className={
                  value === 2
                    ? globalClasses.active + " " + globalClasses.tab
                    : globalClasses.tab
                }
              >
                <ListItemAvatar>
                  <FcGallery style={{ fontSize: "30px" }} />
                </ListItemAvatar>
                <ListItemText primary="Send Image" />
              </ListItem>
              <ListItem
                onClick={() => {
                  handleChangeTab(3);
                }}
                className={
                  value === 3
                    ? globalClasses.active + " " + globalClasses.tab
                    : globalClasses.tab
                }
              >
                <ListItemAvatar>
                  <FcAudioFile style={{ fontSize: "30px" }} />
                </ListItemAvatar>
                <ListItemText primary="Send Audio" />
              </ListItem>
              <ListItem
                onClick={() => {
                  handleChangeTab(4);
                }}
                className={
                  value === 4
                    ? globalClasses.active + " " + globalClasses.tab
                    : globalClasses.tab
                }
              >
                <ListItemAvatar>
                  <FcVideoFile style={{ fontSize: "30px" }} />
                </ListItemAvatar>
                <ListItemText primary="Send Video" />
              </ListItem>
              <ListItem
                onClick={() => {
                  handleChangeTab(5);
                }}
                className={
                  value === 5
                    ? globalClasses.active + " " + globalClasses.tab
                    : globalClasses.tab
                }
              >
                <ListItemAvatar>
                  <FcDocument style={{ fontSize: "30px" }} />
                </ListItemAvatar>
                <ListItemText primary="Send Document" />
              </ListItem>
              <ListItem
                onClick={() => {
                  handleChangeTab(6);
                }}
                className={
                  value === 6
                    ? globalClasses.active + " " + globalClasses.tab
                    : globalClasses.tab
                }
              >
                <ListItemAvatar>
                  <FcBusinessContact style={{ fontSize: "30px" }} />
                </ListItemAvatar>
                <ListItemText primary="Send Contact" />
              </ListItem>
              <ListItem
                onClick={() => {
                  handleChangeTab(7);
                }}
                className={
                  value === 7
                    ? globalClasses.active + " " + globalClasses.tab
                    : globalClasses.tab
                }
              >
                <ListItemAvatar>
                  <LocationOnIcon style={{ color: "green" }} />
                </ListItemAvatar>
                <ListItemText primary="Send Location" />
              </ListItem>
            </List>
          </Grid>
          <Grid
            xs={12}
            sm={8}
            md={8}
            lg={10}
            order={{ xs: 1, sm: 2, md: 2, lg: 2 }}
            className={classes.tabsContainer}
          >
            <Grid
              className={value === 0 ? globalClasses.block : globalClasses.hide}
            >
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ justifyContent: "center" }}
                className={globalClasses.gap10}
              >
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <form
                    onSubmit={onSendTemplate.handleSubmit}
                    className={globalClasses.p24x8}
                  >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12}>
                        <FormControl className={classes.margin}>
                          <Autocomplete
                            underlineShow={false}
                            options={templateData}
                            getOptionLabel={(option) =>
                              option.name !== undefined ? `${option.name} ` : ""
                            }
                            value={templateValue}
                            onChange={(e, value) => {
                              selectTemplate(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                label={"Select Template"}
                                {...params}
                                name="template"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                error={
                                  onSendTemplate.touched.template_name &&
                                  Boolean(onSendTemplate.errors.template_name)
                                }
                                helperText={
                                  onSendTemplate.touched.template_name &&
                                  onSendTemplate.errors.template_name
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <TextField
                          label="To"
                          name="mobile_number"
                          placeholder="Ex: 91XXXXXXXXXX"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          classes={{
                            root: globalClasses.customOutlinedTextField,
                          }}
                          value={onSendTemplate.values.mobile_number}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setSearchNumbers(
                                e.target.value
                                  .split(",")
                                  [
                                    e.target.value.split(",").length - 1
                                  ].replace(/[^0-9,]/g, ""),
                              );
                              getcontactList(e.target.value);
                            }

                            onSendTemplate.setValues({
                              template: onSendTemplate.values.template,
                              mobile_number: removeDuplicatesFromString(
                                e.target.value.replace(/[^0-9,]/g, ""),
                              ),
                              template_name:
                                onSendTemplate.values.template_name,
                              language_code:
                                onSendTemplate.values.language_code,
                              header: onSendTemplate.values.header,
                              headerType: onSendTemplate.values.headerType,
                              parameters: onSendTemplate.values.parameters,
                              //"footer": onSendTemplate.values.footer,
                              // "button": onSendTemplate.values.button,
                            });
                          }}
                          error={
                            onSendTemplate.touched.mobile_number &&
                            Boolean(onSendTemplate.errors.mobile_number)
                          }
                          helperText={
                            onSendTemplate.touched.mobile_number &&
                            onSendTemplate.errors.mobile_number
                          }
                        />

                        <ClickAwayListener
                          onClickAway={(e) => setSearchNumbers([])}
                        >
                          <div>
                            {searchNumbers?.length > 1 && (
                              <div className={classes.popover}>
                                <List>
                                  {contactData
                                    ?.filter((number) =>
                                      number?.phone_number?.includes(
                                        searchNumbers,
                                      ),
                                    )
                                    .map((name) => (
                                      <ListItem
                                        key={name?.phone_number}
                                        value={name?.phone_number}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          var list = onSendTemplate.values.mobile_number.split(
                                            ",",
                                          );
                                          list[list?.length - 1] =
                                            name?.phone_number + ",";

                                          onSendTemplate.setValues({
                                            template:
                                              onSendTemplate.values.template,
                                            mobile_number: removeDuplicatesFromString(
                                              list.join(","),
                                            ),
                                            template_name:
                                              onSendTemplate.values
                                                .template_name,
                                            language_code:
                                              onSendTemplate.values
                                                .language_code,
                                            header:
                                              onSendTemplate.values.header,
                                            headerType:
                                              onSendTemplate.values.headerType,
                                            parameters:
                                              onSendTemplate.values.parameters,
                                            //"footer": onSendTemplate.values.footer,
                                            // "button": onSendTemplate.values.button,
                                          });
                                        }}
                                      >
                                        {name?.phone_number}
                                      </ListItem>
                                    ))}
                                </List>
                              </div>
                            )}
                          </div>
                        </ClickAwayListener>
                      </Grid>

                      {/* <Grid item xs={12} sm={12} md={12} lg={6}  > 

 
   <TextField 
      
    label="Name" 
    name="template_name"
   placeholder="Enter name"
    fullWidth
    margin="normal"
   InputLabelProps={{
     shrink: true, 
   }}
   InputProps={{
     readOnly: true,
   }}
   variant="outlined"
   classes={{ root: globalClasses.customOutlinedTextField }}
   value={onSendTemplate.values.template_name} 
   onChange={onSendTemplate.handleChange} 
     
   /> 
  
 
</Grid>


<Grid item xs={12} sm={12} md={12} lg={6} > 


 <FormControl className={classes.margin}>
   <TextField 
    label="Language"
    name="language_code"
    placeholder="Enter language"
     fullWidth
     margin="normal"
    InputLabelProps={{
      shrink: true, 
    }}
    InputProps={{
     readOnly: true,
   }}
    variant="outlined"
    classes={{ root: globalClasses.customOutlinedTextField }}
    value={onSendTemplate.values.language_code}
    onChange={onSendTemplate.handleChange} 
     error={onSendTemplate.touched.language_code && Boolean(onSendTemplate.errors.language_code)}
     helperText={onSendTemplate.touched.language_code && onSendTemplate.errors.language_code} 
   /> 
  
 </FormControl>
</Grid> */}

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl className={classes.margin}>
                          <TextField
                            label="Header"
                            name="header"
                            placeholder="Enter header"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            classes={{
                              root: globalClasses.customOutlinedTextField,
                            }}
                            value={onSendTemplate.values.header}
                            onChange={onSendTemplate.handleChange}
                            error={
                              onSendTemplate.touched.header &&
                              Boolean(onSendTemplate.errors.header)
                            }
                            helperText={
                              onSendTemplate.touched.header &&
                              onSendTemplate.errors.header
                            }
                          />
                        </FormControl>

                        {onSendTemplate.values.headerType === "IMAGE" ? (
                          <>
                            <Grid item xs={12}>
                              <FormControl className={classes.margin}>
                                <div style={{ display: "flex", gap: 10 }}>
                                  <TextField
                                    id="image"
                                    type="file"
                                    style={{ display: "none" }}
                                    name="file_path"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => {
                                      handleSendTemplateImage(e, "image");
                                    }}
                                    inputProps={{
                                      accept: "image/jpeg, image/png",
                                    }}
                                  />

                                  <label htmlFor="image">
                                    <Button
                                      variant="contained"
                                      color={"primary"}
                                      className={classes.button}
                                      startIcon={<FcGallery />}
                                      aria-label="upload image"
                                      component="span"
                                    >
                                      Upload image
                                    </Button>
                                    <Typography
                                      className={classes.textWrap}
                                      style={{ fontSize: "12px" }}
                                    >
                                      {"Maximum allowed size: 5 MB"}
                                    </Typography>
                                    <Typography
                                      className={classes.textWrap}
                                      style={{
                                        fontSize: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {"Allowed file formats include:"}
                                      <Tooltip
                                        title="image/jpeg, image/png"
                                        placement="bottom"
                                        aria-label="edit"
                                      >
                                        <InfoIcon
                                          color="primary"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </Tooltip>
                                    </Typography>

                                    {fileData?.image?.name ? (
                                      <Typography className={classes.textWrap}>
                                        {fileData?.image?.name}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                  {imageLoad === false ? (
                                    <CheckCircleIcon
                                      style={{ fontSize: 30, color: "green" }}
                                    />
                                  ) : imageLoad === true ? (
                                    <CircularProgress
                                      color="inherit"
                                      style={{ color: "blue" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </FormControl>
                            </Grid>
                          </>
                        ) : onSendTemplate.values.headerType === "VIDEO" ? (
                          <>
                            <Grid item xs={12}>
                              <FormControl className={classes.margin}>
                                <div style={{ display: "flex", gap: 10 }}>
                                  <TextField
                                    id="video"
                                    type="file"
                                    style={{ display: "none" }}
                                    name="file_path"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => {
                                      handleSendTemplateImage(e, "video");
                                    }}
                                    inputProps={{
                                      accept: "video/mp4, video/3gp",
                                    }}
                                  />

                                  <label htmlFor="video">
                                    <Button
                                      variant="contained"
                                      color={"primary"}
                                      className={classes.button}
                                      startIcon={<FcVideoFile />}
                                      aria-label="upload video"
                                      component="span"
                                    >
                                      Upload video
                                    </Button>
                                    <Typography
                                      className={classes.textWrap}
                                      style={{ fontSize: "12px" }}
                                    >
                                      {"maximum allowed size: 16 MB"}
                                    </Typography>
                                    <Typography
                                      className={classes.textWrap}
                                      style={{
                                        fontSize: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {"Allowed file formats include:"}
                                      <Tooltip
                                        title="video/mp4, video/3gp"
                                        placement="bottom"
                                        aria-label="edit"
                                      >
                                        <InfoIcon
                                          color="primary"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </Tooltip>
                                    </Typography>
                                    {fileData?.video?.name ? (
                                      <Typography className={classes.textWrap}>
                                        {fileData?.video?.name}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                  {imageLoad === false ? (
                                    <CheckCircleIcon
                                      style={{ fontSize: 30, color: "green" }}
                                    />
                                  ) : imageLoad === true ? (
                                    <CircularProgress
                                      color="inherit"
                                      style={{ color: "blue" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </FormControl>
                            </Grid>
                          </>
                        ) : onSendTemplate.values.headerType === "DOCUMENT" ? (
                          <>
                            <Grid item xs={12}>
                              <FormControl className={classes.margin}>
                                <div style={{ display: "flex", gap: 10 }}>
                                  <TextField
                                    id="document"
                                    type="file"
                                    style={{ display: "none" }}
                                    name="file_path"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => {
                                      handleSendTemplateImage(e, "document");
                                    }}
                                    inputProps={{
                                      accept:
                                        "text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    }}
                                  />

                                  <label htmlFor="document">
                                    <Button
                                      variant="contained"
                                      color={"primary"}
                                      className={classes.button}
                                      startIcon={<FcDocument />}
                                      aria-label="upload document"
                                      component="span"
                                    >
                                      Upload document
                                    </Button>

                                    <Typography
                                      className={classes.textWrap}
                                      style={{ fontSize: "12px" }}
                                    >
                                      {"maximum allowed size: 100 MB"}
                                    </Typography>
                                    <Typography
                                      className={classes.textWrap}
                                      style={{
                                        fontSize: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {"Allowed file formats include:"}
                                      <Tooltip
                                        title="text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        placement="bottom"
                                        aria-label="edit"
                                      >
                                        <InfoIcon
                                          color="primary"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </Tooltip>
                                    </Typography>
                                    {fileData?.document?.name ? (
                                      <Typography className={classes.textWrap}>
                                        {fileData?.document?.name}
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                  {imageLoad === false ? (
                                    <CheckCircleIcon
                                      style={{ fontSize: 30, color: "green" }}
                                    />
                                  ) : imageLoad === true ? (
                                    <CircularProgress
                                      color="inherit"
                                      style={{ color: "blue" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </FormControl>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl className={classes.margin}>
                          <TextField
                            label="Body"
                            name="parameters"
                            placeholder="Enter body"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: messageData.bodyField,
                            }}
                            variant="outlined"
                            classes={{
                              root: globalClasses.customOutlinedTextField,
                            }}
                            value={onSendTemplate.values.parameters}
                            onChange={onSendTemplate.handleChange}
                            onBlur={(e) => {
                              messageText(e);
                            }}
                            error={
                              onSendTemplate.touched.parameters &&
                              Boolean(onSendTemplate.errors.parameters)
                            }
                            helperText={
                              onSendTemplate.touched.parameters &&
                              onSendTemplate.errors.parameters
                            }
                          />
                        </FormControl>
                      </Grid>

                      {/* <Grid item xs={12} sm={12} md={12} lg={6}> 

 <FormControl className={classes.margin}>
   <TextField 
     label="Footer"
     placeholder="Enter footer"
     fullWidth
     margin="normal"
     name="footer"
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={{
     readOnly: messageData.footerField,
   }}
   
    variant="outlined"
    classes={{ root: globalClasses.customOutlinedTextField }}
    value={onSendTemplate.values.footer}
     onChange={onSendTemplate.handleChange}
     error={onSendTemplate.touched.footer && Boolean(onSendTemplate.errors.footer)}
     helperText={onSendTemplate.touched.footer && onSendTemplate.errors.footer} 
   /> 
  
 </FormControl>
</Grid> */}

                      {/* <Grid item xs={12} sm={12} md={12} lg={6}> 

 <FormControl className={classes.margin}>
   <TextField 
     label="Button"
     placeholder="Enter button"
     fullWidth
     margin="normal"
     name="button"
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={{
     readOnly: messageData.buttonField,
   }}
   
    variant="outlined"
    classes={{ root: globalClasses.customOutlinedTextField }}
    value={onSendTemplate.values.button}
     onChange={onSendTemplate.handleChange}
     error={onSendTemplate.touched.button && Boolean(onSendTemplate.errors.button)}
     helperText={onSendTemplate.touched.button && onSendTemplate.errors.button} 
   /> 
  
 </FormControl>
</Grid> */}
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} className={classes.formFooter}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          // startIcon={<SendIcon/>}
                          disabled={onSendTemplate.isSubmitting}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  className={classes.marginBorder}
                >
                  <Grid className={globalClasses.p24x8}>
                    <Typography variant="h6" className={globalClasses.center}>
                      {messageData.message ? "Preview" : ""}
                    </Typography>
                    <br />
                    <Typography variant="text">
                      {onSendTemplate.values.header &&
                      onSendTemplate.values.headerType === "IMAGE" ? (
                        <img
                          src={onSendTemplate.values.header}
                          alt="logo"
                          style={{ width: "100%", height: "200px" }}
                        />
                      ) : onSendTemplate.values.header &&
                        onSendTemplate.values.headerType === "VIDEO" ? (
                        <video controls width={"100%"}>
                          <source src={onSendTemplate.values.header} />
                        </video>
                      ) : onSendTemplate.values.header &&
                        onSendTemplate.values.headerType === "DOCUMENT" ? (
                        <iframe
                          src={`https://docs.google.com/a/umd.edu/viewer?url=${onSendTemplate.values.header}&embedded=true`}
                          title="File"
                          width="100%"
                          height="200"
                        ></iframe>
                      ) : (
                        ""
                      )}
                      <div
                        ref={messageRef}
                        dangerouslySetInnerHTML={{
                          __html: messageData.message,
                        }}
                      />
                      {templateButton && templateButton.length>0 ?
                        <>
                          <Divider style={{margin:'15px 0px'}}/>
                              <div style={{paddingTop:'5px' ,display:'flex',justifyContent:'space-evenly',flexWrap:'wrap', alignItems:'center',flexDirection:'row',gap:'20px'}}>
                              {
                                templateButton.map((button, index) => (

                                  <Link key={index} type={button.type} href={button?.url} target='_blank'
                                  underline='hover'
                                  style={{cursor:'pointer',color:'#3cc1e7',fontSize:'1.2rem'}}
                                  >
                                    {button.text}
                                  </Link> 
                                ))
                              }
                              </div>
                        </>
                        :
                        <></>
                        }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              className={value === 1 ? globalClasses.block : globalClasses.hide}
            >
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ justifyContent: "center" }}
                className={globalClasses.gap10}
              >
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <form
                    onSubmit={onSendTextMessage.handleSubmit}
                    className={globalClasses.p24x8}
                  >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <TextField
                          label="To"
                          name="mobile_number"
                          placeholder="Ex: 91XXXXXXXXXX"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          classes={{
                            root: globalClasses.customOutlinedTextField,
                          }}
                          value={onSendTextMessage.values.mobile_number}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setSearchNumbers(
                                e.target.value
                                  .split(",")
                                  [
                                    e.target.value.split(",").length - 1
                                  ].replace(/[^0-9,]/g, ""),
                              );
                              getcontactList(e.target.value);
                            }

                            onSendTextMessage.setValues({
                              mobile_number: e.target.value.replace(
                                /[^0-9,]/g,
                                "",
                              ),
                              message: onSendTextMessage.values.message,
                            });
                          }}
                          error={
                            onSendTextMessage.touched.mobile_number &&
                            Boolean(onSendTextMessage.errors.mobile_number)
                          }
                          helperText={
                            onSendTextMessage.touched.mobile_number &&
                            onSendTextMessage.errors.mobile_number
                          }
                        />

                        <ClickAwayListener
                          onClickAway={(e) => setSearchNumbers([])}
                        >
                          <div>
                            {searchNumbers?.length > 1 && (
                              <div className={classes.popover}>
                                <List>
                                  {contactData
                                    ?.filter((number) =>
                                      number?.phone_number?.includes(
                                        searchNumbers,
                                      ),
                                    )
                                    .map((name) => (
                                      <ListItem
                                        key={name?.phone_number}
                                        value={name?.phone_number}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          var list = onSendTextMessage.values.mobile_number.split(
                                            ",",
                                          );
                                          list[list?.length - 1] =
                                            name?.phone_number + ",";

                                          onSendTextMessage.setValues({
                                            mobile_number: removeDuplicatesFromString(
                                              list.join(","),
                                            ),
                                            message:
                                              onSendTextMessage.values.message,
                                          });
                                        }}
                                      >
                                        {name?.phone_number}
                                      </ListItem>
                                    ))}
                                </List>
                              </div>
                            )}
                          </div>
                        </ClickAwayListener>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl className={classes.margin}>
                          <TextField
                            label="Body"
                            name="message"
                            placeholder="Enter body"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            classes={{
                              root: globalClasses.customOutlinedTextField,
                            }}
                            value={onSendTextMessage.values.message}
                            onChange={onSendTextMessage.handleChange}
                            error={
                              onSendTextMessage.touched.message &&
                              Boolean(onSendTextMessage.errors.message)
                            }
                            helperText={
                              onSendTextMessage.touched.message &&
                              onSendTextMessage.errors.message
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} className={classes.formFooter}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          // startIcon={<SendIcon/>}
                          disabled={onSendTextMessage.isSubmitting}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  className={classes.marginBorder}
                ></Grid>
              </Grid>
            </Grid>

            <Grid
              className={value === 2 ? globalClasses.block : globalClasses.hide}
            >
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ justifyContent: "center" }}
                className={globalClasses.gap10}
              >
                <Grid xs={12} sm={12} md={12} lg={6}>
                  <form
                    onSubmit={onSendImage.handleSubmit}
                    className={globalClasses.p24x8}
                  >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <TextField
                          name="mobile_number"
                          label="To"
                          placeholder="Ex: 91XXXXXXXXXX"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          classes={{
                            root: globalClasses.customOutlinedTextField,
                          }}
                          value={onSendImage.values.mobile_number}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setSearchNumbers(
                                e.target.value
                                  .split(",")
                                  [
                                    e.target.value.split(",").length - 1
                                  ].replace(/[^0-9,]/g, ""),
                              );
                              getcontactList(e.target.value);
                            }
                            onSendImage.setValues({
                              mobile_number: e.target.value.replace(
                                /[^0-9,]/g,
                                "",
                              ),
                              file_path: fileData?.image,
                            });
                          }}
                          error={
                            onSendImage.touched.mobile_number &&
                            Boolean(onSendImage.errors.mobile_number)
                          }
                          helperText={
                            onSendImage.touched.mobile_number &&
                            onSendImage.errors.mobile_number
                          }
                        />

                        <ClickAwayListener
                          onClickAway={(e) => setSearchNumbers([])}
                        >
                          <div>
                            {searchNumbers?.length > 1 && (
                              <div className={classes.popover}>
                                <List>
                                  {contactData
                                    ?.filter((number) =>
                                      number?.phone_number?.includes(
                                        searchNumbers,
                                      ),
                                    )
                                    .map((name) => (
                                      <ListItem
                                        key={name?.phone_number}
                                        value={name?.phone_number}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          var list = onSendImage.values.mobile_number.split(
                                            ",",
                                          );
                                          list[list?.length - 1] =
                                            name?.phone_number + ",";

                                          onSendImage.setValues({
                                            mobile_number: removeDuplicatesFromString(
                                              list.join(","),
                                            ),
                                            file_path: fileData?.image,
                                          });
                                        }}
                                      >
                                        {name?.phone_number}
                                      </ListItem>
                                    ))}
                                </List>
                              </div>
                            )}
                          </div>
                        </ClickAwayListener>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <InputLabel
                          shrink
                          htmlFor="image"
                          style={{ marginTop: "-5px" }}
                        >
                          Image
                        </InputLabel>

                        <FormControl
                          className={classes.margin}
                          style={{ marginTop: "5px" }}
                        >
                          <div>
                            <TextField
                              id="image"
                              type="file"
                              style={{ display: "none" }}
                              name="file_path"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleChange(e, "image");
                                onSendImage.setValues({
                                  mobile_number:
                                    onSendImage.values.mobile_number,
                                  file_path: e.target.files[0]?.name,
                                });
                              }}
                              inputProps={{ accept: "image/jpeg, image/png" }}
                            />

                            <label htmlFor="image">
                              <Button
                                variant="contained"
                                color={
                                  !fileData?.image?.name &&
                                  onSendImage.touched.file_path
                                    ? "secondary"
                                    : "primary"
                                }
                                className={classes.button}
                                startIcon={<FcGallery />}
                                aria-label="upload image"
                                component="span"
                              >
                                Upload image
                              </Button>
                              <Typography
                                className={classes.textWrap}
                                style={{ fontSize: "12px" }}
                              >
                                {"Maximum allowed size: 5 MB"}
                              </Typography>
                              <Typography
                                className={classes.textWrap}
                                style={{
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {"Allowed file formats include:"}
                                <Tooltip
                                  title="image/jpeg, image/png"
                                  placement="bottom"
                                  aria-label="edit"
                                >
                                  <InfoIcon
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </Typography>
                              {!fileData?.image?.name &&
                              onSendImage.touched.file_path ? (
                                <Typography color={"secondary"}>
                                  {onSendImage.errors.file_path}
                                </Typography>
                              ) : (
                                <Typography className={classes.textWrap}>
                                  {fileData?.image?.name}
                                </Typography>
                              )}
                            </label>
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} className={classes.formFooter}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          // startIcon={<SendIcon/>}
                          disabled={onSendImage.isSubmitting}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  className={classes.marginBorder + " " + globalClasses.center}
                >
                  {fileData?.imageUrl ? (
                    <>
                      <Grid className={globalClasses.p24x8}>
                        <Typography
                          variant="h6"
                          className={globalClasses.center}
                        >
                          Preview
                        </Typography>
                        <br />
                        <Typography variant="text">
                          {onSendTemplate.values.header &&
                          onSendTemplate.values.headerType === "IMAGE" ? (
                            <img
                              src={onSendTemplate.values.header}
                              alt="logo"
                              style={{ width: "100%", height: "200px" }}
                            />
                          ) : onSendTemplate.values.header &&
                            onSendTemplate.values.headerType === "VIDEO" ? (
                            <video controls width={"100%"}>
                              <source src={onSendTemplate.values.header} />
                            </video>
                          ) : onSendTemplate.values.header &&
                            onSendTemplate.values.headerType === "DOCUMENT" ? (
                            <iframe
                              src={`https://docs.google.com/a/umd.edu/viewer?url=${onSendTemplate.values.header}&embedded=true`}
                              title="File"
                              width="100%"
                              height="200"
                            ></iframe>
                          ) : (
                            ""
                          )}
                          <img
                            alt="author"
                            src={fileData?.imageUrl}
                            width={"90%"}
                          />
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={value === 3 ? globalClasses.block : globalClasses.hide}
            >
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ justifyContent: "center" }}
                className={globalClasses.gap10}
              >
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <form
                    onSubmit={onSendAudio.handleSubmit}
                    className={globalClasses.p24x8}
                  >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <TextField
                          name="mobile_number"
                          label="To"
                          placeholder="Ex: 91XXXXXXXXXX"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          classes={{
                            root: globalClasses.customOutlinedTextField,
                          }}
                          value={onSendAudio.values.mobile_number}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setSearchNumbers(
                                e.target.value
                                  .split(",")
                                  [
                                    e.target.value.split(",").length - 1
                                  ].replace(/[^0-9,]/g, ""),
                              );
                              getcontactList(e.target.value);
                            }
                            onSendAudio.setValues({
                              mobile_number: e.target.value.replace(
                                /[^0-9,]/g,
                                "",
                              ),
                              file_path: fileData?.audio,
                            });
                          }}
                          error={
                            onSendAudio.touched.mobile_number &&
                            Boolean(onSendAudio.errors.mobile_number)
                          }
                          helperText={
                            onSendAudio.touched.mobile_number &&
                            onSendAudio.errors.mobile_number
                          }
                        />

                        <ClickAwayListener
                          onClickAway={(e) => setSearchNumbers([])}
                        >
                          <div>
                            {searchNumbers?.length > 1 && (
                              <div className={classes.popover}>
                                <List>
                                  {contactData
                                    ?.filter((number) =>
                                      number?.phone_number?.includes(
                                        searchNumbers,
                                      ),
                                    )
                                    .map((name) => (
                                      <ListItem
                                        key={name?.phone_number}
                                        value={name?.phone_number}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          var list = onSendAudio.values.mobile_number.split(
                                            ",",
                                          );
                                          list[list?.length - 1] =
                                            name?.phone_number + ",";

                                          onSendAudio.setValues({
                                            mobile_number: removeDuplicatesFromString(
                                              list.join(","),
                                            ),
                                            file_path: fileData?.audio,
                                          });
                                        }}
                                      >
                                        {name?.phone_number}
                                      </ListItem>
                                    ))}
                                </List>
                              </div>
                            )}
                          </div>
                        </ClickAwayListener>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <InputLabel
                          shrink
                          htmlFor="audio"
                          style={{ marginTop: "-5px" }}
                        >
                          Audio
                        </InputLabel>

                        <FormControl
                          className={classes.margin}
                          style={{ marginTop: "5px" }}
                        >
                          <div>
                            <TextField
                              id="audio"
                              type="file"
                              style={{ display: "none" }}
                              name="file_path"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleChange(e, "audio");
                                onSendAudio.setValues({
                                  mobile_number:
                                    onSendAudio.values.mobile_number,
                                  file_path: e.target.files[0]?.name,
                                });
                              }}
                              inputProps={{
                                accept:
                                  "audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg",
                              }}
                            />

                            <label htmlFor="audio">
                              <Button
                                variant="contained"
                                color={
                                  !fileData?.audio?.name &&
                                  onSendAudio.touched.file_path
                                    ? "secondary"
                                    : "primary"
                                }
                                className={classes.button}
                                startIcon={<FcAudioFile />}
                                aria-label="upload audio"
                                component="span"
                              >
                                Upload audio
                              </Button>
                              <Typography
                                className={classes.textWrap}
                                style={{ fontSize: "12px" }}
                              >
                                {"maximum allowed size: 16 MB"}
                              </Typography>
                              <Typography
                                className={classes.textWrap}
                                style={{
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {"Allowed file formats include:"}
                                <Tooltip
                                  title="audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg (only opus codecs, base audio/ogg is not supported)"
                                  placement="bottom"
                                  aria-label="edit"
                                >
                                  <InfoIcon
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </Typography>
                              {!fileData?.audio?.name &&
                              onSendAudio.touched.file_path ? (
                                <Typography color={"secondary"}>
                                  {onSendAudio.errors.file_path}
                                </Typography>
                              ) : (
                                <Typography className={classes.textWrap}>
                                  {fileData?.audio?.name}
                                </Typography>
                              )}
                            </label>
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} className={classes.formFooter}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          // startIcon={<SendIcon/>}
                          disabled={onSendAudio.isSubmitting}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  className={classes.marginBorder}
                ></Grid>
              </Grid>
            </Grid>
            <Grid
              className={value === 4 ? globalClasses.block : globalClasses.hide}
            >
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ justifyContent: "center" }}
                className={globalClasses.gap10}
              >
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <form
                    onSubmit={onSendVideo.handleSubmit}
                    className={globalClasses.p24x8}
                  >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <TextField
                          name="mobile_number"
                          label="To"
                          placeholder="Ex: 91XXXXXXXXXX"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          classes={{
                            root: globalClasses.customOutlinedTextField,
                          }}
                          value={onSendVideo.values.mobile_number}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setSearchNumbers(
                                e.target.value
                                  .split(",")
                                  [
                                    e.target.value.split(",").length - 1
                                  ].replace(/[^0-9,]/g, ""),
                              );
                              getcontactList(e.target.value);
                            }
                            onSendVideo.setValues({
                              mobile_number: e.target.value.replace(
                                /[^0-9,]/g,
                                "",
                              ),
                              file_path: fileData?.video,
                            });
                          }}
                          error={
                            onSendVideo.touched.mobile_number &&
                            Boolean(onSendVideo.errors.mobile_number)
                          }
                          helperText={
                            onSendVideo.touched.mobile_number &&
                            onSendVideo.errors.mobile_number
                          }
                        />

                        <ClickAwayListener
                          onClickAway={(e) => setSearchNumbers([])}
                        >
                          <div>
                            {searchNumbers?.length > 1 && (
                              <div className={classes.popover}>
                                <List>
                                  {contactData
                                    ?.filter((number) =>
                                      number?.phone_number?.includes(
                                        searchNumbers,
                                      ),
                                    )
                                    .map((name) => (
                                      <ListItem
                                        key={name?.phone_number}
                                        value={name?.phone_number}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          var list = onSendVideo.values.mobile_number.split(
                                            ",",
                                          );
                                          list[list?.length - 1] =
                                            name?.phone_number + ",";

                                          onSendVideo.setValues({
                                            mobile_number: removeDuplicatesFromString(
                                              list.join(","),
                                            ),
                                            file_path: fileData?.video,
                                          });
                                        }}
                                      >
                                        {name?.phone_number}
                                      </ListItem>
                                    ))}
                                </List>
                              </div>
                            )}
                          </div>
                        </ClickAwayListener>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <InputLabel
                          shrink
                          htmlFor="video"
                          style={{ marginTop: "-5px" }}
                        >
                          Video
                        </InputLabel>

                        <FormControl
                          className={classes.margin}
                          style={{ marginTop: "5px" }}
                        >
                          <div>
                            <TextField
                              id="video"
                              type="file"
                              style={{ display: "none" }}
                              name="file_path"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleChange(e, "video");
                                onSendVideo.setValues({
                                  mobile_number:
                                    onSendVideo.values.mobile_number,
                                  file_path: e.target.files[0]?.name,
                                });
                              }}
                              inputProps={{ accept: "video/mp4, video/3gp" }}
                            />

                            <label htmlFor="video">
                              <Button
                                variant="contained"
                                color={
                                  !fileData?.video?.name &&
                                  onSendVideo.touched.file_path
                                    ? "secondary"
                                    : "primary"
                                }
                                className={classes.button}
                                startIcon={<FcVideoFile />}
                                aria-label="upload video"
                                component="span"
                              >
                                Upload video
                              </Button>
                              <Typography
                                className={classes.textWrap}
                                style={{ fontSize: "12px" }}
                              >
                                {"maximum allowed size: 16 MB"}
                              </Typography>
                              <Typography
                                className={classes.textWrap}
                                style={{
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {"Allowed file formats include:"}
                                <Tooltip
                                  title="video/mp4, video/3gp"
                                  placement="bottom"
                                  aria-label="edit"
                                >
                                  <InfoIcon
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </Typography>
                              {!fileData?.video?.name &&
                              onSendVideo.touched.file_path ? (
                                <Typography color={"secondary"}>
                                  {onSendVideo.errors.file_path}
                                </Typography>
                              ) : (
                                <Typography className={classes.textWrap}>
                                  {fileData?.video?.name}
                                </Typography>
                              )}
                            </label>
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} className={classes.formFooter}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          disabled={onSendVideo.isSubmitting}
                          // startIcon={<SendIcon/>}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  className={classes.marginBorder}
                ></Grid>
              </Grid>
            </Grid>
            <Grid
              className={value === 5 ? globalClasses.block : globalClasses.hide}
            >
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ justifyContent: "center" }}
                className={globalClasses.gap10}
              >
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <form
                    onSubmit={onSendDocument.handleSubmit}
                    className={globalClasses.p24x8}
                  >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <TextField
                          name="mobile_number"
                          label="To"
                          placeholder="Ex: 91XXXXXXXXXX"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          classes={{
                            root: globalClasses.customOutlinedTextField,
                          }}
                          value={onSendDocument.values.mobile_number}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setSearchNumbers(
                                e.target.value
                                  .split(",")
                                  [
                                    e.target.value.split(",").length - 1
                                  ].replace(/[^0-9,]/g, ""),
                              );
                              getcontactList(e.target.value);
                            }
                            onSendDocument.setValues({
                              mobile_number: e.target.value.replace(
                                /[^0-9,]/g,
                                "",
                              ),
                              file_path: fileData?.document,
                            });
                          }}
                          error={
                            onSendDocument.touched.mobile_number &&
                            Boolean(onSendDocument.errors.mobile_number)
                          }
                          helperText={
                            onSendDocument.touched.mobile_number &&
                            onSendDocument.errors.mobile_number
                          }
                        />

                        <ClickAwayListener
                          onClickAway={(e) => setSearchNumbers([])}
                        >
                          <div>
                            {searchNumbers?.length > 1 && (
                              <div className={classes.popover}>
                                <List>
                                  {contactData
                                    ?.filter((number) =>
                                      number?.phone_number?.includes(
                                        searchNumbers,
                                      ),
                                    )
                                    .map((name) => (
                                      <ListItem
                                        key={name?.phone_number}
                                        value={name?.phone_number}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          var list = onSendDocument.values.mobile_number.split(
                                            ",",
                                          );
                                          list[list?.length - 1] =
                                            name?.phone_number + ",";

                                          onSendDocument.setValues({
                                            mobile_number: removeDuplicatesFromString(
                                              list.join(","),
                                            ),
                                            file_path: fileData?.document,
                                          });
                                        }}
                                      >
                                        {name?.phone_number}
                                      </ListItem>
                                    ))}
                                </List>
                              </div>
                            )}
                          </div>
                        </ClickAwayListener>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <InputLabel
                          shrink
                          htmlFor="document"
                          style={{ marginTop: "-5px" }}
                        >
                          Document
                        </InputLabel>

                        <FormControl
                          className={classes.margin}
                          style={{ marginTop: "5px" }}
                        >
                          <div>
                            <TextField
                              id="document"
                              type="file"
                              style={{ display: "none" }}
                              name="file_path"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) => {
                                handleChange(e, "document");
                                onSendDocument.setValues({
                                  mobile_number:
                                    onSendDocument.values.mobile_number,
                                  file_path: e.target.files[0]?.name,
                                });
                              }}
                              inputProps={{
                                accept:
                                  "text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                              }}
                            />

                            <label htmlFor="document">
                              <Button
                                variant="contained"
                                color={
                                  !fileData?.document?.name &&
                                  onSendDocument.touched.file_path
                                    ? "secondary"
                                    : "primary"
                                }
                                className={classes.button}
                                startIcon={<FcDocument />}
                                aria-label="upload document"
                                component="span"
                              >
                                Upload document
                              </Button>
                              <Typography
                                className={classes.textWrap}
                                style={{ fontSize: "12px" }}
                              >
                                {"maximum allowed size: 100 MB"}
                              </Typography>
                              <Typography
                                className={classes.textWrap}
                                style={{
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {"Allowed file formats include:"}
                                <Tooltip
                                  title="text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  placement="bottom"
                                  aria-label="edit"
                                >
                                  <InfoIcon
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Tooltip>
                              </Typography>
                              {!fileData?.document?.name &&
                              onSendDocument.touched.file_path ? (
                                <Typography color={"secondary"}>
                                  {onSendDocument.errors.file_path}
                                </Typography>
                              ) : (
                                <Typography className={classes.textWrap}>
                                  {fileData?.document?.name}
                                </Typography>
                              )}
                            </label>
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} className={classes.formFooter}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          disabled={onSendDocument.isSubmitting}
                          // startIcon={<SendIcon/>}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  className={classes.marginBorder}
                ></Grid>
              </Grid>
            </Grid>

            <Grid
              className={value === 6 ? globalClasses.block : globalClasses.hide}
            >
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ justifyContent: "center" }}
                className={globalClasses.gap10}
              >
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <form
                    onSubmit={onSendContact.handleSubmit}
                    className={globalClasses.p24x8}
                  >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <TextField
                          name="mobile_number"
                          label="To"
                          placeholder="Ex: 91XXXXXXXXXX"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          classes={{
                            root: globalClasses.customOutlinedTextField,
                          }}
                          value={onSendContact.values.mobile_number}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setSearchNumbers(
                                e.target.value
                                  .split(",")
                                  [
                                    e.target.value.split(",").length - 1
                                  ].replace(/[^0-9,]/g, ""),
                              );
                              getcontactList(e.target.value);
                            }
                            onSendContact.setValues({
                              mobile_number: e.target.value.replace(
                                /[^0-9,]/g,
                                "",
                              ),
                              first_name: onSendContact.values.first_name,
                              last_name: onSendContact.values.last_name,
                              contact_number:
                                onSendContact.values.contact_number,
                            });
                          }}
                          error={
                            onSendContact.touched.mobile_number &&
                            Boolean(onSendContact.errors.mobile_number)
                          }
                          helperText={
                            onSendContact.touched.mobile_number &&
                            onSendContact.errors.mobile_number
                          }
                        />

                        <ClickAwayListener
                          onClickAway={(e) => setSearchNumbers([])}
                        >
                          <div>
                            {searchNumbers?.length > 1 && (
                              <div className={classes.popover}>
                                <List>
                                  {contactData
                                    ?.filter((number) =>
                                      number?.phone_number?.includes(
                                        searchNumbers,
                                      ),
                                    )
                                    .map((name) => (
                                      <ListItem
                                        key={name?.phone_number}
                                        value={name?.phone_number}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          var list = onSendContact.values.mobile_number.split(
                                            ",",
                                          );
                                          list[list?.length - 1] =
                                            name?.phone_number + ",";

                                          onSendContact.setValues({
                                            mobile_number: removeDuplicatesFromString(
                                              list.join(","),
                                            ),
                                            first_name:
                                              onSendContact.values.first_name,
                                            last_name:
                                              onSendContact.values.last_name,
                                            contact_number:
                                              onSendContact.values
                                                .contact_number,
                                          });
                                        }}
                                      >
                                        {name?.phone_number}
                                      </ListItem>
                                    ))}
                                </List>
                              </div>
                            )}
                          </div>
                        </ClickAwayListener>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl className={classes.margin}>
                          <TextField
                            name="first_name"
                            label="First Name"
                            placeholder="Enter first name"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            classes={{
                              root: globalClasses.customOutlinedTextField,
                            }}
                            value={onSendContact.values.first_name}
                            onChange={onSendContact.handleChange}
                            error={
                              onSendContact.touched.first_name &&
                              Boolean(onSendContact.errors.first_name)
                            }
                            helperText={
                              onSendContact.touched.first_name &&
                              onSendContact.errors.first_name
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl className={classes.margin}>
                          <TextField
                            name="last_name"
                            label="Last Name"
                            placeholder="Enter last name"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            classes={{
                              root: globalClasses.customOutlinedTextField,
                            }}
                            value={onSendContact.values.last_name}
                            onChange={onSendContact.handleChange}
                            error={
                              onSendContact.touched.last_name &&
                              Boolean(onSendContact.errors.last_name)
                            }
                            helperText={
                              onSendContact.touched.last_name &&
                              onSendContact.errors.last_name
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl className={classes.margin}>
                          <TextField
                            type="number"
                            name="contact_number"
                            label="Phone"
                            placeholder="Enter phone"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            classes={{
                              root: globalClasses.customOutlinedTextField,
                            }}
                            value={onSendContact.values.contact_number}
                            onChange={onSendContact.handleChange}
                            error={
                              onSendContact.touched.contact_number &&
                              Boolean(onSendContact.errors.contact_number)
                            }
                            helperText={
                              onSendContact.touched.contact_number &&
                              onSendContact.errors.contact_number
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid></Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} className={classes.formFooter}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          disabled={onSendContact.isSubmitting}
                          // startIcon={<SendIcon/>}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  className={classes.marginBorder}
                ></Grid>
              </Grid>
            </Grid>
            <Grid
              className={value === 7 ? globalClasses.block : globalClasses.hide}
            >
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ justifyContent: "center" }}
                className={globalClasses.gap10}
              >
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <form
                    onSubmit={onSendLocation.handleSubmit}
                    className={globalClasses.p24x8}
                  >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <TextField
                          name="mobile_number"
                          label="To"
                          placeholder="Ex: 91XXXXXXXXXX"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          classes={{
                            root: globalClasses.customOutlinedTextField,
                          }}
                          value={onSendLocation.values.mobile_number}
                          onChange={(e) => {
                            if (e.target.value.trim() !== "") {
                              setSearchNumbers(
                                e.target.value
                                  .split(",")
                                  [
                                    e.target.value.split(",").length - 1
                                  ].replace(/[^0-9,]/g, ""),
                              );
                              getcontactList(e.target.value);
                            }
                            onSendLocation.setValues({
                              mobile_number: e.target.value.replace(
                                /[^0-9,]/g,
                                "",
                              ),
                              latitude: onSendLocation.values.latitude,
                              longitude: onSendLocation.values.longitude,
                              address: onSendLocation.values.address,
                            });
                          }}
                          error={
                            onSendLocation.touched.mobile_number &&
                            Boolean(onSendLocation.errors.mobile_number)
                          }
                          helperText={
                            onSendLocation.touched.mobile_number &&
                            onSendLocation.errors.mobile_number
                          }
                        />

                        <ClickAwayListener
                          onClickAway={(e) => setSearchNumbers([])}
                        >
                          <div>
                            {searchNumbers?.length > 1 && (
                              <div className={classes.popover}>
                                <List>
                                  {contactData
                                    ?.filter((number) =>
                                      number?.phone_number?.includes(
                                        searchNumbers,
                                      ),
                                    )
                                    .map((name) => (
                                      <ListItem
                                        key={name?.phone_number}
                                        value={name?.phone_number}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          var list = onSendLocation.values.mobile_number.split(
                                            ",",
                                          );
                                          list[list?.length - 1] =
                                            name?.phone_number + ",";

                                          onSendLocation.setValues({
                                            mobile_number: removeDuplicatesFromString(
                                              list.join(","),
                                            ),
                                            latitude:
                                              onSendLocation.values.latitude,
                                            longitude:
                                              onSendLocation.values.longitude,
                                            address:
                                              onSendLocation.values.address,
                                          });
                                        }}
                                      >
                                        {name?.phone_number}
                                      </ListItem>
                                    ))}
                                </List>
                              </div>
                            )}
                          </div>
                        </ClickAwayListener>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl className={classes.margin}>
                          <TextField
                            name="latitude"
                            label="Latitude "
                            placeholder="Enter latitude"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            classes={{
                              root: globalClasses.customOutlinedTextField,
                            }}
                            value={onSendLocation.values.latitude}
                            onChange={onSendLocation.handleChange}
                            error={
                              onSendLocation.touched.latitude &&
                              Boolean(onSendLocation.errors.latitude)
                            }
                            helperText={
                              onSendLocation.touched.latitude &&
                              onSendLocation.errors.latitude
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl className={classes.margin}>
                          <TextField
                            name="longitude"
                            label="Longitude"
                            placeholder="Enter longitude"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            classes={{
                              root: globalClasses.customOutlinedTextField,
                            }}
                            value={onSendLocation.values.longitude}
                            onChange={onSendLocation.handleChange}
                            error={
                              onSendLocation.touched.longitude &&
                              Boolean(onSendLocation.errors.longitude)
                            }
                            helperText={
                              onSendLocation.touched.longitude &&
                              onSendLocation.errors.longitude
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <FormControl className={classes.margin}>
                          <TextField
                            name="address"
                            label="Address"
                            placeholder="Enter address"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            classes={{
                              root: globalClasses.customOutlinedTextField,
                            }}
                            value={onSendLocation.values.address}
                            onChange={onSendLocation.handleChange}
                            error={
                              onSendLocation.touched.address &&
                              Boolean(onSendLocation.errors.address)
                            }
                            helperText={
                              onSendLocation.touched.address &&
                              onSendLocation.errors.address
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} className={classes.formFooter}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          type="submit"
                          disabled={onSendLocation.isSubmitting}
                          // startIcon={<SendIcon/>}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  className={[classes.marginBorder, classes.mapContainer]}
                >
                  {/* <BasicMap
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg"
        loadingElement={<div style={{ height: "inherit", width: "inherit" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
      /> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={globalClasses.backdrop} open={loader}>
        {imageLoad === true ? (
          <Typography style={{ color: "white", fontSize: "16px" }}>
            Uploading please wait...
          </Typography>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Backdrop>
    </>
  );
}

export default SendMessage;
