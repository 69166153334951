import React, { createContext, useState, useEffect } from "react";
import { viewAuthentication } from "../services/instance.api";
import useStyles from "../pages/admin/style";
import { toast } from "react-toastify";
import Notification from "../components/Notification/Notification";
import { Redirect,useHistory } from "react-router-dom/cjs/react-router-dom.min";

const InstanceContext = createContext();

export const InstanceDataProvider = ({ children }) => {
  const classes = useStyles();
  const [reset, setReset] = useState(false);
  const [instanceId, setInstanceId] = useState(null);
  const [isInstance, setIsInstance] = useState(false);
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("webWAInfoapto_PX11925");
  const history = useHistory();

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }
  useEffect(() => {
    if (user?.role_code !== "SUPERADMIN") {
      getInstanceId(user?.userId);
      //   const changeInstanceId = () => {
      //     getInstanceId(user?.userId);
      //   };
      //   window.addEventListener("storage", changeInstanceId);
      //   return () => {
      //     window.removeEventListener("storage", changeInstanceId);
      //   };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInstanceId(id) {
    viewAuthentication({ userId: id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          const data = response?.data?.data;
          setInstanceId(data);
          setIsInstance(true);
          if (parseInt(response?.data?.data?.istrash) === 1) {
            setReset(true);
          }
        } else {
          if (user?.role_code === "USER") {
            handleNotificationCall("error", "Instance not found");
            setIsInstance(false);
            setTimeout(() => {
              history.push("dashboard");
            }, 5000);
          }
        }

        if (response.data.logout) {
          localStorage.clear();
          return <Redirect
            to={{
              pathname: "/login",
            }}
          />
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <InstanceContext.Provider
      value={{ reset, setReset, instanceId, setInstanceId, isInstance }}
    >
      {children}
    </InstanceContext.Provider>
  );
};

export default InstanceContext;
