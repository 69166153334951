import React, { useState, useEffect, useReducer } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Button,
  List,
  Box,
  FormControl,
  InputLabel, 
  SwipeableDrawer, 
  Tooltip, 
  Typography, 
  Backdrop,
  CircularProgress, 
  TextField,
  TablePagination, IconButton,
  Switch, InputAdornment
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from "../../themes/style.js";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification"; 
import { Visibility as ViewIcon, Close as CloseIcon, } from "@material-ui/icons";
import { subUsersSchema, subUsersWithoutSchema, subUsersEditSchema } from "../../components/Yup/Yup";

import { viewUser, viewAllSubUsers, changeUserActivity, addSubUsers, editUser } from '../../services/user.api'; 
 import AddCircleIcon from "@material-ui/icons/AddCircle";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useFormik } from 'formik';
 import EditIcon from "@material-ui/icons/Edit";
import PhoneInput from "react-phone-input-2";
import parseMobile from 'libphonenumber-js/mobile'

import useMediaQuery from '@material-ui/core/useMediaQuery'; 
import "react-toastify/dist/ReactToastify.css";

export default function Users(props) {
  var moment = require('moment-timezone');

  const classes = useStyles();
  const mobileQuery = useMediaQuery('(max-width:600px)');  
  const [userData, setUserData] = useState([]); 
  const [userView, setUserView] = useState({ 
    data:""
  });
  const [dataList, setDataList] = useState("ADD"); 

  const [loader, setLoader] = useState(false); 
  // const filterRef = useRef(null);
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
   const [page, setPage] = useState(0);
  const [currerntPage, setCurrerntPage] = useState(1);
  const [count, setCount] = useState(0);
  const token= localStorage.getItem('webWAInfoapto_PX11925');
  const [reducerValue,  forceUpdate ] = useReducer((x) => x + 1, 0);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  const HeaderElements = () => <>Total : {count}</>;

  const handleChangePage = (event, newPage) => {
    setLoader(true);
   
     
    viewAllSubUsers({
      page: newPage + 1,  
      token: token,
    }).then((response) => {    
      if (response.data.status === true) { 
        
        setUserData(response.data.data);
           setCount(response.data.count);
           setLoader(false);

      }  else{
        setCount(0);
        setUserData([]);
       setLoader(false);
     }
     setPage(newPage);
     setCurrerntPage(newPage + 1);
    }) 
    .catch(function (error) {
     console.log(error);
   })

  };

  
  const handleChange = (values, allDetails) => {   
    setCountryCode(allDetails.dialCode);
    setPhoneNumber(values);
};

const [isValid, setIsValid] = useState(true);

const [values, setValues] = React.useState({
  showPassword: false,
});

const handleClickShowPassword = () => {
  setValues({ ...values, showPassword: !values.showPassword });
};

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
       
      viewAllSubUsers({page: 1, token: token,}).then((response) => {    
         if (response.data.status === true) { 
           
           setUserData(response.data.data);
              setLoader(false);
              setCount(response.data.count);
         }   else{
          setCount(0);
          setUserData([]);
         setLoader(false);
         setPage(0);
         setCurrerntPage(1);
       }
       }) 
       .catch(function (error) {
        console.log(error);
      })
 
    };
    fetchData();
       
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reducerValue]);

  

  function handleShow(values, name){
    setLoader(true); 
    if (name === "EDIT") { 
      setDataList("EDIT");
    } else {
      setDataList("VIEW");
    } 
    viewUser({id: values, token: token,}).then((response) => {    
      if (response.data.status === true) { 
         
        setUserView({
          ...userView,
          data: response.data.data 
          });
          setCountryCode(response.data?.data?.country_code);
          setPhoneNumber(response.data?.data?.country_code+response.data?.data?.mobile);

          onUserEdit.setValues(response?.data?.data);  
            setState({ ...state, right: true });
            setLoader(false);
          } 
        }) 
          .catch(function (error) {
           console.log(error);
         })
    
  }
   
 
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  }
   
function handleStatus(id, value) { 
      setLoader(true); 
      changeUserActivity({id: id, token: token,}).then((response) => {    
        if (response.data.status === true) { 
           
          const switchState = userData?.map(item => { 
            if (item.id === id) {
              return { ...item, isActive: value===true? 1: 0 };
               
            }
            return item;
          }); 
          setUserData(switchState);
              setLoader(false);
              handleNotificationCall("success", response.data.message);
            }  else{
              handleNotificationCall("error", response.data.message);
            }
          }) 
            .catch(function (error) {
             console.log(error);
           })
}
 

  // const handleFromDateChange = (event) => {
  //   setFromDate(filterRef.current.fromDate.value);
  // };

  // const handleToDateChange = (event) => {
  //   setToDate(filterRef.current.toDate.value);
  // };

  // function getFilterData() {
  //   setLoader(true);
    
  //   const form = filterRef.current;
      
  //   viewAllSubUsers({
  //     page: 1, 
  //     referedBy: `${form["referedBy"].value}`,
  //     fromDate: `${form["fromDate"].value}`,
  //     toDate: `${form["toDate"].value}`,
  //     token: token,
  //   }).then((response) => {    
  //     if (response.data.status === true) { 
  //       setCount(response.data.count);
  //       setUserData(response.data.data);
  //          setLoader(false); 
  //     }  else{
  //        setCount(0);
  //        setUserData([]);
  //       setLoader(false);
  //     }
     
     
  //   }) 
  //   .catch(function (error) {
  //    console.log(error);
  //  }) 
           
  // }
 
  // const resetForm = (e) => {
  //   filterRef.current.reset(); 
  //   forceUpdate();
  // };

  const onUserEdit = useFormik({
    initialValues: {  
      id:"",
      email: '', 
      firstname: '',
      lastname: '',     
      mobile:'', 
    },
     validationSchema: subUsersEditSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      setLoader(true);
      editUser(values,  { id: onUserEdit.values.id,mobile:phoneNumber=== ""? onUserEdit.values.mobile: phoneNumber.slice(countryCode?.length), countryCode:countryCode===""? onUserEdit.values.country_code:countryCode, token: token}).then((response) => {    
      if (response.data.status === true) { 
        handleNotificationCall("success", response.data.message);  
        forceUpdate(); 
        resetForm();
        setState({ ...state, right: false }); 
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })

    },

   
  });  
 
  const onUserAdd = useFormik({
    initialValues: { 
      email: '',
      password: '', 
      firstname: '',
      lastname: '',     
      mobile:'', 
    },
     validationSchema: phoneNumber === "" ? subUsersSchema : subUsersWithoutSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => { 
      

      setLoader(true);
      addSubUsers(values, {phoneNumber: phoneNumber, countryCode: countryCode, token: token}).then((response) => {    
        
      if (response.data.status === true) { 
         
         handleNotificationCall("success", response.data.message); 
         setState({ ...state, right: false }); 
         resetForm();
         setPhoneNumber(countryCode); 
         forceUpdate(); 
        
       } else { 
        handleNotificationCall("error", response.data.message); 
       
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })
  
    },
  }); 
 


  const [state, setState] = useState({  right: false });

  const toggleDrawer = (anchor, open) => (event) => {  
    setState({ ...state, [anchor]: open });
  };


  const list = (anchor) =>
  dataList === "ADD" ? (
    <Box sx={{ width: "100%" }} role="presentation">
    <List>
      <Card >
        <CardHeader>
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.drawerHeader}
          >
            <Typography variant="subtitle1"> Add User</Typography>

            <Grid className={classes.drawerClose}>
              <CloseIcon
                className={classes.closeBtn}
                size="14px"
                onClick={toggleDrawer(anchor, false)}
              />
            </Grid>
          </Grid>
        </CardHeader>

       
     <form onSubmit={onUserAdd.handleSubmit}  >

    <CardContent className={classes.drawerViewContent}>
        <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="name">  First Name    </InputLabel>

            <FormControl className={classes.margin}>
              <TextField
                classes={{ root: classes.customTextField }}
                InputProps={{ disableUnderline: true }}
                size="small"
                placeholder="Enter first name"
                type="text"
                name="firstname" 
                value={onUserAdd.values.firstname}
                onChange={onUserAdd.handleChange}
                error={onUserAdd.touched.firstname && Boolean(onUserAdd.errors.firstname)}
                helperText={onUserAdd.touched.firstname && onUserAdd.errors.firstname}

              />
 
            </FormControl>
          </Grid>
         
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="name"> Last Name    </InputLabel>

            <FormControl className={classes.margin}>
              <TextField
                classes={{ root: classes.customTextField }}
                InputProps={{ disableUnderline: true }}
                size="small"
                placeholder="Enter last name"
                type="text"
                name="lastname" 
                value={onUserAdd.values.lastname}
                onChange={onUserAdd.handleChange}
                error={onUserAdd.touched.lastname && Boolean(onUserAdd.errors.lastname)}
                helperText={onUserAdd.touched.lastname && onUserAdd.errors.lastname}

              />
 
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <InputLabel shrink htmlFor="email"> Email </InputLabel>

           
            <TextField
                    id="email"
                    classes={{ root: classes.customTextField }}
                    InputProps={{ disableUnderline: true }}
                    name="email"
                    margin="normal"
                    placeholder="Enter Email"
                    type="email"
                    fullWidth
                    value={onUserAdd.values.email}
                    onChange={onUserAdd.handleChange} 
                    error={onUserAdd.touched.email && Boolean(onUserAdd.errors.email)}
                    helperText={onUserAdd.touched.email && onUserAdd.errors.email}
   
                  /> 
           
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
          <InputLabel shrink htmlFor="password"> Password </InputLabel>

           
          <TextField
                    id="password"
                    InputProps={{ 
                      disableUnderline: true,
                      endAdornment: <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}   >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    }}
                    name="password"
                    margin="normal"
                    placeholder="Password"
                    type={values.showPassword ? 'text' : 'password'}
                    classes={{ root: classes.customTextField }}
                    fullWidth
                    value={onUserAdd.values.password}
                    onChange={onUserAdd.handleChange}
                    
                    error={onUserAdd.touched.password && Boolean(onUserAdd.errors.password)}
                    helperText={onUserAdd.touched.password && onUserAdd.errors.password}
 
                   
                  />

</Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
          <InputLabel shrink htmlFor="phoneNumber"> Mobile </InputLabel> 
     
<PhoneInput
      country={"in"}
      id="mobile"
      enableSearch={true}
      value={phoneNumber}
      className={classes.customTextField}
       
    onChange={(val, allDetails) => {
      handleChange(val, allDetails);
      setIsValid(parseMobile('+'+phoneNumber)?.isValid()); 
  }}
  onBlur={(e) => {  
    setIsValid(parseMobile('+'+phoneNumber)?.isValid()); 
   }}
   margin="normal"
   name="mobile"
   variant="outlined"
 
    />
<Typography variant="inherit" color="error">{ phoneNumber === ""?  onUserAdd.touched.mobile && onUserAdd.errors.mobile :isValid !== true? "Invalid phone number format":""}</Typography>

          </Grid>

         
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.drawerFooter}
        >
       

          <Button
            variant="contained"
            color="primary"
            size="small"
             type="submit"
              disabled={isValid !== true? true: onUserAdd.isSubmitting}

          >
            Save
          </Button>

          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={toggleDrawer(anchor, false)}
          >
           
            Close
          </Button>
        </Grid>
      </CardActions>
    </form>
    </Card>
        </List>
        </Box>
 
  ) :  dataList === "EDIT" ? (
    <Box sx={{ width: "100%" }} role="presentation">
    <List>
      <Card >
        <CardHeader>
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.drawerHeader}
          >
            <Typography variant="subtitle1"> Edit User</Typography>

            <Grid className={classes.drawerClose}>
              <CloseIcon
                className={classes.closeBtn}
                size="14px"
                onClick={toggleDrawer(anchor, false)}
              />
            </Grid>
          </Grid>
        </CardHeader>

                   <form onSubmit={onUserEdit.handleSubmit}  >
                  <CardContent className={classes.drawerViewContent}>
                      <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                          <InputLabel shrink htmlFor="firstname">  First Name    </InputLabel>

                          <FormControl className={classes.margin}>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              InputProps={{ disableUnderline: true }}
                              size="small"
                              placeholder="Enter name"
                              type="text"
                              name="firstname" 
                             
                              value={onUserEdit.values.firstname}
                              // onChange={onUserEdit.handleChange}
                              onChange={(e) => {
                                onUserEdit.setValues({
                                  "id":  onUserEdit.values.id,
                                  "firstname":e.target.value,
                                  "lastname":onUserEdit.values.lastname,
                                  "email":onUserEdit.values.email,
                                  "mobile": onUserEdit.values.mobile,
                                  "country_code":  onUserEdit.values.country_code
                                })
                               }}
                              error={onUserEdit.touched.firstname && Boolean(onUserEdit.errors.firstname)}
                              helperText={onUserEdit.touched.firstname && onUserEdit.errors.firstname} 
                            />


                       
                          </FormControl>
                        </Grid>
                       
                     

                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <InputLabel shrink htmlFor="lastname">  Last Name    </InputLabel>

                          <FormControl className={classes.margin}>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              InputProps={{ disableUnderline: true }}
                              size="small"
                              placeholder="Enter last name"
                              type="text"
                              name="lastname" 
                             
                              value={onUserEdit.values.lastname}
                              // onChange={onUserEdit.handleChange}
                              onChange={(e) => {
                                onUserEdit.setValues({
                                  "id":  onUserEdit.values.id,
                                  "firstname": onUserEdit.values.firstname,
                                  "lastname": e.target.value,
                                  "email":onUserEdit.values.email,
                                  "mobile": onUserEdit.values.mobile,
                                  "country_code":  onUserEdit.values.country_code
                                })
                               }}
                              error={onUserEdit.touched.lastname && Boolean(onUserEdit.errors.lastname)}
                              helperText={onUserEdit.touched.lastname && onUserEdit.errors.lastname} 
                            />


                       
                          </FormControl>
                        </Grid>

                     <Grid item xs={12} sm={6} md={6} lg={6}>
                          <InputLabel shrink htmlFor="email"> Email  </InputLabel>

                          <FormControl className={classes.margin}>    
                        <TextField
                        classes={{ root: classes.customTextField }}
                        InputProps={{ disableUnderline: true }}
                        size="small"
                        placeholder="Enter email"
                        type="text"
                        name="email" 
                        value={onUserEdit.values.email}
                       // onChange={onUserEdit.handleChange}
                       onChange={(e) => {
                        onUserEdit.setValues({
                          "id":  onUserEdit.values.id,
                          "email":e.target.value,
                          "firstname": onUserEdit.values.firstname,
                           "lastname":  onUserEdit.values.lastname,
                          "mobile": onUserEdit.values.mobile,
                          "country_code":  onUserEdit.values.country_code
                        })
                       }}
                        error={onUserEdit.touched.email && Boolean(onUserEdit.errors.email)}
                        helperText={onUserEdit.touched.email && onUserEdit.errors.email}

                      />

                           
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6}>
          <InputLabel shrink htmlFor="phoneNumber"> Mobile </InputLabel>  
  
<PhoneInput
      country={"in"}
      id="mobile"
      enableSearch={true}
      value={phoneNumber}
      className={classes.customTextField}
      style={{ width: "100%" }}

      
      onChange={(val, allDetails) => {
        handleChange(val, allDetails);
        setIsValid(parseMobile('+'+phoneNumber)?.isValid()); 
       }}

       onBlur={(e) => {
    
        onUserEdit.setValues({  
          "id":  onUserEdit.values.id,
          "mobile": phoneNumber,
          "email":  onUserEdit.values.email,
          "firstname": onUserEdit.values.firstname,
          "lastname":  onUserEdit.values.lastname,
          "country_code": countryCode
        })
          
        setIsValid(parseMobile('+'+phoneNumber)?.isValid()); 
       }}
   margin="normal"
   name="mobile"
   variant="outlined"                
    />


<Typography variant="inherit" color="error">{ phoneNumber === ""?  onUserEdit.touched.mobile && onUserEdit.errors.mobile : isValid !== true? "Invalid phone number format": ""}  </Typography>


          </Grid>

         
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        className={classes.drawerFooter}
                      >
               

                        <Button
                       
                           variant="contained"
                           color="primary"
                           size="small"
                            type="submit"
                           
                            disabled={isValid !== true? true: onUserEdit.isSubmitting}
                        >
                          Update  
                        </Button>

                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          onClick={toggleDrawer(anchor, false)}
                        >
                         
                          Close
                        </Button>
                      </Grid>
                    </CardActions>
                  </form>
                  </Card>
        </List>
        </Box>
  ) : (
      <>
        <Box sx={{ width: "100%" }} role="presentation">
          <List>
            <Card  >
              <CardHeader>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  className={classes.drawerHeader}
                >
                  <Typography variant="subtitle1"> View User</Typography>

                  <Grid className={classes.drawerClose}>
                    <CloseIcon
                      className={classes.closeBtn}
                      size="14px"
                      onClick={toggleDrawer(anchor, false)}
                    />
                  </Grid>
                </Grid>
              </CardHeader>

              <CardContent className={classes.drawerContent}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}> <Typography className={classes.boldtext}>  First Name   </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}> {userView.data?.firstname}  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}> Last Name  </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>  {userView.data?.lastname}  </Grid>

               
 
                  <Grid item xs={12} sm={6} md={6} lg={6}>  <Typography className={classes.boldtext}>  Email  </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {userView.data?.email}  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}>  Mobile  </Typography>  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {userView.data?.country_code + " " +userView.data?.mobile}   </Grid>
                
           

                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}>  Status  </Typography>  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {parseInt(userView.data?.isActive) === 1?   
                  <Button variant="contained" size="small" color='primary'  className={classes.pointerEventsNone} > ACTIVE </Button> 
                  : <Button variant="contained" size="small" color='secondary' className={classes.pointerEventsNone}   > INACTIVE </Button> }   </Grid>
 
          
 
                  <Grid item xs={12} sm={6} md={6} lg={6}>  <Typography className={classes.boldtext}>    Posted Date </Typography>  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}> { userView.data?.created? moment(userView.data?.created).tz(moment.tz.guess()).format('DD-MM-YYYY') :""}    </Grid> 
               
                </Grid>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.drawerFooter}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={toggleDrawer(anchor, false)}
                  >
                    
                    Close
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </List>
        </Box>
      </>
    ) ;

  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
          
          <PageTitle title="Users" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={classes.drawerClose}>
          
        <div className={classes.lgButton}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              onClick={(e) => {
                setCountryCode("");
                setPhoneNumber("");
                setIsValid(true);
               
                onUserAdd.resetForm(); 
                setDataList("ADD"); 
                setState({ ...state, right: true });
              }}
            >
              Add New User
            </Button>
          </div>

          <div className={classes.smButton}>
            <Button
              variant="contained"
              size="small"
              startIcon={<AddCircleIcon />}
              className={classes.margin}
              color="primary"
              onClick={(e) => {
                setCountryCode("");
                setPhoneNumber("");
                setIsValid(true);
                
                onUserAdd.resetForm(); 

                setDataList("ADD");
              
                setState({ ...state, right: true });
              }}
            >
              
              Add
            </Button>
          </div>
        

          <SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            classes={{ paper: classes.drawer }}
            disableSwipeToOpen={false}
          >
            {list("right")}
          </SwipeableDrawer>
        </Grid>
      </Grid>



      {/* <form
        ref={filterRef}
        onSubmit={(e) => {
          e.preventDefault();
          getFilterData();
        }}
      >
        <Grid container spacing={2} className={classes.filterGap}>
        
          <TextField
            name="fromDate"
           label="From"
            InputLabelProps={{ shrink: true }}
            classes={{ root: classes.filterFullWidth }}
            type="date"
            defaultValue={fromDate}
            onChange={handleFromDateChange}
          />

          <TextField
            name="toDate"
           label="To"
            InputLabelProps={{ shrink: true }}
            classes={{ root: classes.filterFullWidth }}
            type="date"
            defaultValue={toDate}
            onChange={handleToDateChange}
          />

          <div className={classes.buttons}>
            <Button
              variant="contained"
              size="small"
              color="primary" 
              type="submit"
            >
              Search
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => resetForm()}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </form> */}



      <Grid container spacing={2}>
        <Grid item xs={12}>
 
          
          <MUIDataTable
            options={{
              pagination: false,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
              responsive: mobileQuery===true? 'vertical' : 'standard',
              customToolbar: () => <HeaderElements />,
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              }
            }}
            columns={[
              {
                name: "S.No",
              },
              {
                name: "Name",
              }, 
              {
                name: "Email",
              },

              {
                name: "Mobile",
              }, 
               
              {
                name: "Status",
              }, 
              {
                name: "Posted Date",
              },
              
              {
                name: "Actions",
              },
            ]}
            data={userData?.map((item, index) => {
               return [
                currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 , 
                item.firstname + " " + item.lastname, 
               
                item.email,
                item.country_code + " " +item.mobile, 
                <Switch
                checked={parseInt(item.isActive) === 1? true: false }
                onChange={(e) => {
                  
                   handleStatus(item.id, e.target.checked);
                }}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }} />,
                item?.created?  moment(item?.created).tz(moment.tz.guess()).format('DD-MM-YYYY') :"",
                 
                <>
                  <Grid container className={classes.space}>
                    <Grid item xs className={classes.toolAlign}>
                    
                    <Tooltip
                        title="Edit User"
                        placement="bottom"
                        aria-label="edit"
                      >
                        <EditIcon
                          className={classes.toolIcon}
                          onClick={(e) => {
                            handleShow(item.id, "EDIT");
                          }}
                        />
                      </Tooltip>
                     
                      <Tooltip
                        title="View User"
                        placement="bottom"
                        aria-label="view"
                      >
                        <ViewIcon
                          className={classes.toolIcon}
                          onClick={(e) => {

                            handleShow(item.id, "VIEW");
                           

                          }}
                        />
                      </Tooltip>
                      
                    </Grid>
                  </Grid>
                </>,
              ];
            })}
          />

          <Grid container spacing={2} className={classes.pagination}>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={count}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      </Grid>

      

      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

